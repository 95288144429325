<template>
    <div id="carrinho-dt-id" class="bg-carrinho-compras">
        <div class="carrinho-compras" v-click-outside="hideCart">
            <div class="left-carrinho">
                <div class="titulo-carrinho">
                    <h2>Produtos adicionados ({{ qtdItensCarrinho }})</h2>
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                </div>
                <div class="divisoria-carrinho"></div>  <!-- DIVISÓRIA -->
                <div class="lista-produtos-carrinho y-scroll">
                    <template v-for="produto in lstProdutos">
                        <div v-if="parseInt(produto.qtdItensCarrinho) > 0" class="produto-carrinho">
                            <div class="foto-produto">
                                <div class="qtd-item-carrinho"><span>{{ produto.qtdItensCarrinho }}</span></div>
                                <img src="../../assets/images/produtos/1g.avif" alt="">
                            </div>
                            <div class="descricao-produto">
                                <p>{{ produto.descricao.toUpperCase() }}</p>
                                <div class="divisoria-carrinho" style="width: 95%;"></div>  <!-- DIVISÓRIA -->
                                <!-- <money class="total-produto-carrinho" v-bind="money" v-model="produto.valorReal"></money> -->
                                <div class="footer-produto">
                                    <div class="seletor-quantidade">
                                        <button class="btn-menos" @click="produto.qtdItensCarrinho--"><i class="fa fa-minus" aria-hidden="true"></i></button>
                                        <input v-model="produto.qtdItensCarrinho" type="text" name="" id="">
                                        <button class="btn-mais" @click="produto.qtdItensCarrinho++"><i class="fa fa-plus" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="total-por-produto">
                                <span>{{formataReal(produto.qtdItensCarrinho*parseFloat(produto.valorReal), 'R$')}}</span>
                            </div>
                            <div class="remover-produto">
                                <button @click="produto.qtdItensCarrinho = 0"><i class="fa fa-times" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="right-carrinho">
                <div class="titulo-carrinho">
                    <h2>Resumo do seu carrinho</h2>
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                </div>
                <div class="divisoria-carrinho"></div>  <!-- DIVISÓRIA -->
                <div class="campo-cep">
                    <div class="tipo-entrega">
                        <button class="active">Receber</button>
                        <button>Retirar na loja</button>
                    </div>
                </div>
                <div class="divisoria-carrinho" style="margin-top: auto;"></div>  <!-- DIVISÓRIA -->
                <div class="rodape-carrinho">
                    <div class="codigo-promocao-carrinho"></div>
                    <div class="calculos-carrinho">
                        <div class="valor-subtotal">
                            <p>Subtotal</p>
                            <span>R$199,90</span>
                        </div>
                        <div class="valor-frete">
                            <p>Frete</p>
                            <span>R$9,45</span>
                        </div>
                        <div class="valor-descontos">
                            <p>Descontos</p>
                            <span>R$12,00</span>
                        </div>
                    </div>
                    <div class="divisoria-carrinho"></div>
                    <div class="finalizar-carrinho">
                        <div class="valor-total"></div>
                        <div class="botoes-finalizar">
                            <button>Finalizar compra</button>
                            <button @click="hideCart">Continuar comprando</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import utils from '@/utilitarios/utils';
import {Money} from 'v-money'
export default {
    name: 'CartTablet',
    props: {
        hideCart: {
            type: Function,
            required: true
        }
        // msg: String
    },
    components: {
        Money
    },
    data: function() {
        return {
            // CONFIGURAÇÃO COMPONENTE MONEY
            money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'R$',     //aqui colocar o cifrão se for necessário
                    precision: 2,
                    masked: false
                },

            // CONFIGURAÇÕES DO CLIENTE
            temaFilial              : '', // AQUI TRÁS O TEMA DE CORES ESCOLHIDO NA CONFIGURAÇÃO DO WEBSITE NO SISTEMA
            
            // DADOS CARRINHO
            qtdItensCarrinho        : '1',
            lstProdutos: [
				{
					descricao: 'Smart Tv Samsung 32 Hd Tizen T4300 Bivolt',
					valorReal: '2250',
					porcentagemDesc: '13',
					urlimg: '1g.avif',
					outro: 'ou R$2.250,00 em 5x de R$450,00 sem juros',
					qtdItensCarrinho: 1,
				},
				{
					descricao: 'Tênis Mizzuno Allianz',
					valorReal: '1899,00',
					valorDesc: '1.059,00',
					porcentagemDesc: '44',
					urlimg: '1g.avif',
					outro: 'ou R$2.250,00 em 10x de R$105,00 sem juros',
					qtdItensCarrinho: 35,
				},
				{
					descricao: 'Apple iPhone 15 Pro Max 256 GB -Titânio Natural',
					valorReal: '8998,89',
					valorDesc: '8099,00',
					porcentagemDesc: '14',
					urlimg: '1g.avif',
					outro: 'ou R$8.998,89 em 10x de R$899,89 sem juros',
					qtdItensCarrinho: 4,
				},
				{
					descricao: 'Smart TV 55" 4K LG NanoCell 55NANO77SRA Bluetooth, ThinQ AI, Alexa',
					valorReal: '2250,00',
					valorDesc: '1957,50',
					porcentagemDesc: '13',
					urlimg: '1g.avif',
					outro: 'ou R$2.250,00 em 5x de R$450,00 sem juros',
					qtdItensCarrinho: 125,
				},
				{
					descricao: 'Smart TV 55" 4K LG NanoCell 55NANO77SRA Bluetooth, ThinQ AI, Alexmart TV 55" 4K LG NanoCell 55NANO77SRA Bluetooth, ThinQ AI, Alexa',
					valorReal: '1899,00',
					valorDesc: '1059,00',
					porcentagemDesc: '44',
					urlimg: '1g.avif',
					outro: 'ou R$2.250,00 em 10x de R$105,00 sem juros',
					qtdItensCarrinho: 10,
				},
				{
					descricao: 'Smart Tv Samsung 32 Hd Tizen T4300 Bivolt',
					valorReal: '1899,00',
					valorDesc: '1059,00',
					porcentagemDesc: '44',
					urlimg: '1g.avif',
					outro: 'ou R$2.250,00 em 10x de R$105,00 sem juros',
					qtdItensCarrinho: 15,
				},
			],
        };
    },
    methods: {
        formataReal(valor, prefix) {
            return utils.formataReal(valor, prefix)
        },
    },
    computed: {
        // calculaSubtotal(){
        //     return 
        // }
    }

    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.divisoria-carrinho {
    width: 100%;
    height: 2px;
    background-color: rgb(220, 220, 220);
    margin-block: 4px;
}
.bg-carrinho-compras{
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.47);
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-carrinho {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    width: 59%;
    height: 100%;
}
.right-carrinho {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    width: 39%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.campo-cep{
    width: 100%;
    // background-color: red;
    height: 100px;
}
.tipo-entrega{
    button {
        font-size: 14px;
        height: 33px;
        border-radius: 7px;
        padding-inline: 5px;
        margin-inline: 5px;
        background-color: #fff;
        border: solid 1px #818181a4;

        &:hover {
            background-color: rgb(223, 223, 223)
        }
    }
}
.active {
    background-color: var(--cor-primaria);
    color: #fff;
    font-weight: 600;
}

.carrinho-compras {
    height: 85vh;
    width: 80vw;
    border-radius: 10px;
    background-color: rgb(230, 230, 230);
    display: flex;
    justify-content: space-between;
    padding: 15px;

}
.titulo-carrinho {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h2 {
        height: 100%;
        font-size: 18px;
        font-weight: 600;
        padding: 0;
        margin: 0;
    }
    & > i {
        text-align: center;
        height: 100%;
        font-size: 20px;
    }
}
.lista-produtos-carrinho {
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    height: 93%;
    overflow-y: auto;
    background-color: rgb(230, 230, 230);
}

.rodape-carrinho {
    // height: fit-content;
    width: 100%;
    // background-color: blue;
}

.calculos-carrinho{
    display: flex;
    flex-direction: column;
    & > .valor-subtotal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > p {
            font-size: 25px;
            font-weight: 600;
            margin: 0;
        }
        & > span {
            font-size: 20px;
            margin: 0;
        }
    }
    & > .valor-frete {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > p {
            margin: 0;
            font-size: 18px;
            color: rgb(133, 133, 133);
        }
        & > span {
            margin: 0;
            font-size: 18px;
            color: rgb(133, 133, 133);
        }
    }
    & > .valor-descontos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > p {
            margin: 0;
            font-size: 18px;
            color: rgb(133, 133, 133);
        }
        & > span {
            margin: 0;
            font-size: 18px;
            color: rgb(88, 174, 90);
            text-decoration: line-through;
        }
    }
}

.finalizar-carrinho{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    & > .botoes-finalizar {
        display: flex;
        flex-direction: column;
        padding-inline: 10px;
        :first-child {
            border: 0;
            width: 220px;
            height: 40px;
            border-radius: 10px;
            background-color: var(--cor-primaria);
            color: #fff;
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 8px;
            &:hover {
                background-color: var(--cor-primaria-hover);
            }
        }
        :last-child {
            border: 2px solid #b1b1b1;
            font-size: 16px;
            font-weight: 600;
            background-color: #fff;
            width: 220px;
            height: 40px;
            border-radius: 10px;
            color: #3e3e3e;
        }

    }
}

.produto-carrinho {
    display: flex;
    width: 100%;
    height: 90px;
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 5px;

    & > .foto-produto {
        height: 100%;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        & > .qtd-item-carrinho {
            position: absolute;
            background-color: rgb(208, 208, 208);
            color: #242424;
            padding-inline: 3px;
            min-width: 23px;
            height: 23px;
            border-radius: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 8px;
            right: -10px;
            & > span {
                font-weight: 600;
            }
        }
        & > img {
            margin-left: 10px;
            padding: 4px;
            border-radius: 10px;
            border: 2px solid rgb(208, 208, 208);
            width: 100%;
        }
    }
    & > .descricao-produto {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        margin-left: 23px;
        text-align: left;
        & > p {
            font-size: 12px;
            font-weight: 600;
            width: 95%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            margin-left: 5px;
        }
        & > span {
            margin: 0;
            font-weight: 600;
            color:#242424b9 ;
        }

    }
    & >.remover-produto {
        height: 100%;
        width: fit-content;
        padding-top: 5px;
        padding-right: 5px;
        & > button {
            background-color: #fff;
            color: rgba(173, 0, 0, 0.689);
            border: 0;
            &:hover {
                color: rgba(216, 0, 0, 0.97);
            }
        }
    }
}
.footer-produto {
    width: 100%;
    // background-color: red;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 10px;
}

.seletor-quantidade {
    & > .btn-mais {
        width: 30px;
        background-color: #f3f3f3;
        border: 2px solid rgb(233, 233, 233);
        color: #2424246f;
    }
    & > input[type="text"]{
        width: 30px;
        text-align: center;
        // font-weight: 600;
        background-color: #fff;
        border-left: 0;
        border-top: 2px solid rgb(233, 233, 233);
        border-bottom: 2px solid rgb(233, 233, 233);
        border-right: 0;
    }
    & > .btn-menos {
        width: 30px;
        background-color: #f3f3f3;
        border: 2px solid rgb(233, 233, 233);
        color: #2424246f;
        // font-size: 20px;
    }
}

.total-por-produto {
    // background-color: red;
    height: 100%;
    width: 150px;
    display: flex;
    align-items: center;
    // justify-content: center;
    & > span {
        font-size: 14px;
        font-weight: 600;
    }
}
</style>
