<template>
  <div id="app" :class="temaFilial">
    <div v-if="isLoading" class="loader-pulse pulse">
      <img :src="'https://'+clienteSac.endServidor+'/controller/ecommerce/'+configPathsLogos.logoIcone" alt="...">
    </div>
    <router-view v-else/>
  </div>
</template>

<script>

import bl from '@/utilitarios/beforeLoad.js'

export default {
  data: function() {
          return {
              isLoading : true,
          }
      },
  computed: {
    temaFilial (){
        return 'tema-'+this.$store.state.filial.tema
    },
    configPathsLogos() {
        return this.$store.state.configPathsLogos;
    },
    clienteSac(){
        return this.$store.state.clienteSac;
    },
    filial(){
        return this.$store.state.filial;
    },
  },
  async beforeMount() {
    if (window.localStorage.getItem('path_logo_icone') != null || window.localStorage.getItem('path_logo_icone') != undefined){
      this.$store.commit('MUDAR_CONFIG_PATHS_LOGO_ICONE', window.localStorage.getItem('path_logo_icone'));
    }
    
    if (window.localStorage.getItem('usuarioLogado') != null){      
      let payload = (JSON.parse(window.localStorage.getItem('usuarioLogado')))   
      this.$store.commit('MUDAR_USUARIO_LOGADO', payload);
    }

    // cada uma dessas funções devem ser carregadas somente apos a outra ter sido carregada
    let ret1 = await bl.carregaDadosClienteSac(this);
    let ret2 = await bl.carregaLogosFilial(this);
    let ret3 = await bl.carregaDadosFilial(this);
    let ret4 = await bl.carregaInfosGeraisProdutos(this);

    if (this.configPathsLogos.logoIcone) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = 'https://' + this.clienteSac.endServidor + '/controller/ecommerce/' + this.configPathsLogos.logoIcone;
    }

    document.title = `${this.filial.fantasia} | ${window.location.hostname}`;
    this.isLoading = false;
    // this.isLoading = false;
  },
}
</script>
<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.loader-pulse{
  width: 100vw;
  height: 100vh;
  // background-color: red;
}
</style>
