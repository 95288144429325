<template>
    <div class="header">
        <!-- CARRINHO DE COMPRAS -->
        <CartTablet v-if="showCart" :hideCart="toggleCart"></CartTablet> 
        <!-- CARRINHO DE COMPRAS -->
        <div class="logo-filial">
            <a href="#">
                <img v-if="imgLogoFilial != ''" :src="imgLogoFilial">
            </a>
        </div>
        <div class="container-buscar">
            <div class="buscar-grupo">
                <a href="#"><i class="fa fa-search" aria-hidden="true"></i></a>
                <input type="text" placeholder="Buscar produtos...">
            </div>
        </div>
        <div class="adicionais-direita">
            <a href="#"><i class="fa fa-user-circle-o" aria-hidden="true"></i></a>
            <a href="#" class="login-grupo">
                <span><strong>Entre</strong> ou <strong>Cadastre-se</strong></span>
                <span>para ver seus pedidos</span>
            </a>
            <button class="grupo-carrinho" @click="toggleCart">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                <div class="badge-carrinho"><p>{{ qtdItensCarrinho }}</p></div>
            </button>
        </div>
        <nav class="rodape-header">
            <ul>
                <li class="todos-dp">
                    <a href="#"><i class="bi bi-list"></i> Todas as categorias</a>
                    <div class="dropdown-categorias">
                        <ul>
                            <li v-for="item in categoriasProdutos.todas">{{ item }}</li>
                        </ul>
                    </div>
                </li>
                <!-- <li class="categorias-header" v-for="categoria in categoriasProdutos.header"><a href="#">{{ categoria }}</a></li> -->
            </ul>
        </nav>            
    </div>
</template>
<script>
import CartTablet from '@/components/CarrinhoDeCompras/CartTablet.vue'
export default {
    name: 'HeaderTablet',
    props: {
        color: String
        // msg: String
    },
    components: {
        CartTablet
    },
    data: function() {
        return {
            // DADOS FILIAL
            imgLogoFilial           : 'https://i.imgur.com/8lJ6gDQ.png',
            fantasiaFilia           : '',

            // EXIBE MODAIS
            showCep: false,
            showCart: false,

            // CONFIGURAÇÕES DO CLIENTE
            temaFilial              : '', // AQUI TRÁS O TEMA DE CORES ESCOLHIDO NA CONFIGURAÇÃO DO WEBSITE NO SISTEMA
            categoriasProdutos: {
                todas: [
                    'Masculinas',
                    'Femininas',
                    'Infantis',
                    'Esportivas',
                    'Casuais',
                    'Mexirica',
                    'Notebook',
                    'Utensilhos',
                    'Eletrônicos',
                    'Cozinha',
                    'Refrigeradores',
                    'Eletrodomésticos',
                ],
                header: [
                    'Masculinas',
                    'Femininas',
                    'Infantis',
                    'Esportivas',
                    'Casuais',
                    'Mixirica',
                    'Notebook',
                ]
            },

            // DADOS CARRINHO
            qtdItensCarrinho        : '9',
        };
    },
    methods: {
        toggleModalCep(){
            this.showCep = !this.showCep;
        },
        toggleCart(){
            setTimeout(() => {
                this.showCart = !this.showCart;
            }, 90);
        },
    },

    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

div.header {
    position: sticky;
    z-index: 1;
    top: -95px;
    background-color: rgb(231, 231, 231);
    display: grid;
    grid-template-columns: 2fr 0.7fr 5fr;
    height: 135px;
    width: 100%;
    grid-template-rows: auto auto;
    padding-top: 15px;
    .rodape-header {
        height: 38px;
        background-color: var(--cor-primaria);
        grid-column: span 4;
        grid-row: 2;
        margin-top: auto;
        border-bottom: 3px solid var(--cor-primaria-light);
        & > ul{
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 30px;
            & > li{
                &.todos-dp {
                    position: relative;
                    margin-right: 20px;
                    margin-left: 20px;
                    height: 38px;
                    & > a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 38px;
                        width: 200px;
                        font-weight: 600;
                        list-style: none;
                        color: #fff;

                        &:hover {
                            color: var(--cor-primaria)a;
                            background-color: rgb(231, 231, 231);
                        }
                    }

                    &:hover > .dropdown-categorias {
                        // opacity: 1;
                        max-height: 500px;
                        // overflow-y: auto;
                    }
                    &:hover > .dropdown-categorias ul {
                        display: flex;
                        max-height: 500px;
                        // overflow-y: auto;
                    }

                }
                &.categorias-header {
                    margin-left: 50px;
                    list-style: none;
                    & > a{
                        font-weight: 500;
                        color: #fff;
                    }
                }
            }
        }
    }
}
// INICIO DROPDOWN TODAS CATEGORIAS
.dropdown-categorias {
    position: absolute;
    top: 36px;
    max-height: 0px;
    width: 200px;
    overflow-y: hidden;
    // opacity: 0;
    background-color: var(--cor-primaria);
    transition: all .3s;
}
.dropdown-categorias ul {
    display: flex;
    max-height: 0;
    flex-direction: column;
    list-style: none;
    padding-bottom: 5px;
    overflow-y: hidden;
    transition: all .3s;
    & > li {
        display: flex;
        align-items: center;
        height: 30px;
        width: 200px;
        
        color: #fff;
        font-size: 14px;
        padding-left: 20px;
        cursor: pointer;

        &:hover {
            color: var(--cor-primaria);
            background-color: #fff;
        }
    }
}

// FIM DROPDOWN TODAS CATEGORIAS
.logo-filial{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: 20px;

    img {
        height: 65px;
    }
}

.container-buscar{
    // background-color: blue;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    input[type=text] {
        height: 30px;
        width: 300px;
        border-radius: 8px;
        padding-left: 10px;
        border: none;
        box-shadow: 0px 0px 5px -2.5px rgba(0,0,0,0.75);

        &:focus {
            outline: 0;
            border: 1px solid rgb(172, 172, 172);
        }
    }
}

.buscar-grupo{
    position: relative;
    height: fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            color: var(--cor-primaria);
            position: absolute;
            right: 10px;
        }
    }
}

.adicionais-direita{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
        font-size: 31px;
        color: var(--cor-primaria);
        margin-right: 5px;
    }
    .login-grupo{
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0);
        span {
            font-size: 13px;
            color: var(--cor-primaria);
        }
    }
    .grupo-carrinho{
        position: relative;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        margin-left: 30px;
        .badge-carrinho {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: -5px;
            top: 0;
            color: #fff;
            font-size: 11px;
            font-weight: 600;
            height: 15px;
            width: 15px;
            border-radius: 50%;
            background-color: rgb(255, 0, 0);

            & > p {
                margin: 0;
            }
        }
    }
}
</style>
