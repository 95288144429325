<template>
    <div class="header">
        <!-- CARRINHO DE COMPRAS -->
        <CartDesktop></CartDesktop> 
        <!-- <CartDesktop v-if="showCart" :hideCart="toggleCart" @atualizaQtdCarrinho="atualizaQtdItensCarrinhoCart"></CartDesktop>  -->
        <!-- CARRINHO DE COMPRAS -->
        <div class="container-header">
            <div class="logo-filial">
                <a href="/">
                    <img v-if="configPathsLogos.logoPrincipal !== ''" :src="'https://'+clienteSac.endServidor+'/controller/ecommerce/'+configPathsLogos.logoPrincipal">
                </a>
            </div>
            <div class="container-cep">
                <div class="grupo-cep">
                    <button @click="toggleModalCep" :disabled="isLoadingCep"><i class="bi bi-geo-alt"></i> Informe seu CEP</button>
                    <div v-show="showCep" class="modal-cep-header" v-click-outside="fechaModalCep">
                        <template v-if="isLoadingCep">
                            <div class="spinner-border spinner-logout" style="width: 2rem; height: 2rem;" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </template>
                        <template v-else>
                            <p>A gente ajuda a encontrar o seu produto com <strong>menor frete</strong> e <strong>prazo de entrega</strong>.</p>
                            <div class="input-cep">
                                <!-- <input type="text" value="66055-225"> -->
                                <input type="tel" v-model="vCepEcommerce" v-mask="'#####-###'" placeholder="_____-__" style="font-size: 15px;">
                                <button @click.prevent="alterarCepEcommerce">OK</button>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="container-buscar">
                <div class="buscar-grupo">
                    <button @click.prevent="executarPesquisa()"><i class="fa fa-search" aria-hidden="true"></i></button>
                    <input v-model="vConteudoPesquisa" @keyup.enter="executarPesquisa()" type="text" placeholder="Buscar produto...">
                </div>
            </div>
            <template v-if="usuarioLogado.id === null">
                <div class="adicionais-direita">
                    <router-link to="/login"><i class="fa fa-user-circle-o" aria-hidden="true"></i></router-link>
                    <button class="login-grupo" @click="$router.push('/login')">
                        <span><strong>Entre</strong> ou <strong>Cadastre-se</strong></span>
                        <span>para ver seus pedidos</span>
                    </button>
                    <button class="grupo-carrinho" @click="toggleCart">
                        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                    </button>
                </div>
            </template>
            <template v-else>
                <div class="adicionais-direita dropdown">
                    <div v-if="isLoadingLogout" class="spinner-border spinner-logout" style="width: 1.7rem; height: 1.7rem; margin-right: 11px;" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <template v-else>
                        <i class="fa fa-user-circle-o" aria-hidden="true" style="cursor: pointer;"></i>
                    </template>
                    <button class="login-grupo dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        <span style="width: 100%; text-align: start;">Olá, <strong>{{primeiraMaiuscula(usuarioLogado.nome.split(' ')[0])}}</strong>.</span>
                        <span>Bem-Vindo(a) de volta!</span>
                    </button>
                    <div class="dropdown-menu">
                        <button class="dropdown-item menu-usuario" @click.prevent="$router.push('/perfil')"><i class="fa fa-user" aria-hidden="true"></i> Meu perfil</button>
                        <button class="dropdown-item menu-usuario" @click.prevent="$router.push('/pedidos')"><i class="fa fa-shopping-bag" aria-hidden="true" style="font-size: 15px !important;"></i> Meus pedidos</button>
                        <button class="dropdown-item menu-usuario" @click.prevent="logoutUsuario"><i class="fa fa-sign-out" aria-hidden="true"></i> Sair</button>
                    </div>
                    <button class="grupo-carrinho" @click="$modal.show('mdl-carrinho-compras')">
                        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                        <div v-if="qtdItensCarrinho > 0" class="badge-carrinho"><p>{{ qtdItensCarrinho }}</p></div>
                    </button>
                </div>
            </template>
        </div>
        <nav class="rodape-header">
            <ul>
                <li class="todos-dp">
                    <a href="#"><i class="bi bi-list"></i> Todas as categorias</a>
                    <div class="dropdown-categorias">
                        <ul>
                            <li v-for="item in infosGeraisProdutos.todasCategorias" @click.prevent="
                                ($route.params.idcategoria !== item.id) && $router.push({ 
                                    name: 'ProdutosCategoria', 
                                    params: { idcategoria: item.id, desccategoria: preparaDescricaoRouter(item.descricao) } })">
                                {{ limitaTexto(toUpperCase(item.descricao), 20) }}
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="categorias-header" v-for="(categoria, index) in lstCategoriasHeader">
                    <router-link :to="{ name: 'ProdutosCategoria', params: {idcategoria: categoria.id, desccategoria: preparaDescricaoRouter(categoria.descricao)} }">{{ limitaTexto(toUpperCase(String(categoria.descricao)), 20) }}</router-link>
                </li>
            </ul>
        </nav>
    </div>
</template>
<script>
import utils from '@/utilitarios/utils';
import CartDesktop from '../CarrinhoDeCompras/CartDesktop.vue';

export default {
    name: 'HeaderDesktop',
    components: {
        CartDesktop,
    },
    props: {
        color: String
        // msg: String
    },
    data: function() {
        return {
            // DADOS FILIAL
            fantasiaFilia           : '',

            // EXIBE MODAIS
            showCep             : false,
            isLoadingCep        : false,
            showCart            : false,
            vCepEcommerce       : '',
            vConteudoPesquisa   : '',
            isLoadingLogout     : false,
            // CONFIGURAÇÕES DO CLIENTE
            temaFilial              : '', // AQUI TRÁS O TEMA DE CORES ESCOLHIDO NA CONFIGURAÇÃO DO WEBSITE NO SISTEMA
            categoriasProdutos: {
                todas: [
                    'Masculinas',
                    'Femininas',
                    'Infantis',
                    'Esportivas',
                    'Casuais',
                    'Mexirica',
                    'Notebook',
                    'Utensilhos',
                    'Eletrônicos',
                    'Cozinha',
                    'Refrigeradores',
                    'Eletrodomésticos',
                ],
                header: [
                    'Sapatilhas',
                    'Femininas',
                    'Infantis',
                    'Esportivas',
                    'Casuais',
                    'Mixirica',
                    'Notebook',
                ]
            },
        };
    },
    methods: {
        toggleModalCep(){
            this.isLoadingCep = true;
            if (this.usuarioLogado.id === null) {
                setTimeout(() => {
                    this.isLoadingCep = false;
                    this.$router.push('/login');
                }, 1000);
                return;
            }
            this.vCepEcommerce = this.usuarioLogado.cepecommerce ? this.usuarioLogado.cepecommerce : this.usuarioLogado.cep;
            setTimeout(() => {
                this.isLoadingCep = false;
                this.showCep = !this.showCep;
            }, 90);
        },
        fechaModalCep(){
            if(this.showCep){
                this.showCep = false;
            }
        },
        toggleCart(){
            if (this.usuarioLogado.id === null) {
                this.$router.push('/login');
                return;
            }
            setTimeout(() => {
                this.showCart = !this.showCart;
            }, 90);
        },
        primeiraMaiuscula(string){
            return utils.primeiraMaiuscula(string);
        },
        toUpperCase(string){
            return String(string).toUpperCase();
        },
        limitaTexto(texto, limite){
            return utils.limitaTexto(texto, limite);
        },
        carregaQtdCarrinho(){
            let vself = this;
            let parametros = [
                {parametro: 'op',               valor: 'qtdcarrinho'},
                {parametro: 'idcliente',        valor: this.usuarioLogado.id},
                {parametro: 'idclientesac',     valor: this.clienteSac.id}
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtcarrinhocompras.php');
            retorno.then(function (response) {
                vself.$store.commit('MUDAR_QTD_CARRINHO_COMPRAS', response.data);
            }).catch(function (response) {
                console.log(response.message);
            });
        },
        alterarCepEcommerce(){
            let vself = this;
            this.isLoadingCep = true;
            let parametros = [
                {parametro: 'op',               valor: 'alteracepecommerce'},
                {parametro: 'idcliente',        valor: this.usuarioLogado.id},
                {parametro: 'idclientesac',     valor: this.clienteSac.id},
                {parametro: 'cepecommerce',     valor: this.vCepEcommerce},
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/auxiliares.php');
            retorno.then(function (response) {
                if(!isNaN(response.data)){
                    vself.$store.commit('MUDAR_CEP_ECOMMERCE', vself.vCepEcommerce);
                }
                vself.showCep = false;
                vself.isLoadingCep = false;
            }).catch(function (response) {
                console.log(response.message);
                vself.showCep = false;
                vself.isLoadingCep = false;
            });
        },
        logoutUsuario(){
            this.isLoadingLogout = true;
            setTimeout(() => {
                this.isLoadingLogout = false;
                this.$store.commit('LOGOUT_USUARIO');
                this.usuarioLogado.id = null;
                if (this.$route.path !== '/') {
                    this.$router.push('/');
                }
            }, 1000);
        },
        preparaDescricaoRouter(descricao){
			return utils.prepDescricaoRouter(descricao);
		},
        executarPesquisa(){
            if(this.vConteudoPesquisa.trim() !== ''){
                this.$router.push({ name: 'BuscarProdutos', params: { srcpesquisa: this.preparaDescricaoRouter(this.vConteudoPesquisa), conteudopesquisa: this.vConteudoPesquisa } });
            }
        }
    },
    mounted: function() {
        this.carregaQtdCarrinho();
    },
    computed: {
        clienteSac(){
            return this.$store.state.clienteSac;
        },
        filial(){
            return this.$store.state.filial;
        },
        usuarioLogado() {
            return this.$store.state.usuarioLogado;
        },
        configPaths() {
            return this.$store.state.configPaths;
        },
        configPathsLogos() {
            return this.$store.state.configPathsLogos;
        },
        qtdItensCarrinho(){
            return this.$store.state.carrinhoCompras.qtdItens;
        },
        infosGeraisProdutos(){
            return this.$store.state.infosGeraisProdutos;
        },

        lstCategoriasHeader(){
            if(this.infosGeraisProdutos.categoriasHeader.length < 5){
                // Retorna 5 categorias aleatorias do obj todasCategorias
                return this.infosGeraisProdutos.todasCategorias.sort(() => Math.random() - Math.random()).slice(0, 5);
            } else {
                // Se não, retorna as 5 primeiras categorias do obj categoriasHeader,
                // caso por algum motivo ele tenha conseguido adicionar mais de 5 categorias
                return this.infosGeraisProdutos.categoriasHeader.slice(0, 5);
            }
        }
    },
    watch: {
        usuarioLogado: {
            handler: function(){
                this.carregaQtdCarrinho();
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.header {
    position: sticky;
    z-index: 2;
    top: -95px;
    background-color: rgb(231, 231, 231);
    display: flex;
    flex-direction: column;
    height: 135px;
    width: 100%;
    padding-top: 15px;
    .rodape-header {
        height: 38px;
        background-color: var(--cor-primaria);
        margin-top: auto;
        border-bottom: 4px solid var(--cor-primaria-light);
        & > ul{
            height: 100%;
            width: 1100px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-inline: auto;
            & > li{
                &.todos-dp {
                    position: relative;
                    margin-right: 20px;
                    height: 38px;
                    & > a {
                        display: flex;
                        align-items: center;
                        height: 38px;
                        width: 180px;
                        font-weight: 600;
                        list-style: none;
                        color: #fff;

                        &:hover {
                            color: var(--cor-primaria);
                            background-color: rgb(231, 231, 231);
                        }
                    }

                    &:hover > .dropdown-categorias {
                        // opacity: 1;
                        max-height: 540px;
                        // overflow-y: auto;
                    }
                    &:hover > .dropdown-categorias ul {
                        display: flex;
                        max-height: 540px;
                        // overflow-y: auto;
                    }

                }
                &.categorias-header {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 200px;
                    height: 100%;
                    list-style: none;
                    &:hover {
                        background-color: rgba(0, 0, 0, 0.452);
                    }
                    & > a{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        font-weight: 500;
                        font-size: 13px;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.container-header{
    display: flex;
    justify-content: space-between;
    width: 1100px;
    height: 100%;
    margin-inline: auto;
}

// INICIO DROPDOWN TODAS CATEGORIAS
.dropdown-categorias {
    position: absolute;
    z-index: 50;
    top: 36px;
    max-height: 0px;
    width: 200px;
    overflow-y: hidden;
    // opacity: 0;
    background-color: var(--cor-primaria);
    transition: all .3s;
}
.dropdown-categorias ul {
    display: flex;
    max-height: 0;
    flex-direction: column;
    list-style: none;
    // padding-block: 5px;
    overflow-y: hidden;
    transition: all .3s;
    & > li {
        display: flex;
        align-items: center;
        height: 100%;
        width: 200px;
        
        margin-block: 3px !important;
        color: #fff;
        font-size: 14px;
        padding-left: 20px;
        cursor: pointer;
        
        &:hover {
            color: var(--cor-primaria);
            background-color: #fff;
        }
    }
}

// FIM DROPDOWN TODAS CATEGORIAS
.logo-filial{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > button{
        border: none;
    }
    img {
        height: 65px;
    }
}

.container-cep{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .grupo-cep {
        & > button {
            border: 1px solid rgb(172, 172, 172);
            border-radius: 10px;
            height: 34px;
            width: 130px;
            background-color: var(--cor-primaria);
            color: #fff;
            font-size: 12px;
            font-weight: 500;
    
            &:hover {
                cursor: pointer;
            }
        }

        .modal-cep-header{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            z-index: 1;
            top: 65px;
            left: -50px;
            width: 240px;
            height: 130px;
            background-color: #fff;
            border-radius: 10px;

            & > p {
                margin-top: 10px;
                margin-inline: 13px;
                font-size: 13px;
                padding-bottom: 0;
            }

            & > .input-cep {
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                padding-inline: 5px;
                margin-bottom: 7px;
                & > input[type=tel] {
                    padding-left: 15px;
                    height: 35px;
                    width: 150px;
                    font-size: 18px;
                    outline: 0;
                    color: #717171;
                    border: 1px solid rgb(185, 185, 185);
                    border-radius: 5px;
                }
                & > button {
                    background-color: var(--cor-primaria);
                    color: #fff;
                    width: 50px;
                    font-weight: 600;
                    border-radius: 5px;
                }
            }
        }
    }
}

.container-buscar{
    // background-color: blue;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    input[type=text] {
        height: 30px;
        width: 400px;
        border-radius: 8px;
        padding-left: 10px;
        border: none;
        box-shadow: 0px 0px 5px -2.5px rgba(0,0,0,0.75);

        &:focus {
            outline: 0;
            border: 1px solid rgb(172, 172, 172);
        }
    }
}

.buscar-grupo{
    position: relative;
    height: fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    
    & > button {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            color: var(--cor-primaria);
            position: absolute;
            right: 10px;
        }
    }
}

.adicionais-direita{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > button {
        border: 0;
    }
    i {
        font-size: 31px;
        color: var(--cor-primaria);
        margin-right: 5px;
    }
    .login-grupo{
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0);
        span {
            font-size: 13px;
            color: var(--cor-primaria);
        }
    }
    .grupo-carrinho{
        position: relative;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        margin-left: 30px;
        .badge-carrinho {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: -5px;
            top: 0;
            color: #fff;
            font-size: 11px;
            font-weight: 600;
            height: 15px;
            width: 15px;
            border-radius: 50%;
            background-color: rgb(255, 0, 0);

            & > p {
                margin: 0;
            }
        }
    }
}

.dropdown-toggle::after{
    color: var(--cor-primaria) !important;
    opacity: .7;
    margin-left: 30px;
    width: fit-content !important;
}

.spinner-logout{
    border-color: var(--cor-primaria) !important;
    border-right-color: transparent !important;
}
// .fa .fa-user-circle-o:hover{
//     cursor: pointer;
// }

.menu-usuario {
    color: var(--cor-primaria);
    font-size: 14px;
    font-weight: 600;
    & > i {
        width: 20px !important;
        margin-right: 0;
        // background-color: red;
        font-size: 18px !important;
    }
}
</style>
