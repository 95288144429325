<template>
    <modal name="modal-pagamento-pix" styles="background-color: transparent;" width="900" height="auto" @opened="conectaSocketIO()" @before-close="fechaSocketIO()" :clickToClose="false">
        <div class="container-fluid container-modal-pix">
            <div class="row header-modal">
                <div class="col-sm-12 col-md-12 col-lg-3">
                    <p>PEDIDO REALIZADO</p>
                    <span>{{ dtEmissaoPedidoExtenso }}</span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-2">
                    <p>TOTAL</p>
                    <span>R$ {{ valorPagamento }}</span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3">
                    <p>STATUS</p>
                    <span v-if="isPago == 0" style="color: #0044aa;"><i class="fa fa-spinner" aria-hidden="true"></i> Pagamento pendente</span>
                    <span v-else style="color: green;"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Pagamento recebido</span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3">
                    <p>PEDIDO Nº {{ idPedido }}</p>
                    <span>
                        <a href="#">Detalhes</a>
                    </span>
                </div>
                <div class="col-sm-1 col-md-1 col-lg-1 d-flex justify-content-center align-center">
                    <button type="button" class="close" @click.prevent="$modal.hide('modal-pagamento-pix')">&times;</button>
                </div>
            </div>
            <div class="row body-modal">
                <template v-if="isPago == 1">
                    <div class="animContainer">
                        <LottieAnimation
                            ref="anim"
                            :animationData="require('@/assets/alertsuccess.json')"
                            :loop="false"
                            :autoPlay="true"
                        />
                        <span>Pagamento recebido!</span>
                    </div>
                </template>
                <template v-else>
                    <div class="container-fluid pt-3 pb-4">
                        <div class="row">
                            <div class="col-3" style="height: 40px;">
                                <img src="@/assets/logo-pix.svg" alt="" style="height: 100%">
                            </div>
                            <div class="col-6">
                                <h1 style="font-size: 30px; font-family: Roboto, sans-serif; font-weight: 600;">Pagamento via pix</h1>
                            </div>
                            <div class="col-3 d-flex justify-content-center" style="height: 40px;">
                                <img src="" style="height: 100%;">
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 infos-header-body">
                                <h2>Pagar <strong>R${{ valorPagamento }}</strong> para Marketplace by Rillsoft</h2>
                                <p>Abra o app do seu banco, copie e cole este código QR para fazer o pagamento:</p>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-6 col-lg-6" style="border-right: 1px solid #c2c2c2;">
                                <div class="container-qrcode">
                                    <!-- <img src="@/assets/qrcode.png"> -->
                                    <qr-code 
                                    :text="codigoCopiaeCola"
                                    :size="sizeQrCode"
                                    error-level="H">
                                    </qr-code>
                                    <p v-show="avisoPixCopiado">Código copiado!</p>
                                </div>
                                <div class="btn-copiar-pix">
                                    <button @click.prevent="copiarQRcode()"><i class="fa fa-clipboard" aria-hidden="true"></i> Copiar código QR code</button>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="infos-pix">
                                    <div class="info-pix">
                                        <p>Identificador:</p>
                                        <span>{{ identificadorPix }}</span>
                                    </div>
                                    <div class="info-pix">
                                        <p>Prazo de pagamento:</p>
                                        <span>{{ prazoPagamento }}</span>
                                    </div>
                                    <div class="info-pix">
                                        <p>Valor:</p>
                                        <span>R${{ valorPagamento }}</span>
                                    </div>
                                </div>
                                <div class="status-pagamento">
                                    <span class="loader-pix"></span>
                                    <p>Aguardando pagamento</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="row footer-modal">
                <small>Pagamento 100% seguro. Oferecido por: Rillsoft Sistemas LTDA</small>
            </div>
        </div>
    </modal>
</template>
<script>

import io from 'socket.io-client';
import LottieAnimation from 'lottie-web-vue'
import utils from '@/utilitarios/utils';

export default {
    name: 'ModalPagamentoPix',
    props: {
        idPedido: {
            type: String,
            required: true
        },
        dadosPix: {
            type: Object,
            required: true
        },
    },
    components: {
        LottieAnimation
    },
    data: function() {
        return {
            sizeQrCode              : 220,
            avisoPixCopiado         : false,
            connSocketIo            : null,
            isPago                  : 0,

            codigoCopiaeCola        : '',
            identificadorPix        : '',
            prazoPagamento          : '',
            valorPagamento          : '',
            dtEmissaoPedidoExtenso  : '',
            prazoPagamento          : '',
        };
    },
    methods: {
        copiarQRcode(){
            navigator.clipboard.writeText(this.codigoCopiaeCola).then(
                () => {
                    this.avisoPixCopiado = true;
                    setTimeout(() => (this.avisoPixCopiado = false), 4000); 
                },
            );
        },
        formataReal(valor){
            return utils.formataReal(valor);
        },
        conectaSocketIO(){
            this.connSocketIo = io('https://app-rillsoft.com.br');
            this.connSocketIo.on('pagamento-recebido', (data) => {
                if (data.txid != this.identificadorPix) {
                    return;
                }
                this.connSocketIo.disconnect()
                this.isPago = 1;
                setTimeout(() => {
                    this.$modal.hide('modal-pagamento-pix');
                    this.isPago = 0;
                    if (this.$route.name != 'MeusPedidos') {
                        this.$router.push({ name: 'MeusPedidos' });
                    }
                }, 3000);
            });

            this.connSocketIo.on('conectado', (message) => {
                console.log(message.message)
            });
        },
        fechaSocketIO(){
            if (this.connSocketIo) {
                this.connSocketIo.disconnect();
            }
            if (this.$route.name != 'MeusPedidos') {
                this.$router.push({ name: 'MeusPedidos' });
            }
        },
    },
    computed: {
    },
    watch: {
        dadosPix: {
            handler: function(novoValor, valorAntigo) {
                this.codigoCopiaeCola   = novoValor.pixCopiaECola;
                this.identificadorPix   = novoValor.txid;
                this.valorPagamento     = this.formataReal(novoValor.valor.original);

                // Formatar a data de emissão do pedido
                this.dtEmissaoPedidoExtenso = new Date(novoValor.calendario.criacao).toLocaleDateString('pt-BR', { 
                    day: 'numeric', 
                    month: 'long', 
                    year: 'numeric' 
                });

                // Calcular a data de expiração
                const dataCriacao = new Date(novoValor.calendario.criacao);
                const dataExpiracao = new Date(dataCriacao.getTime() + novoValor.calendario.expiracao * 1000);

                // Formatar a data de expiração no formato DD/MM/YYYY HH:mm:ss
                this.prazoPagamento = dataExpiracao.toLocaleString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                });
            },
            deep: true
        },
    },
    mounted: function() {
    },
    beforeDestroy: function() {
    },
    computed: {
        usuarioLogado() {
            return this.$store.state.usuarioLogado;
        },
        clienteSac() {
            return this.$store.state.clienteSac;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.animContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 440px;
    width: 100%;
    &>div {
        width: 100%;
        height: 100%;
    }
    &>span {
        position: absolute;
        font-size: 30px;
        font-weight: 600;
        color: rgb(31, 147, 93);
        bottom: 20px;
    }
}

.container-modal-pix{
	overflow: hidden;
	background-color: #fff;
	border-radius: 10px;
	border: 2px solid #c0c0c0;
	&>.header-modal{
		background-color: #f1f1f1;
		border-bottom: 1px solid #c0c0c0;
		div {
			display: flex;
			flex-direction: column;
			padding-block: 5px;
		}
		p {
			text-align: left;
			margin-bottom: 0;
			font-size: 12px;
		}
		span {
			text-align: left;
			font-size: 14px;
			font-weight: 500;
			color: #000;
			&>a{
				color: #007bff;
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
	&>.body-modal{
		height: fit-content;
		max-height: 600px;
		// overflow-y: scroll;
		// overflow-x: hidden;
		padding: 10px;
		h2{
			font-weight: 600;
			font-size: 20px;
			margin-bottom: 0;
		}
        .infos-header-body{
            &>h2{
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 8px;
                &>strong {
                    font-weight: 600;
                    color: #000;
                }
            }
            &>p{
                font-size: 14px;
                font-weight: 400;
                color: #000;
            }
        }
	}
}
.container-qrcode{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &>p{
        position: absolute;
        top: -20px;
        right: 20px;
        background-color: #00a025;
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        transition: all 0.3s;
    }
    &>div{
        border-radius: 10px;
        border: 3.5px solid var(--cor-primaria);
        padding: 13px;
        width: fit-content;
        height: fit-content;
        background-color: #fff;
    }
}

.btn-copiar-pix{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    &>button{
        background-color: var(--cor-primaria);
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s;
        &:hover{
            background-color: var(--cor-primaria-hover);
        }
    }
}

.infos-pix{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &>.info-pix{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        &>p{
            font-size: 14px;
            font-weight: 500;
            color: #6c6c6c;
            margin-bottom: 0;
        }
        &>span{
            font-size: 16px;
            font-weight: 600;
            color: #000;
        }
    }
}
.status-pagamento{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    &>p{
        font-size: 14px;
        font-weight: 500;
        color: #6c6c6c;
    }
}

.footer-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
    border-top: 1px solid #c0c0c0;
    padding: 10px;
    text-align: center;
    font-size: 12px;
    color: #6c6c6c;
}
</style>
