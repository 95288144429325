
export default {
    formataReal(valor, prefix = '') {
        valor = parseFloat(valor); // Converte o valor para float
        // Cria um formatador de números com estilo decimal
        const formater = new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        
        if (prefix) { // SE TIVER PREFIXO
            return prefix.concat(formater.format(valor));
        } else {
            return formater.format(valor);
        }
    },
    reqAxios(parametros, metodo, url){
        let axios        = require('axios');
        let bodyFormData = new FormData(); 

        for (let i = 0; i < parametros.length; i++){
            let pmt = parametros[i].parametro;
            let vl  = parametros[i].valor;
            bodyFormData.append(pmt, vl);    
        }
        return axios({
            method: metodo,
            // url: 'https://www.rillsoft-app.cloud/'+url,
            url : 'https://www.'+url,
            data: bodyFormData,
            headers: {'Content-Type': 'multipart/form-data' }            
        });           
    },
    buscaCep(){
        return
    },
    IsCEP(strCEP, blnVazio) {
        // Caso o CEP não esteja nesse formato ele é inválido!
        let objER   = /^[0-9]{2}[0-9]{3}-[0-9]{3}$/;
        strCEP      = this.Trim(strCEP)
        if (strCEP.length > 0) {
            if (objER.test(strCEP))
                return true;
            else
                return false;
        }
        else
            return blnVazio;
    },
    Trim(strTexto) {
        // Substitúi os espaços vazios no inicio e no fim da string por vazio.
        return strTexto.replace(/^s+|s+$/g, '');
    },
    primeiraMaiuscula(text) {
        try{
            if (text !== '') {
                let texto = text.toLowerCase()
                return texto[0].toUpperCase() + texto.slice(1);
            } else {
                return '';
            }
        }
        catch(e) {
            return text;
        }
    },
    limpaCnpjCpf(cnpjcpf){
        return cnpjcpf.replace(/[.\-\/]/g, '');
    },
    dataAtual(barra, pegahora){ 
        var datacompleta = new Date();
        var imes = datacompleta.getMonth();
        var strhora = "";
        if (pegahora){
            var hora    = datacompleta.getHours();          // 0-23
            var min     = datacompleta.getMinutes();        // 0-59
            var seg     = datacompleta.getSeconds();        // 0-59
            strhora     = ' '+hora+':'+min+':'+seg;
        }
        ++imes;
        var mesabrev = imes + "";
        if (mesabrev.length == 1) {
            mesabrev = "0" + mesabrev;
        }
        var dataabrev = datacompleta.getDate() + "";

        if (dataabrev.length == 1) {
            dataabrev = "0" + dataabrev;
        }
        var anoatual = datacompleta.getFullYear();
        if (barra) {
            return anoatual + '/' + mesabrev + '/' + dataabrev + strhora;
        } else {
            return dataabrev + mesabrev + anoatual + strhora;
        }
    },
    capitalizeText(value) { // gpt fez
        if (!value) return '';
        value = value.toString().toLowerCase();
        return value.replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    },
    prepDescricaoRouter(descricao) { // gpt fez
        // Substituir espaços por hífen
        let descricaoFormatada = this.capitalizeText(descricao).replace(/\s+/g, '-');
        
        // Remover caracteres especiais não permitidos em URLs
        descricaoFormatada = descricaoFormatada.replace(/[^a-zA-Z0-9-]/g, '');
        
        return descricaoFormatada;
    },
    dataPorExtenso(data) { // converte a data por exemplo 01/01/2022 para 1 de janeiro de 2022
        if (!data) return '';
        const meses = [
            "janeiro",
            "fevereiro",
            "março",
            "abril",
            "maio",
            "junho",
            "julho",
            "agosto",
            "setembro",
            "outubro",
            "novembro",
            "dezembro"
        ];

        const partesData = data.split("/");
        const dia = partesData[0];
        const mes = meses[parseInt(partesData[1]) - 1];
        const ano = partesData[2];

        return `${dia} de ${mes} de ${ano}`;
    },
    removeAcentos(texto){
        const acentos = {
            'á': 'a', 'ã': 'a', 'â': 'a', 'à': 'a', 'ä': 'a',
            'é': 'e', 'ê': 'e', 'è': 'e', 'ë': 'e',
            'í': 'i', 'î': 'i', 'ì': 'i', 'ï': 'i',
            'ó': 'o', 'õ': 'o', 'ô': 'o', 'ò': 'o', 'ö': 'o',
            'ú': 'u', 'û': 'u', 'ù': 'u', 'ü': 'u',
            'ç': 'c',
            'Á': 'A', 'Ã': 'A', 'Â': 'A', 'À': 'A', 'Ä': 'A',
            'É': 'E', 'Ê': 'E', 'È': 'E', 'Ë': 'E',
            'Í': 'I', 'Î': 'I', 'Ì': 'I', 'Ï': 'I',
            'Ó': 'O', 'Õ': 'O', 'Ô': 'O', 'Ò': 'O', 'Ö': 'O',
            'Ú': 'U', 'Û': 'U', 'Ù': 'U', 'Ü': 'U',
            'Ç': 'C'
        };
    
    return texto.split('').map(letra => acentos[letra] || letra).join('');
    },
    limitaTexto(texto, limite) {
        if (texto.length <= limite) {
            return texto;
        }
        return texto.substring(0, limite) + '...';
    },
    extensaoArquivo(arquivo){
        var value = arquivo.name;
        var values = value.split('.'); 
        if (values.length > 0){
            return values[values.length - 1];
        } else {
            return '';
        }        
    },
    GerarStringAleatoria() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      
        for (var i = 0; i < 20; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      
        return text;
    },
} 
