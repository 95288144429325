<template>
    <div class="pc-container">
        <router-link :to="{ name: 'DetalhesProduto', params: { idproduto: produtoData.id, nomeproduto: preparaDescricaoRouter(produtoData.descricao) } }">
            <div class="pc-img-produto">
                <img :src="'https://'+clienteSac.endServidor+'/controller/ecommerce/'+produtoData.imagem.patharquivo" style="height: 140px;">
            </div>
            <div class="pc-desc-vlpromoc">
                <small v-if="parseFloat(produtoData.desconto) > 0">{{ formataReal(produtoData.valor, 'R$') }}</small><span v-if="parseFloat(produtoData.desconto) > 0">-{{produtoData.desconto}}% OFF</span>
            </div>
            <div class="pc-nome-produto">
                <h6>{{ toCapitalized(produtoData.descricao) }}</h6>
            </div>
            <div class="pc-cod-refer">
                <p>Ref.: {{ produtoData.id }}</p>
            </div>
            <template v-if="parseFloat(produtoData.valor) > 0">
                <div class="pc-vlproduto-pix">
                    <span>{{ formataReal(produtoData.valor, 'R$') }} no pix</span>
                </div>
                <div class="pc-desc-parc-cartao">
                    <p>ou 1x de {{ formataReal(produtoData.valor, 'R$') }} sem juros no cartão</p>
                </div>
            </template>
            <template v-else>
                <div class="pc-tit-prod-ind">
                    <span>Produto Indisponível</span>
                </div>
                <div class="pc-desc-prod-ind">
                    <p>Este produto encontra-se sem estoque no momento.</p>
                </div>
            </template>
            <div class="pc-footer-marca">
                <p>Marca: {{ produtoData.descmarca }}</p>
            </div>
        </router-link>
    </div>
</template>
<script>

import utils from '@/utilitarios/utils';

export default {
    name: 'CardProduto',
    props: {
        produtoData: {
            type: Object,
            required: true
        },
    },
    components: {

    },
    data: function() {
        return {
            
        };
    },
    methods: {
        preparaDescricaoRouter(descricao){
			return utils.prepDescricaoRouter(descricao);
		},
        toCapitalized(value){
			return utils.capitalizeText(value);
		},
        formataReal(valor, prefix) {
            return utils.formataReal(valor, prefix)
        },
    },
    computed: {
        clienteSac(){
			return this.$store.state.clienteSac;
		},
		filial(){
			return this.$store.state.filial;
		},
		destaquesProdutos(){
			return this.$store.state.destaquesProdutos;
		},
		usuarioLogado() {
			return this.$store.state.usuarioLogado;
		},
		configPaths(){
			return this.$store.state.configPaths;
		},
		produtosAleatorios(){
			return this.$store.state.produtosAleatorios;
		}
    },
    watch: {
        
    },
    mounted: function() {

    },
    beforeDestroy: function() {

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.pc-container{
    height: 320px !important;
    width: 245px !important;

    & > a {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        height: 320px !important;
        width: 245px !important;
        background-color: rgb(255, 255, 255);
        padding-top: 10px;

        border: 3px solid #BCBCBC;
        border-radius: 10px;

        text-decoration: none;
    }
}

.pc-img-produto{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    
    & > img {
        height: 110px !important;
        max-width: 170px !important;
        width: 100% !important;
        object-fit: cover !important;
        overflow: hidden !important;
    }
}

.pc-desc-vlpromoc{
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 20px;
    padding-inline: 25px;

    & > small {
        font-size: 15px !important;
        font-weight: 400;
		color: rgb(106, 106, 106);
		text-decoration: line-through;
	}

    & > span {
        background-color: green;
        color: white;
        font-weight: 500;
        font-size: 12px;
        border-radius: 5px;
        padding-inline: 6px;
        margin-left: 5px;
        height: 17px;
    }
}

.pc-nome-produto{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 135px;
    padding-inline: 25px;

    & > h6 {
        font-size: 20px !important;
        font-weight: 500 !important;
        color: #000 !important;

        display: -webkit-box;
		line-clamp: 2;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: 0;
    }
}

.pc-cod-refer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 54px;

    & > p {
        font-size: 13px;
        font-weight: 600;
        color: #868686;
        margin: 0;
    }
}

.pc-vlproduto-pix{
    display: flex;
    width: 100%;
    padding-inline: 25px;
    & > span {
        color: var(--cor-primaria);
		font-weight: 600;
		font-size: 20px
    }
}

.pc-desc-parc-cartao{
    display: flex;
    width: 100%;
    min-height: 40px;
    padding-inline: 25px;
    & > p {
		font-size: 13px;
		margin: 0;
		text-align: left;
        color: #868686;
    }
}

.pc-footer-marca{
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    height: 90px;
    background-color: #e2e2e2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    & > p {
        margin: 0;
        color: #636363;
        font-size: 14px;
        padding-inline: 25px;
    }
}

.pc-tit-prod-ind {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-inline: 25px;
    & > span {
        color: #ae0000b9;
		font-weight: 600;
        text-align: center;
		font-size: 18px
    }
}

.pc-desc-prod-ind {
    display: flex;
    width: 100%;
    min-height: 40px;
    padding-inline: 25px;
    & > p {
		font-size: 13px;
		margin: 0;
		text-align: center;
        color: #868686;
    }
}
</style>
