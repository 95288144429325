<template>
	<div class="produtos-categoria">
		<!-- EXIBE O HEADER PARA DESKTOP -->
		<div class="d-none d-sm-none d-md-none d-lg-block">
			<HeaderDesktop></HeaderDesktop>
		</div>
		
		<!-- EXIBE O HEADER PARA TABLET -->
		<div class="d-none d-sm-none d-md-block d-lg-none">
			<HeaderTablet></HeaderTablet>
		</div>
		
		<!-- EXIBE O HEADER PARA MOBILE -->
		<div class="d-block d-sm-block d-md-none d-lg-none">
			<HeaderMobile></HeaderMobile>
		</div>
		<div class="breadcrumb-produtos-categoria">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">
						<router-link to="/" style="color: var(--cor-primaria-scroll);">Home</router-link>
					</li>
					<!-- <li class="breadcrumb-item"><a href="#">Eletrônicos</a></li> -->
					<li class="breadcrumb-item active" aria-current="page">{{ toCapitalized(descricaoCategoria) }}</li>
				</ol>
			</nav>
		</div>
		<div class="container-conteudo-principal">
			<div v-if="isLoading" class="container-loading-inicial">
				<div class="spinner-border" style="width: 2.3rem; height: 2.3rem; margin-right: 11px;" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
			<template v-else>
				<div class="header-categoria">
					<div class="titulo-categoria">
						<h1>{{ toCapitalized(descricaoCategoria) }}</h1>
					</div>
					<div class="qtd-produtos">
						<p>Exibindo {{ qtdProdutosCategoria }} de {{ qtdProdutosCategoria }} produtos</p>
					</div>
				</div>
				<div class="container-filtro-produtos">
					<section class="side-produtos-filtro">
						<nav class="filtros-produtos">
							<div class="filtro-produto">
								<h3>Filtrar por Marca:</h3>
								<div v-for="marca in lstMarcas" :key="marca.id">
									<input type="checkbox" :id="marca.id" v-model="vMarcaFiltro" :value="marca.id">
									<label :for="marca.id">{{ toCapitalized(marca.descricao) }}</label>
								</div>
							</div>
						</nav>
						<nav class="filtros-produtos">
							<div class="filtro-produto">
								<h3>Filtrar por Preço: <button><i class="fa fa-search" aria-hidden="true"></i></button></h3>
								<template>
									<!-- trocar esse slider por um de min e max -->
									<range-slider
										class="slider"
										:disabled="vPrecoFiltroAtivaDesativa"
										:min="vPrecoFiltroMin"
										:max="vPrecoFiltroMax"
										:step="vPrecoFiltroSteps"
										v-model="vPrecoFiltro">
									</range-slider>
								</template>
								<div class="val-min-max-filtro">
									<span>R$ {{ vPrecoFiltroMin.toFixed(2).replace('.', ',') }}</span>
									<span> até </span>
									<span>R$ {{ vPrecoFiltro.toFixed(2).replace('.', ',') }}</span>
								</div>
							</div>
						</nav>
						<!-- <nav class="filtros-produtos">
							<div class="filtro-produto">
								<h3>Filtrar por Desconto:</h3>
								<div v-for="desconto in descontos" :key="desconto.id">
									<input type="checkbox" :id="desconto.id" v-model="vDescontoFiltro" :value="desconto.id">
									<label :for="desconto.id">{{ desconto.nome }}</label>
								</div>
							</div>
						</nav> -->
					</section>
					<section class="side-listagem-produtos">
						<template v-if="lstProdutosCategoria.length > 0">
							<div class="container-produtos">
								<CardProduto 
								v-for="(produto, indexProdt) in lstProdutosCategoria" 
								:key="indexProdt" 
								:produtoData="produto"
								/>
								<!-- <div v-for="(produto, j) in lstProdutosCategoria" :key="j" class="card d-flex flex-column card-destaques">
									<router-link :to="{ name: 'DetalhesProduto', params: { idproduto: produto.id, nomeproduto: preparaDescricaoRouter(produto.nomeproduto) } }" style="color: black;">
										<div class="header-card">
											<img :src="'https://'+$store.state.clienteSac.endServidor+'/controller/ecommerce/'+produto.imagem.patharquivo">
										</div>
										<div class="footer-card">
											<h6 style="margin-inline: auto;">{{ toCapitalized(produto.nomeproduto) }}</h6>
											<small>R${{ String(produto.valor).replace('.', ',') }} <span class="badge badge-success">-15% OFF</span></small>
											<span>R${{ String(produto.valor).replace('.', ',') }} no pix</span>
											<p>ou R$2.250,00 em 5x de R$450,00 sem juros</p>
										</div>
									</router-link>
								</div> -->
							</div>
						</template>
						<template v-else>
							<div class="nenhum-produto-listagem">
								<i class="fa fa-search" aria-hidden="true"></i>
								<h3>Não encontramos nenhum produto!</h3>
							</div>
						</template>
					</section>
				</div>
			</template>
		</div>
    </div>
</template>

<script>
// @ is an alias to /src
import HeaderDesktop 	from '@/components/Header/HeaderDesktop.vue';
import HeaderTablet 	from '@/components/Header/HeaderTablet.vue';
import HeaderMobile 	from '@/components/Header/HeaderMobile.vue';
import CardProduto      from '@/components/CardProduto/CardProduto.vue';

import utils from '@/utilitarios/utils';

import RangeSlider from 'vue-range-slider'
// you probably need to import built-in style
import 'vue-range-slider/dist/vue-range-slider.css'

export default {
	name: 'ProdutosCategoria',
	components: {
		HeaderDesktop,
		HeaderTablet,
		HeaderMobile,
		CardProduto,
		RangeSlider
	},
	data: function() {
        return {
			isLoading				: true,
			descricaoCategoria   	: '',
			qtdProdutosCategoria	: 0,
			qtdProdutosPesquisa		: 0,
			lstProdutosCategoria	: [],

			imagensProdutos		: [
				{url: 'https://picsum.photos/seed/tree/1000'},
				{url: 'https://picsum.photos/seed/airplane/1000'},
				{url: 'https://picsum.photos/seed/car/1000'},
				{url: 'https://picsum.photos/seed/robot/1000'},
				{url: 'https://picsum.photos/seed/earth/1000'},
			],
			lstMarcas: [],
			lstPrecos: [
				{id: 1, nome: 'Até R$5,00'},
				{id: 2, nome: 'R$5,00 a R$10,00'},
				{id: 3, nome: 'R$10,00 a R$20,00'},
				{id: 4, nome: 'R$20,00 a R$50,00'},
				{id: 5, nome: 'R$50,00 a R$100,00'},
				{id: 6, nome: 'R$100,00 a R$200,00'},
				{id: 7, nome: 'R$200,00 a R$500,00'},
				{id: 8, nome: 'R$500,00 a R$1.000,00'},
			],
			descontos: [
				{id: 1, nome: 'Até 10%'},
				{id: 2, nome: '10% a 30%'},
				{id: 4, nome: '30% a 60%'},
				{id: 6, nome: '60% a 80%'},
				{id: 7, nome: 'Mais de 80%'},
			],
			vMarcaFiltro				: [],

			vPrecoFiltro				: 0,
			vPrecoFiltroMin				: 0,
			vPrecoFiltroMax				: 0,
			vPrecoFiltroSteps			: 1,

			vDescontoFiltro				: '',
        };
    },
    methods: {
		toCapitalized(value){
			return utils.capitalizeText(value);
		},
		preparaFiltroMarcas(){
			// O return desse método, caso haja marcas selecionadas, será uma string com " AND m.id IN (ids selecionados)"
			// To testando esse tipo de filtragem, se ficar ruim, eu mudo depois...
			let filtroMarcas = '';
			if(this.vMarcaFiltro.length > 0){
				filtroMarcas = ' AND m.id IN ('+this.vMarcaFiltro.join(',')+')';
			}
			return filtroMarcas;
		},
		preparaFiltroPrecos(){

		},
		carregaDadosCategoria(){
			let vself = this;
			vself.isLoading = true;
			let filtroMarcas = vself.preparaFiltroMarcas();
			let parametros = [
                {parametro: 'op',           	valor: 'carregadadoscategoria'},
                {parametro: 'idclientesac',     valor: this.$store.state.clienteSac.id},
				{parametro: 'idcategoria',      valor: this.$route.params.idcategoria},
				{parametro: 'idtabelaprecos',	valor: this.$store.state.filial.idtabelaprecos},
				{parametro: 'idestoque',		valor: this.$store.state.filial.idtabelaestoque},
				{parametro: 'pathimagens',		valor: this.$store.state.configPaths.pathImgProdutos},
				{parametro: 'filtromarcas',		valor: filtroMarcas},
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtprodutoscategoria.php');
            retorno.then(function (response) {
				// Fazer rotina para caso não tenha produtos para essa categoria
				vself.lstProdutosCategoria	= [];
				vself.lstMarcas = [];
				vself.descricaoCategoria	= vself.$route.params.desccategoria;
				
				if(parseInt(response.data.qtdprodutos) > 0){
					vself.lstProdutosCategoria 	= response.data.produtos;
					vself.lstProdutosCategoria.forEach(produto => { produto.imagem = vself.ordenaImagensProduto(produto.imagem)[0] });
					vself.lstMarcas 			= response.data.marcas;
					vself.descricaoCategoria 	= response.data.descategoria;
					
					vself.vPrecoFiltroMax 		= parseFloat(response.data.vlmaxprodutos);
					vself.vPrecoFiltro 			= vself.vPrecoFiltroMax;
					vself.vPrecoFiltroSteps 	= vself.vPrecoFiltroMax / 10;
				}
				vself.qtdProdutosCategoria	= response.data.qtdprodutos;
				vself.isLoading = false;
            }).catch(function (response) {
                console.log(response.message);
				vself.isLoading = false;
            });
		},
		ordenaImagensProduto(lstimagens){
			let lstordenada = lstimagens.sort((a, b) => {
				if (a.arquivo < b.arquivo) {
					return -1;
				}
				if (a.arquivo > b.arquivo) {
					return 1;
				}
				return 0;
			});
			return lstordenada;
		},
		preparaDescricaoRouter(descricao){
			return utils.prepDescricaoRouter(descricao);
		}
    },
	computed: {
        clienteSac(){
            return this.$store.state.clienteSac;
        },
        filial(){
            return this.$store.state.filial;
        },
        usuarioLogado() {
            return this.$store.state.usuarioLogado;
        },
        configPaths() {
            return this.$store.state.configPaths;
        },
        qtdItensCarrinho(){
            return this.$store.state.carrinhoCompras.qtdItens;
        },
        infosGeraisProdutos(){
            return this.$store.state.infosGeraisProdutos;
        },

		vPrecoFiltroAtivaDesativa(){
			return this.vPrecoFiltroMax <= 0;
		},
    },
	mounted: function() {
		this.carregaDadosCategoria();
		// console.log(window.localStorage.getItem('usuarioLogado'));
		// console.log(this.$store.state.usuarioLogado);
	},
	watch: {
		'$route.params.idcategoria': function(){
			this.carregaDadosCategoria();
		},
		vMarcaFiltro: function(){
			this.carregaDadosCategoria();
		},
		vPrecoFiltro: function(){
			console.log(this.vPrecoFiltro);
		},
	}
}
</script>
<style scoped lang="scss">

.produtos-categoria {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.066)
}
.breadcrumb-produtos-categoria{
	margin-top: 15px;
	width: 1100px;
	height: 30px;
	font-size: 15px;
	margin-inline: auto;
	&>nav .breadcrumb{
		background-color: transparent;
		padding: 0;
		margin: 0;
		&>.breadcrumb-item a{
			color: var(--cor-primaria-scroll);
		}
		&>.breadcrumb-item.active{
			color: var(--cor-primaria);
		}
	}
}


.container-conteudo-principal{
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	overflow: hidden;
	background-color: #fff;
	width: 1100px;
	margin-inline: auto;
	min-height: 500px;
	margin-bottom: 50px;
}

.container-loading-inicial{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 60vh;
}

.header-categoria{
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.titulo-categoria{
	display: flex;
	h1{
		font-size: 30px;
		margin-block: 15px;
		padding-left: 15px;
	}
}

.qtd-produtos{
	display: flex;
	align-items: center;
	font-size: 15px;
	margin-block: 15px;
	padding-right: 15px;
}

.side-listagem-produtos{
	width: 100%;
}

.nenhum-produto-listagem{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 100px;
	&>i{
		font-size: 50px;
		color: #c0c0c0;
		margin-bottom: 10px;
	}
	&>h3{
		font-size: 23px;
		color: var(--cor-primaria);
		opacity: .7;
		margin-bottom: 0;
	}
}

.opcoes-categorias{
	display: flex;
	justify-content: space-around;
	margin-bottom: 20px;
}

.container-filtro-produtos{
	display: flex;

}
.side-produtos-filtro{
	display: flex;
	flex-direction: column;
	&>nav {
		width: 260px;
		// background-color: red;
		margin-bottom: 5px;
		padding: 20px;
	}
}
.filtros-produtos {
	width: 100%;
	// background-color: red;
}

.val-min-max-filtro{
	display: flex;
	width: 160px;
	// background-color: red;
	justify-content: space-between;
	font-size: 15px;
	margin-top: 5px;
}
.filtro-produto {
	display: flex;
	flex-direction: column;
	align-items: start;
	& > h3 {
		font-size: 17px;
		margin-bottom: 10px;

		& > button {
			background-color: #bcbcbc;
			color: white;
			font-size: 12px;
			border: none;
			border-radius: 5px;
			padding: 5px 10px;
			margin-left: 10px;
			cursor: pointer;

			&:hover {
				background-color: #a8a8a8;
			}
		}
	}
	& > div {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
		& > input {
			width: 22px;
			height: 22px;
			margin-right: 5px;
		}
		& > label {
			font-size: 15px;
			margin-bottom: 0;
		}
	}
}

.container-produtos{
	display: grid;
	grid-template-columns: repeat(3, 1fr); /* 3 colunas */
	gap: 20px;

}
@media (max-width: 768px) {
	.container-produtos {
		grid-template-columns: repeat(2, 1fr); /* 2 colunas */
	}
}


.card-destaques{
	min-height: 300px;
	width: 250px;
	margin-inline: 5px !important;
	margin-bottom: 5px !important;
	border: 2px #bcbcbc solid;
	&:hover{
		border: 1px var(--cor-primaria) solid;
		box-shadow: 0 0 4px var(--cor-primaria);
	}
	// @media (max-width: 1250px) { // RESPONSIVO TABLET
	// 	height: 330px;
	// }
}

.header-card {
	display: flex;
	justify-content: center;
	align-items: center;
	& > img {
		height: 190px;
		padding: 10px;
	}
}
.footer-card {
	display: flex;
	flex-direction: column;
	align-items: start;
	padding-bottom: 8px;
	padding-inline: 15px;
	& > h6 {
		font-size: 1.1rem;
		display: -webkit-box;
		line-clamp: 3;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: 0;
		margin-bottom: 5px;
	}
	& > small {
		margin-bottom: 5px;
		color: rgb(106, 106, 106);
		text-decoration: line-through;
	}
	& > span {
		color: var(--cor-primaria);
		font-weight: 600;
		font-size: 20px
	}
	& > p {
		min-height: 25px;
		font-size: 13px;
		min-width: 50px;
		margin: 0;
		text-align: left;
	}
}

.opcao-categoria{
	display: flex;
	flex-direction: column;
	& > label{
		text-align: start;
		font-size: 15px;
		margin-bottom: 5px;
		font-weight: 500;
	}
	& > select{
		width: 150px;
		height: 35px;
	}
}
</style>