<template>
    <div id="carrinho-id" class="bg-carrinho-compras"">
        <div class="carrinho-compras" v-click-outside="hideCart">
            <div class="titulo-carrinho">
                <h2>Resumo do seu carrinho</h2>
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
            </div>
            <div class="divisoria-carrinho"></div>  <!-- DIVISÓRIA -->
            <div class="lista-produtos-carrinho">
                <div v-for="produto in lstProdutos" class="produto-carrinho">
                    <div class="foto-produto">
                        <div class="qtd-item-carrinho"><span>{{ produto.qtdItensCarrinho }}</span></div>
                        <img src="../../assets/images/produtos/1g.avif" alt="">
                    </div>
                    <div class="descricao-produto">
                        <p>{{ produto.descricao }}</p>
                        <div class="divisoria-carrinho" style="width: 95%;"></div>  <!-- DIVISÓRIA -->
                        <!-- <money class="total-produto-carrinho" v-bind="money" v-model="produto.valorReal"></money> -->
                        <span>{{formataReal(produto.qtdItensCarrinho*parseFloat(produto.valorReal), 'R$')}}</span>

                    </div>
                    <div class="valor-quantidade">
                        <div class="seletor-quantidade">
                            <button class="btn-mais" @click="produto.qtdItensCarrinho++"><i class="fa fa-plus" aria-hidden="true"></i></button>
                            <input v-model="produto.qtdItensCarrinho" type="text" name="" id="">
                            <button class="btn-menos" @click="produto.qtdItensCarrinho--"><i class="fa fa-minus" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divisoria-carrinho"></div>  <!-- DIVISÓRIA -->
            <div class="rodape-carrinho">
                <div class="codigo-promocao-carrinho"></div>
                <div class="calculos-carrinho">
                    <div class="valor-subtotal">
                        <p>Subtotal</p>
                        <span>R$199,90</span>
                    </div>
                    <div class="valor-frete">
                        <p>Frete</p>
                        <span>R$9,45</span>
                    </div>
                    <div class="valor-descontos">
                        <p>Descontos</p>
                        <span>R$12,00</span>
                    </div>
                </div>
                <div class="divisoria-carrinho"></div>
                <div class="finalizar-carrinho">
                    <div class="valor-total"></div>
                    <div class="botoes-finalizar">
                        <button>Finalizar compra</button>
                        <button @click="hideCart">Continuar comprando</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import utils from '@/utilitarios/utils';
import {Money} from 'v-money'
export default {
    name: 'CartMobile',
    props: {
        hideCart: {
            type: Function,
            required: true
        }
        // msg: String
    },
    components: {
        Money
    },
    data: function() {
        return {
            // CONFIGURAÇÃO COMPONENTE MONEY
            money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'R$',     //aqui colocar o cifrão se for necessário
                    precision: 2,
                    masked: false
                },

            // CONFIGURAÇÕES DO CLIENTE
            temaFilial              : '', // AQUI TRÁS O TEMA DE CORES ESCOLHIDO NA CONFIGURAÇÃO DO WEBSITE NO SISTEMA
            
            // DADOS CARRINHO
            qtdItensCarrinho        : '1',
            lstProdutos: [
				{
					descricao: 'Smart TV 55" 4K LG NanoCell 55NANO77SRA Bluetooth, ThinQ AI, Alexa',
					valorReal: '2250',
					porcentagemDesc: '13',
					urlimg: '1g.avif',
					outro: 'ou R$2.250,00 em 5x de R$450,00 sem juros',
					qtdItensCarrinho: 1,
				},
				{
					descricao: 'Smart Tv Samsung 32 Hd Tizen T4300 Bivolt',
					valorReal: '1899,00',
					valorDesc: '1.059,00',
					porcentagemDesc: '44',
					urlimg: '1g.avif',
					outro: 'ou R$2.250,00 em 10x de R$105,00 sem juros',
					qtdItensCarrinho: 35,
				},
				{
					descricao: 'Apple iPhone 15 Pro Max 256 GB -Titânio Natural',
					valorReal: '8998,89',
					valorDesc: '8099,00',
					porcentagemDesc: '14',
					urlimg: '1g.avif',
					outro: 'ou R$8.998,89 em 10x de R$899,89 sem juros',
					qtdItensCarrinho: 4,
				},
				{
					descricao: 'Smart TV 55" 4K LG NanoCell 55NANO77SRA Bluetooth, ThinQ AI, Alexa',
					valorReal: '2250,00',
					valorDesc: '1957,50',
					porcentagemDesc: '13',
					urlimg: '1g.avif',
					outro: 'ou R$2.250,00 em 5x de R$450,00 sem juros',
					qtdItensCarrinho: 125,
				},
				{
					descricao: 'Smart Tv Samsung 32 Hd Tizen T4300 Bivolt',
					valorReal: '1899,00',
					valorDesc: '1059,00',
					porcentagemDesc: '44',
					urlimg: '1g.avif',
					outro: 'ou R$2.250,00 em 10x de R$105,00 sem juros',
					qtdItensCarrinho: 10,
				},
				{
					descricao: 'Smart Tv Samsung 32 Hd Tizen T4300 Bivolt',
					valorReal: '1899,00',
					valorDesc: '1059,00',
					porcentagemDesc: '44',
					urlimg: '1g.avif',
					outro: 'ou R$2.250,00 em 10x de R$105,00 sem juros',
					qtdItensCarrinho: 15,
				},
			],
        };
    },
    methods: {
        fechaCart(){
            $('#carrinho-id').hide();
        },
        formataReal(valor, prefix) {
            return utils.formataReal(valor, prefix)
        },
    },
    computed: {
        // calculaSubtotal(){
        //     return 
        // }
    },
    // watch: {
    //     mostra(val) {
    //         console.log(val);
    //     }
    // }

    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.divisoria-carrinho {
    width: 100%;
    height: 2px;
    background-color: rgb(220, 220, 220);
    margin-block: 4px;
}
.bg-carrinho-compras{
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.47);
    display: flex;
    justify-content: center;
    align-items: center;
}

.carrinho-compras {
    height: 85vh;
    width: 90vw;
    border-radius: 10px;
    background-color: rgb(236, 236, 236);
    display: flex;
    flex-direction: column;
    padding: 15px;
    & > .titulo-carrinho {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > h2 {
            height: 100%;
            font-size: 18px;
            font-weight: 600;
            padding: 0;
            margin: 0;
        }
        & > i {
            text-align: center;
            height: 100%;
            font-size: 20px;
        }
    }
    & > .lista-produtos-carrinho {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        // background-color: red;
    }

}

.rodape-carrinho {
    // height: fit-content;
    width: 100%;
    // background-color: blue;
}

.calculos-carrinho{
    display: flex;
    flex-direction: column;
    & > .valor-subtotal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > p {
            font-size: 25px;
            font-weight: 600;
            margin: 0;
        }
        & > span {
            font-size: 20px;
            margin: 0;
        }
    }
    & > .valor-frete {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > p {
            margin: 0;
            font-size: 18px;
            color: rgb(133, 133, 133);
        }
        & > span {
            margin: 0;
            font-size: 18px;
            color: rgb(133, 133, 133);
        }
    }
    & > .valor-descontos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > p {
            margin: 0;
            font-size: 18px;
            color: rgb(133, 133, 133);
        }
        & > span {
            margin: 0;
            font-size: 18px;
            color: rgb(88, 174, 90);
            text-decoration: line-through;
        }
    }
}

.finalizar-carrinho{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    & > .botoes-finalizar {
        display: flex;
        flex-direction: column;
        padding-inline: 10px;
        :first-child {
            border: 0;
            width: 100%;
            height: 50px;
            border-radius: 10px;
            background-color: var(--cor-primaria);
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 8px;
        }
        :last-child {
            border: 2px solid #b1b1b1;
            font-weight: 600;
            background-color: #fff;
            height: 45px;
            width: 100%;
            border-radius: 10px;
            color: #3e3e3e;
        }

    }
}

.produto-carrinho {
    display: flex;
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 10px;
    & > .foto-produto {
        height: 100%;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        & > .qtd-item-carrinho {
            position: absolute;
            background-color: rgb(208, 208, 208);
            color: #242424;
            padding-inline: 3px;
            min-width: 23px;
            height: 23px;
            border-radius: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 8px;
            right: -10px;
            & > span {
                font-weight: 600;
            }
        }
        & > img {
            margin-left: 10px;
            padding: 4px;
            border-radius: 10px;
            border: 2px solid rgb(208, 208, 208);
            width: 100%;
        }
    }
    & > .descricao-produto {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        margin-left: 15px;
        text-align: left;
        & > p {
            font-size: 14px;
            width: 190px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
        }
        & > span {
            margin: 0;
            font-weight: 600;
            color:#242424b9 ;
        }

    }
}
.valor-quantidade {
    height: 100%;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > .seletor-quantidade {
        margin-right: 10px;

        & > .btn-mais {
            width: 30px;
            background-color: #f3f3f3;
            border: 2px solid rgb(233, 233, 233);
            color: #2424246f;
        }
        & > input[type="text"]{
            width: 30px;
            text-align: center;
            // font-weight: 600;
            background-color: #fff;
            border-top: 0;
            border-left: 2px solid rgb(233, 233, 233);
            border-right: 2px solid rgb(233, 233, 233);
            border-bottom: 0;
        }
        & > .btn-menos {
            width: 30px;
            background-color: #f3f3f3;
            border: 2px solid rgb(233, 233, 233);
            color: #2424246f;
            // font-size: 20px;
        }
    }

}
</style>
