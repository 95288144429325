<template>
    <div class="header">
        <!-- CARRINHO DE COMPRAS -->
        <CartMobile v-if="showCart" :hideCart="toggleCart"></CartMobile> 
        <!-- CARRINHO DE COMPRAS -->
        <div class="container-top">
            <div class="botao-menu-side" @click="toggleMenuSide">
                <i class="fa fa-bars" aria-hidden="true"></i>
            </div>
            <div class="logo-filial">
                <a href="#">
                    <img v-if="imgLogoFilial != ''" :src="imgLogoFilial">
                </a>
            </div>
            <div class="adicionais-direita">
                <button class="grupo-login"><i class="fa fa-user-circle-o" aria-hidden="true"></i></button>
                <button class="grupo-carrinho" @click="toggleCart">
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                    <div class="badge-carrinho"><p>{{ qtdItensCarrinho }}</p></div>
                </button>
            </div>
        </div>
        <div class="container-buscar">
                <div class="buscar-grupo">
                    <a href="#"><i class="fa fa-search" aria-hidden="true"></i></a>
                    <input type="text" placeholder="Buscar produtos...">
                </div>
            </div>
        <nav class="rodape-header">
            <div class="container-cep">
                <div class="grupo-cep">
                    <button @click="toggleModalCep"><i class="bi bi-geo-alt"></i> Informe seu CEP</button>
                </div>
            </div>
        </nav>
        <div v-show="showCep" class="bg-modal-cep" @click="toggleModalCep"></div>
        <div class="modal-cep-header" v-show="showCep">
            <p>A gente ajuda a encontrar o seu produto com <strong>menor frete</strong> e <strong>prazo de entrega</strong>.</p>
            <div class="input-cep">
                <div class="grupo-input-cep d-flex flex-column">
                    <label for="inpt-cep">Digite seu CEP:</label>
                    <input type="text" id="inpt-cep" value="66055-225">
                    <!-- <input type="text" id="inpt-cep" value="_____-__"> -->
                </div>
                <button @click="toggleModalCep">OK</button>
            </div>
        </div>
        <div v-show="showMenuSide" @click="toggleMenuSide" class="bg-menu-side"><i class="bi bi-x-lg"></i></div>
        <div v-show="showMenuSide" class="menu-side">
            <button class="login-side">
                <i class="fa fa-user-circle-o" aria-hidden="true"></i> Fazer Login</button>
            <button class="ir-carrinho">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i> Ir para carrinho <span class="badge badge-danger">0</span></button>
            <ul>
                <li v-for="item in categoriasProdutos.todas">{{ item }}</li>
            </ul>
        </div>
    </div>
</template>
<script>

import CartMobile from '../CarrinhoDeCompras/CartMobile.vue';

export default {
    name: 'HeaderMobile',
    components: {
        CartMobile
    },
    props: {
        color: String
        // msg: String
    },
    data: function() {
        return {
            testeCarrinho : '',
            // DADOS FILIAL
            imgLogoFilial           : 'https://i.imgur.com/8lJ6gDQ.png',
            fantasiaFilia           : '',

            // EXIBE MODAIS
            showCep: false,
            showMenuSide: false,
            showCart: false,

            // CONFIGURAÇÕES DO CLIENTE
            temaFilial              : '', // AQUI TRÁS O TEMA DE CORES ESCOLHIDO NA CONFIGURAÇÃO DO WEBSITE NO SISTEMA
            categoriasProdutos: {
                todas: [
                    'Masculinas',
                    'Femininas',
                    'Infantis',
                    'Esportivas',
                    'Casuais',
                    'Mexirica',
                    'Notebook',
                    'Utensilhos',
                    'Eletrônicos',
                    'Cozinha',
                    'Refrigeradores',
                    'Eletrodomésticos',
                ],
                header: [
                    'Masculinas',
                    'Femininas',
                    'Infantis',
                    'Esportivas',
                    'Casuais',
                    'Mixirica',
                    'Notebook',
                ]
            },

            // DADOS CARRINHO
            qtdItensCarrinho        : '9',
        };
    },
    methods: {
        toggleModalCep(){
            this.showCep = !this.showCep;
        },
        toggleMenuSide(){
            this.showMenuSide = !this.showMenuSide;
        },
        toggleCart(){
            setTimeout(() => {
                this.showCart = !this.showCart;
            }, 90);
        },
    },

    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.header {
    position: sticky;
    z-index: 1;
    background-color: rgb(231, 231, 231);
    display: flex;
    flex-direction: column;
    height: 135px;
    width: 100%;
    padding-top: 5px;
}
.container-top{
    display: flex;
    justify-content: space-between;
    padding-inline: 15px;
}

.botao-menu-side {
    width: 105px;
    height: 55px;
    display: flex;
    align-items: center;
    font-size: 35px;
    color: var(--cor-primaria);
    &:hover {
        cursor: pointer;
    }
}

.logo-filial{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: 20px;

    img {
        height: 55px;
    }
}

.rodape-header {
    height: 60px;
    background-color: var(--cor-primaria);
    grid-column: span 3;
    grid-row: 2;
    margin-top: auto;
    border-bottom: 3px solid var(--cor-primaria-light);
}


.container-buscar{
    // background-color: blue;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 10px;

    input[type=text] {
        height: 45px;
        width: 90vw;
        border-radius: 8px;
        padding-left: 10px;
        border: none;
        box-shadow: 0px 0px 5px -2.5px rgba(0,0,0,0.75);
        font-size: 20px;

        &:focus {
            outline: 0;
            border: 1px solid rgb(172, 172, 172);
        }
    }
}

.buscar-grupo{
    position: relative;
    height: fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        i {
            color: var(--cor-primaria);
            position: absolute;
            right: 10px;
        }
    }
}
.container-cep{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .grupo-cep {
        & > button {
            display: flex;
            align-items: center;
            border: 0;
            border-radius: 10px;
            height: 35px;
            width: 95vw;
            background-color: var(--cor-primaria);
            color: #fff;
            font-size: 15px;
            font-weight: 500;
            padding-inline: 18px;
            & > i {
                margin-right: 5px;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.bg-modal-cep {
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.47);
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    z-index: 3;
}

.modal-cep-header{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-60%);
    display: flex;
    z-index: 3;
    flex-direction: column;
    width: 400px;
    height: 330px;
    background-color: #fff;
    border-radius: 10px;
    
    & > p {
        margin-top: 20px;
        margin-inline: 30px;
        font-size: 25px;
    }
    
    & > .input-cep {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-inline: 5px;
        & > .grupo-input-cep {
            & > label {
                font-size: 20px;
                font-weight: 600;
            }
            & > input[type=text] {
                padding-left: 40px;
                height: 55px;
                width: 280px;
                font-size: 40px;
                outline: 0;
                color: #717171;
                border: 2px solid rgb(125, 125, 125);
                box-shadow: 0px 0px 5px -2.5px rgba(0,0,0,0.75);
                border-radius: 5px;
            }
        }
        & > button {
            background-color: var(--cor-primaria);
            color: #fff;
            margin-top: 20px;
            height: 50px;
            width: 150px;
            font-weight: 600;
            border-radius: 5px;
        }
    }
}


.adicionais-direita{
    height: 100%;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > button {
            border: 0;
    }

    i {
        font-size: 38px;
        color: var(--cor-primaria);
    }
    .grupo-login{
        margin-right: 25px;
    }
    .grupo-carrinho{
        position: relative;
        background-color: rgba(0, 0, 0, 0);

        & > .badge-carrinho {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: -5px;
            top: 0;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: rgb(255, 0, 0);

            & > p {
                margin: 0;
            }
        }
    }
}

.bg-menu-side{
    position: fixed;
    display: flex;
    padding-inline: 60px;
    flex-direction: row-reverse;
    top: 0;
    background-color: rgba(0, 0, 0, 0.47);
    height: 100vh;
    width: 100vw;
    z-index: 4;
    color: #fff;
    font-size: 45px;
}


.menu-side {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 70%;
    height: 100vh;
    background-color: rgb(231, 231, 231);
    & > ul {
        display: flex;
        align-items: start;
        flex-direction: column;
        & > li {
            text-align: left;
            font-size: 26px;
            padding-left: 15px;
            margin-bottom: 5px;
            width: 100%;
            background-color: #fff;
            border-bottom: 2px #f000 solid;
        }
    }
    & > .login-side{
        border: 0;
        height: 50px;
        background-color: var(--cor-primaria);
        color: #fff;
        border-bottom: 5px solid var(--cor-primaria-light);
        // margin-bottom: 5%;
    }
    & > .ir-carrinho {
        width: 100%;
        height: 35px;
        border: 0;
        background-color: var(--cor-primaria-light);
    }
}
</style>
