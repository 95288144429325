<template>
	<!--Luiz Trindade - Macau inter papagaio 2-->
    <div class="perfil">
        <!-- EXIBE O HEADER PARA DESKTOP -->
		<div class="d-none d-sm-none d-md-none d-lg-block">
			<HeaderDesktop></HeaderDesktop>
		</div>
	
		<!-- EXIBE O HEADER PARA TABLET -->
		<div class="d-none d-sm-none d-md-block d-lg-none">
			<HeaderTablet></HeaderTablet>
		</div>
	
		<!-- EXIBE O HEADER PARA MOBILE -->
		<div class="d-block d-sm-block d-md-none d-lg-none">
			<HeaderMobile></HeaderMobile>
		</div>
		<div class="container-center">
			<div v-if="isLoadingPerfil" class="card container-loading-inicial">
				<div class="spinner-border" style="width: 2.3rem; height: 2.3rem; margin-right: 11px;" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
			<div v-else class="card card-perfil">
				<div class="col-12 header-card-perfil">
					<div class="foto-perfil-usuario">
						<input
							type="file"
							id="ipfotoperfil"
							accept="image/png, image/jpeg"
							@change="mtdselecionarimagem($event)"
							style="display: none;"
							ref="click_fotoperfil"
							>
						<div class="imagem-selecionada" @click="$refs.click_fotoperfil.click()">
							<img v-if="!isLoadingFotoPerfil" class="card-img-top imagem-usuario" :src="'https://'+clienteSac.endServidor+vImgCliente.patharquivo" alt="Foto de perfil do usuário">
							<div v-if="!isLoadingFotoPerfil" class="floater-hover-camera">
								<i class="fa fa-camera" aria-hidden="true"></i>
							</div>
							<div v-else class="card container-loading-inicial">
								<div class="spinner-border" style="width: 2.3rem; height: 2.3rem;" role="status">
									<span class="sr-only">Loading...</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<h5 class="card-title">Perfil</h5>
					<div class="dadosconta">
						<h6 class="card-subtitle mb-2 text-muted">Dados da conta</h6>
						<form class="form-login">
							<div class="row" style="margin-bottom: 40px;">
								<div class="col-6">
									<div class="grupo-form">
										<label for="edEmailperfil">E-mail</label>
										<div class="wrap-input-8">
											<input id="edEmailperfil" @input="alterouCampos" v-model="vEmailPerfil" class="input" type="email" :disabled="alteraEmail" placeholder="Digite seu email">
											<span class="focus-border"><i></i></span>
											<i class="fa fa-pencil" aria-hidden="true" @click="toggleEmail" ></i>
										</div>
									</div>
								</div>
								<div class="col-6">
									<div class="grupo-form">
										<label for="edSenhaperfil">{{ labelSenha }}</label>
										<div class="wrap-input-8">
											<input maxlength="30" id="edSenhaperfil" v-model="vSenhaPerfil" class="input" type="password" :disabled="alteraSenha" :placeholder="'Digite sua ' + labelSenha">
											<span class="focus-border"><i></i></span>
											<i class="fa fa-pencil" aria-hidden="true" @click="toggleSenha"></i>
										</div>
									</div>
									<transition name="fade">
										<div class="grupo-form" v-if="mostrarNovaSenha">
											<label for="novaSenha" style="width: 100%;">Nova senha
												<span v-if="novaSenha.length === 20" style="width: fit-content; color: rgb(161, 0, 0); font-weight: 400; float: right;"> {{ novaSenha.length }}/20 caracteres</span>
												<span v-else style="width: fit-content; color: #8b8b8b; font-weight: 400; float: right;"> {{ novaSenha.length }}/20 caracteres</span>
											</label>
											<div class="wrap-input-8">
												<input maxlength="20" id="novaSenha" v-model="novaSenha" class="input" type="password" :disabled="alteraSenha" placeholder="Digite sua nova senha">
												<span class="focus-border"><i></i></span>
											</div>
											<small v-if="erroSenha1">A senha deve conter 8 ou mais caracteres!</small>
											<small v-else> </small>
											<div class="grupo-btns-form-password">
												<button id="btn-entrar" :disabled="!vSenhaPerfil || novaSenha.length < 8" @click.prevent="atualizarSenha">Salvar senha <i class="bi bi-arrow-right"></i></button>
											</div>
										</div>
									</transition>
								</div>
							</div>
						</form>
					</div>
					<div class="dadospessoais">
						<h6 class="card-subtitle mb-2 mt-3 text-muted">Dados pessoais</h6>
						<form class="form-login">
							<div class="row" style="margin-bottom: 15px;">
								<div class="col-6">
									<div class="grupo-form">
										<label for="edNomecompletoperfil">Nome completo</label>
										<div class="wrap-input-8">
											<input id="edNomecompletoperfil" @input="alterouCampos" v-model="vNomeCompletoPerfil" class="input" type="text" :disabled="alteranNome" placeholder="Digite seu nome completo">
											<span class="focus-border"><i></i></span>
											<i class="fa fa-pencil" aria-hidden="true" @click="toggleNome"></i>
										</div>
									</div>
								</div>
								<div class="col-6">
									<div class="grupo-form">
										<label for="edCpfcnpjperfil">CPF/CNPJ</label>
										<div class="wrap-input-8">
											<input id="edCpfcnpjperfil"
												v-model="vCpfCnpjPerfil" 
												class="input" 
												type="text" 
												:disabled="alteraCpfCnpj" 
												placeholder="Digite seu CPF/CNPJ"
												@mouseover="showMessage"
												@mouseleave="hideMessage"
											>
											<span class="focus-border"><i></i></span>
											<!-- <i class="fa fa-pencil" aria-hidden="true" @click="toggleCpfCnpj"></i> -->
											<transition name="fade">
												<div v-if="messageVisible" class="tooltip-message">
													Para alterar o CPF/CNPJ, entre em contato conosco.
												</div>
											</transition>
											
										</div>
									</div>
								</div>
							</div>
							<div class="row" style="margin-bottom: 60px;">
								<div class="col-4">
									<div class="grupo-form">
										<label for="edDtnascimentoperfil">Data de nascimento</label>
										<div class="wrap-input-8">
											<input id="edDtnascimentoperfil" @input="alterouCampos" v-model="vDtNascimentoPerfil" class="input" type="date" :disabled="alteraDtNascimento" placeholder="Digite sua data de nascimento">
											<span class="focus-border"><i></i></span>
											<i class="fa fa-pencil" aria-hidden="true" @click="toggleDtNascimento"></i>
										</div>
									</div>
								</div>
								<div class="col-4">
									<div class="grupo-form">
										<label for="edTelefoneperfil">Telefone</label>
										<div class="wrap-input-8">
											<input id="edTelefoneperfil" v-mask="['(##) ####-####', '(##) #####-####']" @input="alterouCampos" v-model="vTelefonePerfil" class="input" type="tel" :disabled="alteraTelefone" placeholder="Digite seu telefone">
											<span class="focus-border"><i></i></span>
											<i class="fa fa-pencil" aria-hidden="true" @click="toggleTelefone"></i>
										</div>
									</div>
								</div>
								<div class="col-4">
									<div class="grupo-form">
										<label for="edCelularperfil">Celular</label>
										<div class="wrap-input-8">
											<input id="edCelularperfil" v-mask="['(##) ####-####', '(##) #####-####']"  @input="alterouCampos" v-model="vCelularPerfil" class="input" type="tel" :disabled="alteraCelular" placeholder="Digite seu celular">
											<span class="focus-border"><i></i></span>
											<i class="fa fa-pencil" aria-hidden="true" @click="toggleCelular"></i>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="grupo-btns-form" v-if="showButton">
						<button id="btn-entrar" @click.prevent="atualizarUsuario">Salvar alterações <i class="bi bi-arrow-right"></i></button>
					</div>
				</div>
			</div>
		</div>
		<modal name="modal-cropper-imagem" height="auto">
            <div class="container-cropper card">
                <div class="card-header titulo-cropper">
                    <h4>Recorte da imagem</h4>
                    <p><i class="fa fa-object-group" aria-hidden="true"></i> {{ tituloCropper }}</p>
                </div>
                <div class="card-body body-cropper">
                    <cropper
                        ref="cropper"
                        :src="imgselecionada"
						stencil-component="circle-stencil"
                        :stencil-props="{
                            aspectRatio: aspectRatioCropper,
                            scalable: scalableCropper,
                        }"
                    />
                </div>
                <div class="card-footer" style="text-align: left;">
                    <small>Proporção da imagem:</small>
                    <div class="aspectRatios">
                        <button class="btn-aspectratio"><div class="p1-1"></div><small>1:1</small></button>
                    </div>
                    <div class="btns-cropper">
                        <button @click.prevent="cancelarSelecaoImagem" class="btn btn-secondary btn-cancelar">Cancelar</button>
                        <button @click.prevent="confirmarSelecaoImagem" class="btn btn-primary btn-salvar-alteracao">Salvar Alterações</button>
                    </div>    
                </div>
            </div>
        </modal>
		<v-dialog />
    </div>
</template>
<script>
import utils from '@/utilitarios/utils.js';
import HeaderDesktop from '@/components/Header/HeaderDesktop.vue';
import HeaderTablet from '@/components/Header/HeaderTablet.vue';
import HeaderMobile from '@/components/Header/HeaderMobile.vue';
import SidePerfilDesktop from '@/components/SidePerfil/SidePerfilDesktop.vue';


export default {
    name: 'DetalhesPerfil',
    components: {
        HeaderDesktop,
        HeaderTablet,
        HeaderMobile,
        SidePerfilDesktop,
    },
    data() {
        return {
			isLoadingPerfil			: true,	
			isLoadingFotoPerfil		: true,

            imgLogoFilial			: 'https://i.imgur.com/8lJ6gDQ.png',
            temaFilial				: '',
            vEmailPerfil			: '',
            vSenhaPerfil			: '',
            vNomeCompletoPerfil		: '',
            vDtNascimentoPerfil		: '',
            vCpfCnpjPerfil			: '',
            vTelefonePerfil			: '',
            vCelularPerfil			: '',
			vImgCliente				: {
				patharquivo		: '/imagens/semimagem.png',
				diretorioimagem	: 'semimagem.png',
				imagemcliente	: ''
			},

			alteraEmail				: true,
			alteraSenha				: true, 
			alteranNome				: true, 
			alteraCpfCnpj			: true,
			alteraDtNascimento		: true, 
			alteraTelefone			: true,
			alteraCelular			: true,

			messageVisible			: false,

			labelSenha				: 'Senha',
			novaSenha				: '',    
			mostrarNovaSenha		: false,

			showButton				: false,

			dadosAntigos 			: {},

			// cropper
			imgselecionada              : null,
			extimgselecionada           : null,
			imgParaUpload               : null,
			tituloCropper               : '1:1',
			aspectRatioCropper          : 1,
			scalableCropper             : true,
			circleStencil			   	: true,
		}
    },
    mounted() {
		this.carregarDadosPerfil();
    },
	computed: {
		clienteSac(){
            return this.$store.state.clienteSac;
        },
        filial(){
            return this.$store.state.filial;
        },
        usuarioLogado() {
            return this.$store.state.usuarioLogado;
        },
        configPaths() {
            return this.$store.state.configPaths;
        },
        infosGeraisProdutos(){
            return this.$store.state.infosGeraisProdutos;
        },
        qtdItensCarrinho(){
            return this.$store.state.carrinhoCompras.qtdItens;
        },
		erroSenha1(){
            if (this.novaSenha.length > 0){
                return this.novaSenha.length < 8
            } else {
                return false
            }
        },
    },
	watch: {
        vNomeCompletoPerfil: function(){
            this.vNomeCompletoPerfil = this.vNomeCompletoPerfil.toUpperCase();
        },
    },
    methods: {
		mascararCnpjCpf(cnpjcpf) {
			// Obg sr chatgpt
			if (cnpjcpf.length === 11) {
				// Formata o CPF com pontuação
				let formatado = cnpjcpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
				// Substitui todos os dígitos, exceto os três últimos, por '*'
				let mascarado = formatado.slice(0, -6).replace(/\d/g, '*') + formatado.slice(-6);
				return mascarado;
			} else if (cnpjcpf.length === 14) {
				// Formata o CNPJ com pontuação
				let formatado = cnpjcpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
				// Substitui todos os dígitos, exceto os três últimos, por '*'
				let mascarado = formatado.slice(0, -7).replace(/\d/g, '*') + formatado.slice(-7);
				return mascarado;
			} else {
				return "";
			}
		},
		alterouCampos() {
			let vself = this;
			if(vself.dadosAntigos.email !== vself.vEmailPerfil ||  vself.dadosAntigos.nome.toUpperCase() !== vself.vNomeCompletoPerfil.toUpperCase() || vself.dadosAntigos.dtnascfund 
				!== vself.vDtNascimentoPerfil || vself.dadosAntigos.fone1 !== vself.vTelefonePerfil || vself.dadosAntigos.fone2 !== vself.vCelularPerfil ){
				vself.showButton   = true;
			} else{
				vself.showButton   = false;
			}
		},
		showMessage() {
            this.messageVisible = true;
        },
        hideMessage() {
            this.messageVisible = false;
        },
		toggleEmail() {
            this.alteraEmail = !this.alteraEmail;
            this.$nextTick(() => {
                if (!this.alteraEmail) {
                    $('#edEmailperfil').focus();
                }
            });
        },
		toggleSenha() {
        	this.alteraSenha = !this.alteraSenha;
			this.mostrarNovaSenha = !this.mostrarNovaSenha;
			if (this.mostrarNovaSenha) {
				this.labelSenha = 'Antiga Senha';
				this.vSenhaPerfil = ''; // Limpa o campo da senha antiga
			} else {
				this.vSenhaPerfil = 'senhateste'
				this.labelSenha = 'Senha';
				this.novaSenha = ''; // Limpa o campo da nova senha
			}
			this.$nextTick(() => {
                if (!this.alteraSenha) {
                    $('#edSenhaperfil').focus();
                }
            });
		},
		toggleNome() {
			this.alteranNome = !this.alteranNome;
			this.$nextTick(() => {
                if (!this.alteranNome) {
                    $('#edNomecompletoperfil').focus();
                }
            });
		},
		toggleDtNascimento() {
			this.alteraDtNascimento = !this.alteraDtNascimento;
			this.$nextTick(() => {
                if (!this.alteraDtNascimento) {
                    $('#edDtnascimentoperfil').focus();
                }
            });
		},
		toggleTelefone() {
			this.alteraTelefone = !this.alteraTelefone;
			this.$nextTick(() => {
                if (!this.alteraTelefone) {
                    $('#edTelefoneperfil').focus();
                }
            });
		},
		toggleCelular() {
			this.alteraCelular = !this.alteraCelular;
			this.$nextTick(() => {
                if (!this.alteraCelular) {
                    $('#edCelularperfil').focus();
                }
            });
		},
		mtdselecionarimagem(event){
			this.extimgselecionada = utils.extensaoArquivo(event.target.files[0]).toLowerCase();
			this.imgselecionada = URL.createObjectURL(event.target.files[0]);

			this.$modal.show('modal-cropper-imagem')
			event.target.value = null;
		},
		cancelarSelecaoImagem(){
			this.imgselecionada = null;
			this.extimgselecionada = null;
			this.$modal.hide('modal-cropper-imagem')
		},
		async salvarImagemCortada() {
			let stringAleatoria = utils.GerarStringAleatoria();
			this.imgParaUpload = null;
			let vself = this;
			vself.isLoadingFotoPerfil = true;
			vself.$modal.hide('modal-cropper-imagem')
			
			// PEGA A IMAGEM RECORTADA E CONVERTE PARA BLOB -> ARQUIVO, DEPOIS ENVIA ELE PARA O SERVIDOR
			const { canvas, } = this.$refs.cropper.getResult();
			const createBlob = () => {
				return new Promise((resolve) => {
				canvas.toBlob((blob) => {
					resolve(blob);
				}, `image/${this.extimgselecionada}`);
				});
			};
			let localsalvar = vself.configPaths.pathImgClientes + vself.clienteSac.id+'/'+vself.vImgCliente.diretorioimagem;
			const blob = await createBlob();
			this.imgParaUpload = new File([blob], `${stringAleatoria}.${this.extimgselecionada}`, { type: `image/${this.extimgselecionada}` });
			let parametros      = [
				{parametro: 'arquivo',          valor  : vself.imgParaUpload},
				{parametro: 'localsalvar',      valor  : localsalvar},
				{parametro: 'nomearquivo',      valor  : vself.imgParaUpload.name}
			];
			let retorno     = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/uploadfile.php');
			retorno.then(function (response) {
				if ($.isNumeric(response.data.inf)) {
					vself.atzPathImagemCliente({localsalvar: localsalvar, nomearquivo: vself.imgParaUpload.name});
				}else{
					vself.$toastr.e('Ocorreu um erro ao carregar a imagem: '+response.data.inf, 'Erro!');
				}                   
			}).catch(function (response) {
				vself.$toastr.e('Ocorreu um erro ao carregar a imagem: '+response.message, 'Erro!');
			});
		},
		atzPathImagemCliente(imagem){
			let vself = this;
			let parametros = [
				{parametro: 'op',           	valor: 'atzpathimagemcliente'},
				{parametro: 'idclientesac',     valor: this.clienteSac.id},
				{parametro: 'idcliente',    	valor: this.usuarioLogado.id},
				{parametro: 'pathimagens',		valor: this.configPaths.pathImgClientes},
				{parametro: 'imagemcliente',	valor: imagem.localsalvar+'/'+imagem.nomearquivo},
			];
			let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtperfilusuario.php');
			retorno.then(function (response) {
				if(parseInt(response.data) > 0){
					vself.$toastr.s('Imagem atualizada com sucesso!', 'Pronto!');
					vself.carregarDadosPerfil();
				} else{
					vself.$toastr.w('Erro ao atualizar imagem. Tente novamente.', 'Ops!');
					vself.$modal.hide('modal-cropper-imagem');
					vself.carregarDadosPerfil();
				}
			}).catch(function (response) {
				vself.$toastr.e('Erro ao atualizar imagem. Tente novamente.', 'Ops!');
				vself.vImgCliente = '/imagens/semimagem.png';
				console.log(response.message);
			});
		},
		confirmarSelecaoImagem(){
			let vself = this;

			vself.$modal.show('dialog', {
				title: 'Confirmação',
				text: 'Deseja confirmar a alteração da imagem?',
				buttons: [
					{
					title: 'Cancelar',
					handler: () => {
						vself.$modal.hide('dialog')
						vself.cancelarSelecaoImagem();
					}
				},
				{
					title: 'Confirmar',
					class: 'btn-success',
					handler: async () => {
						vself.$modal.hide('dialog')
						vself.salvarImagemCortada();
						// window.location.reload();
					}
					},
				]
			})
		},
        async carregarDadosPerfil() {
            let vself = this;
			vself.isLoadingPerfil = true;
			vself.isLoadingFotoPerfil = true;
            let parametros = [
                {parametro: 'op',           	valor: 'carregadadosperfil'},
                {parametro: 'idcliente',    	valor: this.$store.state.usuarioLogado.id},
                {parametro: 'idclientesac',     valor: this.$store.state.clienteSac.id},
				{parametro: 'pathimagens',		valor: this.$store.state.configPaths.pathImgClientes},
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtperfilusuario.php');
            retorno.then(function (response) {
				if(parseInt(response.data.inf) > 0){
					vself.vEmailPerfil 			= response.data.dadosCliente.email;
                    vself.vNomeCompletoPerfil 	= response.data.dadosCliente.nome;
                    vself.vCpfCnpjPerfil 		= vself.mascararCnpjCpf(response.data.dadosCliente.cnpjcpf);
                    vself.vDtNascimentoPerfil 	= response.data.dadosCliente.dtnascfund;
                    vself.vCelularPerfil 		= response.data.dadosCliente.fone1;
                    vself.vTelefonePerfil 		= response.data.dadosCliente.fone2;
                    vself.vSenhaPerfil 			= 'senhateste';
					vself.vImgCliente 			= response.data.dadosCliente.imagens[0];
					vself.dadosAntigos 			= response.data.dadosCliente
					// console.log(vself.vCpfCnpjPerfil)
				}
				
				vself.isLoadingPerfil = false;
				setTimeout(() => { vself.isLoadingFotoPerfil = false; }, 1500);
            }).catch(function (response) {
                console.log(response.message);
				vself.isLoadingPerfil = false;
				setTimeout(() => { vself.isLoadingFotoPerfil = false; }, 1500);
            });
        },
		async atualizarUsuario() {
            let vself = this;

			let registroCrud = {
                id			: vself.$store.state.usuarioLogado.id,
                nome		: vself.vNomeCompletoPerfil,
                email		: vself.vEmailPerfil,
                dtnascfund  : vself.vDtNascimentoPerfil,
                fone1	  	: vself.vCelularPerfil,
                fone2  	    : vself.vTelefonePerfil,
            }
            let parametros = [
				{parametro: 'op', 				valor: 'rotinacrud'},
                {parametro: 'opcrud', 			valor: 2},
                {parametro: 'idclientesac', 	valor: vself.$store.state.clienteSac.id},
                {parametro: 'registro', 		valor: JSON.stringify(registroCrud)},
            ];

			vself.$modal.show('dialog', {
				title: 'Confirmação',
				text: 'Deseja salvar as alterações?',
				buttons: [
					{
					title: 'Cancelar',
					handler: () => {
						vself.$modal.hide('dialog')
						vself.carregarDadosPerfil();
					}
				},
				{
					title: 'Confirmar',
					class: 'btn-success',
					handler: async () => {
						vself.$modal.hide('dialog')
						try {
							let response = await utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtcadastrousuario.php');
							if (!isNaN(response.data.idcliente)) {
								vself.$toastr.s('Perfil atualizado com sucesso!', 'Pronto!');
							} else {
								vself.$toastr.w('Erro ao atualizar perfil. Tente novamente.', 'Ops!');
							}
						} catch (error) {
							console.error(error.message);
							vself.$toastr.e('Erro ao atualizar perfil. Tente novamente.', 'Ops!');
						}
						window.location.reload();
					}
					},
				]
			})
        },
		async atualizarSenha() {
			let vself = this;

			let registroSenha = {
                id			: vself.$store.state.usuarioLogado.id,
                senhantiga	: vself.vSenhaPerfil,
                senhanova	: vself.novaSenha,
				
            }
            let parametros = [
				{parametro: 'op', 				valor: 'alteraSenha'},
                {parametro: 'idclientesac', 	valor: vself.$store.state.clienteSac.id},
                {parametro: 'registro', 		valor: JSON.stringify(registroSenha)},
            ];

			vself.$modal.show('dialog', {
				title: 'Confirmação',
				text: 'Deseja salvar as alterações?',
				buttons: [
					{
					title: 'Cancelar',
					handler: () => {
						vself.$modal.hide('dialog')
					}
				},
				{
					title: 'Confirmar',
					class: 'btn-success',
					handler: async () => {
						try {
							vself.$modal.hide('dialog')
							let response = await utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtperfilusuario.php');
							if (!isNaN(response.data)) {
									vself.vSenhaPerfil = response.data.senhaecommerce;
									vself.$toastr.s('Senha atualizada com sucesso!', 'Pronto!');
									vself.toggleSenha();
								} else {
									vself.$toastr.w('Erro ao atualizar senha. Tente novamente.', 'Ops!');
								}
							} catch (error) {
								console.error(error.message);
								vself.$toastr.e('Erro ao atualizar senha. Tente novamente.', 'Ops!');
						}
						
					}
					},
				]
			})

		}
    }
}
</script>
<style scoped lang="scss">
.header-card-perfil{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
}

.foto-perfil-usuario:hover {
	cursor: pointer;
}


.imagem-usuario{
	width: 110px;
}

.tooltip-message {
    position: absolute;
    background-color: var(--cor-primaria);
	color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    z-index: 1000;
}
.fade-enter-active, .fade-leave-active {
	transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
.container-center{
    background-color: rgba(0, 0, 0, 0.066);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    padding: 15px;
}

.container-loading-inicial{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60rem;
	height: 80vh;
}

.imagem-selecionada{
	display: flex;
    justify-content: center;
    align-items: center;
	position: relative;
	width: 110px;
	height: 110px;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	box-shadow: 0 0 0 8px var(--cor-primaria); 
	outline: 2px solid rgb(255, 255, 255);

	& > img {
		width: 110px;
		height: 110px;
	}
	.floater-hover-camera {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0;
		transition: opacity 0.3s;
		&:hover {
			opacity: 1;
		}
		& > i {
			color: #fff;
			font-size: 30px;
		}
	}
}

.card-perfil{
    width: 60rem;
	height: fit-content;
	
}
.form-login{
	width: 100%;
	text-align: left;
	& > .grupo-form{
		display: flex;
		flex-direction: column;
		width: 100%;
		// background-color: red;
		& > label {
			margin: 0;
			font-weight: 600;
			color: var(--cor-primaria);
		}
	}
	& > .adicionais-senha{
		width: 100%;
		height: 25px;
		padding-top: 4px;
		margin-bottom: 30px;
		// background-color: red;
		display: flex;
		justify-content: space-between;
		align-items: center;
		& > .form-check {
			width: fit-content;
			display: flex;
			justify-content: center;
			align-items: center;
			& > label {
				margin-left: 5px;
				margin-top: 8px;
				font-size: 11px;
				font-weight: 500;
				color: black;
			}
		}
		& > .form-esqueci{
			padding-top: 4px;
			a {
				font-size: 11px;
				color: rgba(0, 0, 0, 0.731);
				&:hover {
					color: var(--cor-primaria);
				}
			} 
		}
	}
}
.grupo-btns-form{
	& > button {
		font-size: 13.5px;
		width: 200px;
		height: 33px;
		border-radius: 7px;
		border: 1px solid #686868;
		background-color: var(--cor-primaria);
		color: #fff;
		font-weight: 500;
	}
}
.grupo-btns-form-password {
	& > button {
		font-size: 13.5px;
		width: 200px;
		height: 33px;
		border-radius: 7px;
		border: 1px solid #686868;
		background-color: var(--cor-primaria);
		color: #fff;
		font-weight: 500;
		& :disabled{
			background-color: var(--cor-primaria-disabled);
		}
	}
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}
#btn-entrar:disabled {
	background-color: var(--cor-primaria-disabled);
	border: 0;
	color: #fff;
	&:hover{
		cursor: not-allowed;
	}

}
.cadastro-grupo{
	&>span{
		font-size: 14px;
		font-weight: 400;
	}
	&>a{
		color: var(--cor-primaria);
		font-size: 14px;
		font-weight: 500;
	}
}
.right-side{
	display: flex;
	justify-content: center;
	background-color: var(--cor-primaria);
	width: 50%;
	height: 100%;
	overflow: hidden;
}
// input css 
.wrap-input-8 .input {
    display: flex;
    justify-content: center;
	width: 100%;
	height: 40px;
	font-size: 14px;
	box-sizing: border-box;
	letter-spacing: 1px;
	background-color: #f5f6fd;
	border: 0; 
	border-bottom: 2px solid var(--cor-primaria); 
	padding: 5px 10px 5px; 
	transition: 0.4s;
}
.wrap-input-8 .input:focus {
	outline: none;
}
.wrap-input-8 {
	width: 100%;
	margin-top: 5px;
	position: relative;
	& > i{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 8px;
		&:hover{
			cursor: pointer;
		}
	}
}
.wrap-input-8 .input ~ .focus-border:before,
.wrap-input-8 .input ~ .focus-border:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 2px;
	background-color: var(--cor-primaria);
	transition: 0.3s;
}
.wrap-input-8 .input ~ .focus-border:after {
	top: auto;
	bottom: 0;
	left: auto;
	right: 0;
}
.wrap-input-8 .input ~ .focus-border i:before,
.wrap-input-8 .input ~ .focus-border i:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 2px;
	height: 0;
	background-color: var(--cor-primaria);
	transition: 0.4s;
}
.wrap-input-8 .input ~ .focus-border i:after {
	left: auto;
	right: 0;
	top: auto;
	bottom: 0;
}
.wrap-input-8 .input:focus ~ .focus-border:before,
.wrap-input-8 .input:focus ~ .focus-border:after {
	width: 100%;
	transition: 0.3s;
}
.wrap-input-8 .input:focus ~ .focus-border i:before,
.wrap-input-8 .input:focus ~ .focus-border i:after {
	height: 100%;
	transition: 0.4s;
}

.btn-confirmar-modal{
	border: 0;
	background-color: black !important;
	color: #fff;
}

// modal cropper
.container-cropper{
    & > .titulo-cropper{
        display: flex;
        justify-content: space-between;
		background-color: var(--cor-primaria);
		color: #ffffffd0;
    }
	
    & > .body-cropper{
        height: 40vh;
    }
}
.btns-cropper{
    display: flex;
    justify-content: space-between;
    margin-block: 20px;

	& > .btn-cancelar {
		background-color: #aa0111;
		color: #fff;
		border: 0;
	}

	& > .btn-salvar-alteracao {
		background-color: var(--cor-primaria);
		color: #fff;
		border: 0;
	}
}

.aspectRatios {
    display: flex;
    margin-bottom: 10px;
}

.btn-aspectratio{
    height: 43px;
    width: 60px;
    margin: 5px;
    border: 1px solid #0d214e7b;
    border-radius: 5px;
    background-color: #fff;
    color: #0d214ef4;
    font-weight: 600;
    transition: background-color 0.4s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px 0px #0000002f;
    &:hover{
        background-color: rgba(0, 33, 87, 0.676);
        color: #Fff;
        cursor: pointer;
    }
    & > div {
        border: 3px solid #0d214e7b;
    }
    &:hover > div {
        border: 3px solid #ffffffc4;
    }
    & > small {
        height: 10px;
        font-size: 10px;
    }

    & > .p1-1 {
        height: 20px;
        width: 20px;
    }
}

</style>