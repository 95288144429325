<template>
	<div class="container-fluid bg-login">
			<div class="row container-login">
				<div class="left-side">
					<div v-show="isLoadingMounted" class="spinner-border" style="width: 2.2rem; height: 2.2rem;" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<transition name="fade">
						<div v-show="!isLoadingMounted">
							<div class="logo-login">
								<img @click="$router.push({ path: '/' })" :src="'https://'+clienteSac.endServidor+'/controller/ecommerce/'+configPathsLogos.logoPrincipal" alt="logo loja">
							</div>
							<div class="infos-cadastro">
								<h1>Bem-vindo!</h1>
								<span>Faça login para continuar.</span>
							</div>
							<form class="form-login">
								<div class="grupo-form">
									<label for="edEmail">E-mail</label>
									<div class="wrap-input-8">
										<input id="edEmail" v-model="vemail" class="input" type="text" placeholder="Digite seu email">
										<span class="focus-border"><i></i></span>
									</div>
									<div class="email-error" style="height: 20px; width: 100%; display: flex; flex-direction: row-reverse;">
										<small v-show="vemailerror" style="color: red; margin-top: 5px; font-size: 12px; font-weight: 600;">E-mail inválido!</small>
									</div>
								</div>
								<div class="grupo-form">
									<label for="edSenha">Senha</label>
									<div class="wrap-input-8">
										<input maxlength="30" id="edSenha" v-model="vsenha" class="input" type="password" placeholder="Digite sua senha">
										<span class="focus-border"><i></i></span>
										<i v-if="mostraSenha" class="fa fa-eye" aria-hidden="true" @click="toggleSenha"></i>
										<i v-else class="fa fa-eye-slash" aria-hidden="true" @click="toggleSenha"></i>
									</div>
								</div>
								<div class="adicionais-senha">
									<div class="form-check">
										<input id="edLembrarSenha" type="checkbox" class="form-check-input" value="">
										<label for="edLembrarSenha" class="form-check-label"> Lembrar minha senha</label>
									</div>
									<div class="form-esqueci">
										<small><router-link :to="{ name: 'RecuperarSenha'}">Esqueci minha senha</router-link></small>
									</div>
								</div>
								<div class="aviso-login-senha">
									<span v-show="avisoLoginSenha" >{{ avisoLoginSenha }}</span>
								</div>
								<div class="grupo-btns-form">
									<button id="btn-entrar" @click.prevent="logarUsuario" :disabled="isLoading">
										<div v-if="isLoading" class="spinner-border" style="width: 1.2rem; height: 1.2rem;" role="status">
											<span class="sr-only">Loading...</span>
										</div>
										<template v-else>
											Entrar 
											<i class="bi bi-arrow-right"></i>
										</template>
									</button>
								</div>
								<div class="cadastro-grupo" style="margin-top: 10px;">
									<span>Não possui uma conta? </span><router-link to="/cadastro">Cadastre-se</router-link>
								</div>
							</form>
						</div>
					</transition>
				</div>
				<div class="right-side">
					<!-- <img src="../assets/images/login/gente-fleiz.jpg" alt="" style="height: 100%;"> -->
					<img src="../assets/images/login/gente-fleiz.avif" alt="" style="height: 115%; width: 669px;">
				</div>
			</div>
	</div>
</template>

<script>
import utils from '@/utilitarios/utils.js';
// @ is an alias to /src

export default {
	name: 'LoginUsuario',
	components: {
		
	},
	data: function() {
		return {
			imgLogoFilial           : '',
			// CONFIGURAÇÕES DO CLIENTE
			temaFilial              : '', // AQUI TRÁS O TEMA DE CORES ESCOLHIDO NA CONFIGURAÇÃO DO WEBSITE NO SISTEMA
			corPrimaria			 	: '',	
			// DADOS CARRINHO
			mostraSenha             : false,

			// DADOS FORMULARIO
			vemail 					: '',
			vemailerror 			: false,
			vsenha 					: '',
			vsenhaerror 			: false,

			avisoLoginSenha 		: '',
			isLoading 				: false,
			isLoadingMounted		: true,
		};
	},
	methods: {
		toggleSenha (){
			this.mostraSenha = !this.mostraSenha;
			if (this.mostraSenha) {
				$('#edSenha').attr('type',"text")
			} else {
				$('#edSenha').attr('type',"password")
			}
		},
		async logarUsuario(){
			if(this.vemail === '' || this.vemailerror){
				this.vemailerror = true;
				$('#edEmail').trigger("focus");
				return;
			}
			if(this.vsenha < 5){
				this.vsenhaerror = true;
				$('#edSenha').trigger("focus");
				return;
			}

			let vself = this;
			this.isLoading = true;
            let parametros = [
                {parametro: 'op',           	valor: 'loginusuario'},
                {parametro: 'loginusu',    		valor: this.vemail},
                {parametro: 'senhausu',     	valor: this.vsenha},
                {parametro: 'idclientesac',     valor: this.$store.state.clienteSac.id}
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/auxiliares.php');
            retorno.then(function (response) {
				if(parseInt(response.data.qtdusu) > 0){
					const usuarioAtivo = parseInt(response.data.ativo);
					if (usuarioAtivo == 1) {
						vself.$toastr.i("impossível efetuar o login, entre em contato conosco!", "Informação");
						this.isLoading = false;
						return;
					} else {
						let dadoscliente = {
							id 				: response.data.idcliente,
							nome 			: response.data.nome,
							cep 			: response.data.cep,
							cepecommerce 	: response.data.cepecommerce,
						}
						vself.$store.commit('MUDAR_USUARIO_LOGADO', dadoscliente);
	
						window.localStorage.setItem('usuarioLogado', JSON.stringify(dadoscliente));
	
						// LIMPA VARIÁVEIS E REDIRECIONA
						vself.vemail = '';
						vself.vsenha = '';
						vself.$router.push({ path: '/' });
						vself.isLoading = false;
					}		
				} else {
					vself.avisoLoginSenha = response.data.msg;
					vself.isLoading = false;
					setTimeout(() => {
						vself.avisoLoginSenha = '';
					}, 5000);
				}
            }).catch(function (response) {
                console.log(response.message);
				vself.isLoading = false;
            });
		},
	},
	beforeMount: function() {
		if (this.$store.state.usuarioLogado.id != null){
			this.$router.push({ path: '/' })
		}

	},
	mounted: function() {
		let elemento = document.querySelector('.tema-'+this.$store.state.filial.tema);
		this.corPrimaria = getComputedStyle(elemento).getPropertyValue('--cor-primaria')
		setTimeout(() => {
            this.isLoadingMounted = false;
        }, 500);
	},
	computed: {
		clienteSac(){
            return this.$store.state.clienteSac;
        },
        filial(){
            return this.$store.state.filial;
        },
        usuarioLogado() {
            return this.$store.state.usuarioLogado;
        },
        configPaths() {
            return this.$store.state.configPaths;
        },
        configPathsLogos() {
            return this.$store.state.configPathsLogos;
        },
        infosGeraisProdutos(){
            return this.$store.state.infosGeraisProdutos;
        },
	},
	watch: {
		vemail: function(){
			const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
			if(!emailPattern.test(this.vemail) && this.vemail.length > 3){
				this.vemailerror = true;
			} else {
				this.vemailerror = false;
			}
		},
		vsenha: function(){
			if(this.vsenhaerror){
				this.vsenhaerror = false;
			}
		}
	}
}
</script>
<style>
</style>
<style scoped lang="scss">

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
    opacity: 0;
}

.bg-login{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: rgb(231, 231, 231);
}
.container-login{
	height: 90vh;
	width: 900px;
	box-shadow: -5px 6px 10px -3px rgba(0,0,0,0.75);
}
.left-side{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	background-color: #fff;
	width: 50%;
	height: 100%;
	& > div {
		width: 100%;
		& > form {
			width: 100%;
			padding-inline: 10%;
		}
	}
}
.logo-login{
	width: 100%;
	height: fit-content;
	& > img {
		&:hover{
			cursor: pointer;
		}
		width: 200px;
	}
}

.infos-cadastro{
	margin-top: 14px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	& > h1 {
		margin: 0;
		font-size: 22px;
	}
	& > span {
		font-size: 15px;
		color: rgba(0, 0, 0, 0.683);
	}
}

.aviso-login-senha{
	width: 100%;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
	// background-color: red;
	& > span {
		padding-inline: 13px;
		border-radius: 5px;
		border: 1px solid rgba(0, 0, 0, 0.452);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		font-weight: 500;
		background-color: rgba(255, 0, 0, 0.151);
		height: 30px;
	}
}

.form-login{
	width: 70%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: left;
	& > .grupo-form{
		display: flex;
		flex-direction: column;
		width: 100%;
		// background-color: red;
		& > label {
			margin: 0;
			font-weight: 600;
			color: var(--cor-primaria);
		}
	}
	& > .adicionais-senha{
		width: 100%;
		height: 25px;
		padding-top: 4px;
		margin-bottom: 15px;
		// background-color: red;
		display: flex;
		justify-content: space-between;
		align-items: center;
		& > .form-check {
			width: fit-content;
			display: flex;
			justify-content: center;
			align-items: center;
			& > label {
				margin-left: 5px;
				margin-top: 8px;
				font-size: 11px;
				font-weight: 500;
				color: black;
			}
		}
		& > .form-esqueci{
			padding-top: 4px;
			a {
				font-size: 11px;
				color: rgba(0, 0, 0, 0.731);
				&:hover {
					color: var(--cor-primaria);
				}
			} 
		}
	}
}
input[type="checkbox"] {
	width: 14px;
	height: 14px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 2px solid #8b8b8b;
	border-radius: 4px;
	position: relative;
	cursor: pointer;
}

input[type="checkbox"]:checked {
	margin-top: 3px;
	background-color: var(--cor-primaria);
	border: 1px solid #8b8b8b50;
}

input[type="checkbox"]:checked::after {
	content: '✔';
	position: absolute;
	top: 1px;
	left: 4px;
	font-size: 7px;
	color: #ffffff82;
}

.grupo-btns-form{
	& > button {
		font-size: 13.5px;
		width: 200px;
		height: 33px;
		border-radius: 7px;
		border: 1px solid #686868;
		background-color: var(--cor-primaria);
		color: #fff;
		font-weight: 500;
	}
}

#btn-entrar:disabled {
	background-color: var(--cor-primaria-disabled);
	border: 0;
	color: #fff;
	&:hover{
		cursor: not-allowed;
	}

}
.cadastro-grupo{
	&>span{
		font-size: 14px;
		font-weight: 400;
	}
	&>a{
		color: var(--cor-primaria);
		font-size: 14px;
		font-weight: 500;
	}
}

.right-side{
	display: flex;
	justify-content: center;
	background-color: var(--cor-primaria);
	width: 50%;
	height: 100%;
	overflow: hidden;
}

// input css 
.wrap-input-8 .input {
	width: 100%;
	height: 40px;
	font-size: 14px;
	box-sizing: border-box;
	letter-spacing: 1px;
	background-color: #f5f6fd;
	border: 0; 
	border-bottom: 2px solid var(--cor-primaria); 
	padding: 5px 10px 5px; 
	transition: 0.4s;
}
	
.wrap-input-8 .input:focus {
	outline: none;
}
	
.wrap-input-8 {
	width: 100%;
	margin-top: 5px;
	position: relative;
	& > i{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 8px;
		&:hover{
			cursor: pointer;
		}
	}
}
	
.wrap-input-8 .input ~ .focus-border:before,
.wrap-input-8 .input ~ .focus-border:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 2px;
	background-color: var(--cor-primaria);
	transition: 0.3s;
}
.wrap-input-8 .input ~ .focus-border:after {
	top: auto;
	bottom: 0;
	left: auto;
	right: 0;
}
.wrap-input-8 .input ~ .focus-border i:before,
.wrap-input-8 .input ~ .focus-border i:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 2px;
	height: 0;
	background-color: var(--cor-primaria);
	transition: 0.4s;
}
.wrap-input-8 .input ~ .focus-border i:after {
	left: auto;
	right: 0;
	top: auto;
	bottom: 0;
}
.wrap-input-8 .input:focus ~ .focus-border:before,
.wrap-input-8 .input:focus ~ .focus-border:after {
	width: 100%;
	transition: 0.3s;
}
.wrap-input-8 .input:focus ~ .focus-border i:before,
.wrap-input-8 .input:focus ~ .focus-border i:after {
	height: 100%;
	transition: 0.4s;
}

@media (max-width: 991px) { // RESPONSIVIDADE TABLET
	.container-login{
		height: 90vh;
		width: 900px;
		box-shadow: -5px 6px 10px -3px rgba(0,0,0,0.75);
	}

	.right-side {
		overflow: hidden;
	}
}
@media (max-width: 575px) { // RESPONSIVIDADE MOBILE

}
</style>