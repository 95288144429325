<template>
	<div class="meus-pedidos">
		<!-- EXIBE O HEADER PARA DESKTOP -->
		<div class="d-none d-sm-none d-md-none d-lg-block">
			<HeaderDesktop></HeaderDesktop>
			<ModalPagamentoPix :dadosPix="dadosPix" :idPedido="idPedidoPix" @emitePagamento="togglePago()"></ModalPagamentoPix>
		</div>
		<!-- EXIBE O HEADER PARA TABLET -->
		<div class="d-none d-sm-none d-md-block d-lg-none">
			<HeaderTablet></HeaderTablet>
		</div>
		
		<!-- EXIBE O HEADER PARA MOBILE -->
		<div class="d-block d-sm-block d-md-none d-lg-none">
			<HeaderMobile></HeaderMobile>
		</div>
		<!-- <div v-show="isLoading" class="container-loading-inicial">
			<div class="spinner-border" style="width: 2.3rem; height: 2.3rem; margin-right: 11px;" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</div> -->
		<div class="breadcrumb-meus-pedidos">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<!-- <li class="breadcrumb-item">Buscar Produtos</li> -->
					<li class="breadcrumb-item active" aria-current="page">Meus Pedidos</li>
				</ol>
			</nav>
		</div>
		<div class="container-principal">
            <div class="container-conteudo-principal">
				<nav class="side-nav-pedidos">
					<button @click.prevent="opListagemPedidos = 1"  class="btn-pedido" id="btn-todos-pedidos">
						Todos os Pedidos
					</button>
					<button @click.prevent="opListagemPedidos = 2" class="btn-pedido" id="btn-pedidos-andamento">
						Em andamento
					</button>
					<button @click.prevent="opListagemPedidos = 3" class="btn-pedido" id="btn-pedidos-finalizados">
						Finalizados
					</button>
					<button @click.prevent="opListagemPedidos = 4" class="btn-pedido" id="btn-pedidos-cancelados">
						Cancelados
					</button>
				</nav>
				<section class="listagem-pedidos">
					<div class="header-listagem-pedidos">
						<div class="titulo-listagem-pedidos">
							<h1>Meus Pedidos</h1>
						</div>
						<div class="buscar-listagem-pedidos">
							<div class="buscar-pedido">
								<button @click.prevent="executarPesquisaPedido()"><i class="fa fa-search" aria-hidden="true"></i></button>
								<input v-model="vConteudoPesquisaPedido" @keyup.enter="executarPesquisaPedido()" type="text" placeholder="Buscar nº do pedido...">
							</div>
						</div>
					</div>
					<div v-if="!isLoading" class="itens-listagem-pedidos">
						<template v-if="lstPedidos.length > 0">
							<div v-for="(pedido, index) in lstPedidos" class="container-fluid item-pedido" :key="index">
								<div class="row header-pedido">
									<div class="col-sm-12 col-md-12 col-lg-3">
										<p>PEDIDO REALIZADO</p>
										<span>{{ dataPorExtenso(pedido.dtemissao) }}</span>
									</div>
									<div class="col-sm-12 col-md-12 col-lg-2">
										<p>TOTAL</p>
										<span>R$ {{ formataReal(pedido.totalgeral) }}</span>
									</div>
									<div class="col-sm-12 col-md-12 col-lg-3">
										<p>STATUS</p>
										<span v-if="parseInt(pedido.stpedido) === 0 && pedido.cobrancaemaberto == 0" style="color: #0044aa;"><i class="fa fa-spinner" aria-hidden="true"></i> Aguardando pagamento</span>
										<span v-if="parseInt(pedido.stpedido) === 0 && parseInt(pedido.cobrancaemaberto) === 1" style="color: #0044aa;"><i class="fa fa-spinner" aria-hidden="true"></i> Processando pagamento</span>
										<span v-else-if="parseInt(pedido.stpedido) === 2" style="color: red;"><i class="fa fa-ban" aria-hidden="true"></i> Cancelado</span>
										<span v-else-if="parseInt(pedido.stpedido) === 1 && parseInt(pedido.entregaexternafinalizada) === 1" style="color: green;"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Entregue</span>
										<span v-else-if="parseInt(pedido.stpedido) === 1 && parseInt(pedido.tpentregaecommerce) === 0 && parseInt(pedido.stentregaexterna) === 0" style="color: #0044aa;"><i class="fa fa-spinner" aria-hidden="true"></i> Em separação</span>
										<span v-else-if="parseInt(pedido.stpedido) === 1 && parseInt(pedido.tpentregaecommerce) === 0 && parseInt(pedido.stentregaexterna) === 1" style="color: #0044aa;"><i class="fa fa-shopping-bag" aria-hidden="true"></i> Aguardando Retirada</span>
									</div>
									<div class="col-sm-12 col-md-12 col-lg-3 ml-auto">
										<p>PEDIDO Nº {{pedido.id}}</p>
										<span>
											<a href="#">Detalhes</a> | 
											<a href="#">Nota Eletrônica</a>
										</span>
									</div>
								</div>
								<div class="row body-pedidos" :class="{'expanded': pedido.lstExpandida == 1}">
									<div class="col-sm-12 col-md-12 col-lg-8">
										<div class="row">
											<h2 v-if="pedido.stpedido == 0 && pedido.cobrancaemaberto == 0">Aguardando pagamento</h2>
											<h2 v-if="pedido.stpedido == 0 && pedido.cobrancaemaberto == 1">Seu pagamento está sendo analisado...</h2>
											<h2 v-else-if="	parseInt(pedido.stpedido) === 1 &&
															parseInt(pedido.entregaexternafinalizada) === 1" 
															style="color: green;">
															Você recebeu seu produto!
											</h2>
											<h2 v-else-if="	parseInt(pedido.stpedido) === 1 && 
															parseInt(pedido.tpentregaecommerce) === 0 && 
															parseInt(pedido.stentregaexterna) === 1 &&
															parseInt(pedido.entregaexternafinalizada) === 0" 
															style="color: #0044aa;">
															Disponível para retirada na loja!
											</h2>
											<!-- <h2 v-if="pedido.stpedido == 1">Entregue no dia 18 de abril de 2024</h2> -->
											<h2 v-if="pedido.stpedido == 2">O pedido expirou ou foi cancelado.</h2>
										</div>
										<div v-show="pedido.lstExpandida == 1" v-for="item in pedido.itens" class="row mt-3 mb-3 item-pedido-card">
											<div class="col-sm-12 col-md-12 col-lg-3">
												<router-link :to="{ name: 'DetalhesProduto', params: { idproduto: item.id, nomeproduto: preparaDescricaoRouter(item.descricao)}}">
													<img :src="'https://'+$store.state.clienteSac.endServidor+'/controller/ecommerce/'+item.imagens.patharquivo" alt="Imagem do produto" style="width: 80%; height: 93px;">
												</router-link>
											</div>
											<div class="col-sm-12 col-md-12 col-lg-9 detalhes-card-pedido">
												<router-link :to="{ name: 'DetalhesProduto', params: { idproduto: item.id, nomeproduto: preparaDescricaoRouter(item.descricao)}}">
													<h3>{{ toCapitalized(item.descproduto) }}</h3>
													<p>Ref.: {{item.coddig}}</p>
													<p><strong>Quantidade:</strong> {{ item.qtditem }}</p>
													<p><strong>Total:</strong> R$ {{ formataReal(item.vltotal) }}</p>
												</router-link>
											</div>
											<div class="divisoria-itens-pedido" style="margin-top: 10px;"></div>
										</div>
										<div v-show="!pedido.lstExpandida == 1" v-for="item in pedido.itens.slice(0, 2)" class="row mt-3 mb-3 item-pedido-card">
											<div class="col-sm-12 col-md-12 col-lg-3">
												<router-link :to="{ name: 'DetalhesProduto', params: { idproduto: item.coddig, nomeproduto: preparaDescricaoRouter(item.descproduto)}}">
													<img :src="'https://'+$store.state.clienteSac.endServidor+'/controller/ecommerce/'+item.imagens.patharquivo" alt="Imagem do produto" style="width: 80%; height: 93px;">
												</router-link>
											</div>
											<div class="col-sm-12 col-md-12 col-lg-9 detalhes-card-pedido">
												<router-link :to="{ name: 'DetalhesProduto', params: { idproduto: item.coddig, nomeproduto: preparaDescricaoRouter(item.descproduto)}}">
													<h3>{{ toCapitalized(item.descproduto) }}</h3>
													<p>Ref.: {{item.coddig}}</p>
													<p><strong>Quantidade:</strong> {{ item.qtditem }}</p>
													<p><strong>Total:</strong> R$ {{ formataReal(item.vltotal) }}</p>
												</router-link>
											</div>
											<div class="divisoria-itens-pedido" style="margin-top: 10px;"></div>
										</div>
										<template v-if="pedido.itens.length > 2">
											<div class="col-md-12 mais-produtos-card">
												<div class="row mt-2" @click.prevent="toggleMaisPedidos(index)">
													<p v-if="pedido.lstExpandida == 0">▼ MAIS <strong>{{ pedido.itens.length - 2 }}</strong> PRODUTOS</p>
													<p v-else>▲ OCULTAR ITENS</p>
												</div>
											</div>
										</template>
									</div>
									<div class="col-sm-12 col-md-12 col-lg-4 btns-card-pedido">
										<button @click.prevent="detalhesPedido(pedido)">Detalhes do pedido</button>
										<button v-if="parseInt(pedido.stpedido) === 0 && parseInt(pedido.cobrancaemaberto) === 0" @click.prevent="geraCobrancaPix({id : pedido.id, valor: pedido.totalgeral})"  class="btn-concluir-pag">Concluir Pagamento</button>
										<button v-if="parseInt(pedido.stpedido) === 0 && parseInt(pedido.cobrancaemaberto) === 1" disabled class="btn-concluir-pag" title="Aguarde o processamento do seu pagamento!">Concluir Pagamento</button>
										<button v-if="parseInt(pedido.stpedido) === 1">Comprar Novamente</button>
									</div>
								</div>
							</div>
							<!-- PAGINACAO AQUI -->
							<div class="row justify-content-center">
								<div class="col-8 col-sm-8 col-md-10 col-lg-12" id="dvpaginacao">
									<v-pagination
										v-model="pagina_atual"
										:page-count="pqtd_paginas"
										:classes="bootstrapPaginationClasses">
									</v-pagination>
								</div>
							</div>
						</template>
						<template v-else>
							<div class="nenhum-pedido-listagem" style="display: flex; justify-content: center; align-items: center;">
								<i class="fa fa-search" aria-hidden="true"></i>
								<h3>Não encontramos nenhum pedido!</h3>
							</div>
						</template>
					</div>
					<div v-else class="itens-listagem-pedidos" style="display: flex; justify-content: center; align-items: center;">
						<div class="spinner-border" style="width: 2.3rem; height: 2.3rem; margin-bottom: 20px;" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				</section>
			</div>
		</div>
		<template>
			<modal name="modal-detalhes-pedido" styles="background-color: transparent;" width="900" height="auto" scrollable>
				<div class="container-fluid container-detalhes-pedido">
					<div class="row header-detalhes-pedido">
						<div class="col-sm-12 col-md-12 col-lg-3">
							<p>PEDIDO REALIZADO</p>
							<span>{{ dataPorExtenso(mPedidoSelecionado.dtemissao) }}</span>
						</div>
						<div class="col-sm-12 col-md-12 col-lg-2">
							<p>TOTAL</p>
							<span>R$ {{formataReal(mPedidoSelecionado.totalgeral)}}</span>
						</div>
						<div class="col-sm-12 col-md-12 col-lg-3">
							<p>STATUS</p>
							<span v-if="parseInt(mPedidoSelecionado.stpedido) === 0 && pedido.cobrancaemaberto == 0" style="color: #0044aa;"><i class="fa fa-spinner" aria-hidden="true"></i> Aguardando pagamento</span>
							<span v-if="parseInt(mPedidoSelecionado.stpedido) === 0 && pedido.cobrancaemaberto == 1" style="color: #0044aa;"><i class="fa fa-spinner" aria-hidden="true"></i> Processando pagamento</span>
							<span v-else-if="parseInt(mPedidoSelecionado.stpedido) === 2" style="color: red;"><i class="fa fa-ban" aria-hidden="true"></i> Cancelado</span>
							<span v-else-if="parseInt(mPedidoSelecionado.stpedido) === 1 && parseInt(mPedidoSelecionado.entregaexternafinalizada) === 1" style="color: green;"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Entregue</span>
							<span v-else-if="parseInt(mPedidoSelecionado.stpedido) === 1 && parseInt(mPedidoSelecionado.tpentregaecommerce) === 0 && parseInt(mPedidoSelecionado.stentregaexterna) === 0" style="color: #0044aa;"><i class="fa fa-spinner" aria-hidden="true"></i> Em separação</span>
							<span v-else-if="parseInt(mPedidoSelecionado.stpedido) === 1 && parseInt(mPedidoSelecionado.tpentregaecommerce) === 0 && parseInt(mPedidoSelecionado.stentregaexterna) === 1" style="color: #0044aa;"><i class="fa fa-shopping-bag" aria-hidden="true"></i> Aguardando Retirada</span>
						</div>
						<div class="col-sm-12 col-md-12 col-lg-3 ml-auto">
							<p>PEDIDO Nº {{mPedidoSelecionado.id}}</p>
							<span>
								<a href="#">Detalhes</a> | 
								<a href="#">Nota Eletrônica</a>
							</span>
						</div>
					</div>
					<div class="container-fluid body-detalhes-pedido y-scroll" style>
						<div class="card" style="flex-direction: column;">
							<div class="card-body d-flex infos-detalhes-pedido">
								<div class="col-sm-12 col-md-12 col-lg-4">
									<p>Resumo do pedido</p>
									<div class="precos-pedido">
										<p>Subtotal:</p><span>R$ {{ formataReal(mPedidoSelecionado.totalsemdesconto) }}</span>
									</div>
									<div class="precos-pedido">
										<p>Frete:</p><span>R$ {{ formataReal(mPedidoSelecionado.totfrete) }}</span>
									</div>
									<div class="precos-pedido">
										<p>Descontos:</p><span>R$ {{ formataReal(mPedidoSelecionado.totaldesconto) }}</span>
									</div>
									<div class="divisoria-itens-pedido" style="margin-top: 5px;"></div>
									<div class="precos-pedido mt-2">
										<p><strong>Total pedido:</strong></p><span><strong> R$ {{ formataReal(mPedidoSelecionado.totalgeral)}}</strong></span>
									</div>
								</div>
								<div class="col-sm-12 col-md-12 col-lg-5" style="border-left: 1px solid #d3d3d3;">
									<p>Endereço de envio</p>
									<span>{{ toCapitalized(mPedidoSelecionado.enderecoentrega.nomedestinatario) }} {{ toCapitalized(mPedidoSelecionado.enderecoentrega.contato) }}</span>
									<span>{{ toCapitalized(mPedidoSelecionado.enderecoentrega.logradouro) }}, {{ toCapitalized(mPedidoSelecionado.enderecoentrega.numero) }}</span>
									<span>{{ toCapitalized(mPedidoSelecionado.enderecoentrega.complemento) }}</span>
									<span>{{ toCapitalized(mPedidoSelecionado.enderecoentrega.descbairro) }} - {{ mPedidoSelecionado.enderecoentrega.descmunicipio }}, {{ mPedidoSelecionado.enderecoentrega.iduf }}</span>
									<span>{{ toCapitalized(mPedidoSelecionado.enderecoentrega.cep) }}</span>
								</div>
								<div class="col-sm-12 col-md-12 col-lg-3" style="border-left: 1px solid #d3d3d3;">
									<p>Método de pagamento</p>
									<span>- Pix</span>
								</div>
							</div>
							<div class="card-header" style="text-align: left;">
								<h2 v-if="mPedidoSelecionado.stpedido == 0">Aguardando pagamento</h2>
								<h2 v-if="mPedidoSelecionado.stpedido == 1">Entregue no dia 18 de abril de 2024</h2>
								<h2 v-if="mPedidoSelecionado.stpedido == 2">Cancelado no dia 18 de abril de 2024</h2>
							</div>
						</div>
						<div v-for="item in mLstItensPedidoSelecionado" class="row mt-3 mb-3 item-detalhes-pedido">
							<div class="col-sm-12 col-md-12 col-lg-2">
								<router-link :to="{ name: 'DetalhesProduto', params: { idproduto: item.coddig, nomeproduto: preparaDescricaoRouter(item.descproduto)}}">
									<img src="https://placehold.co/400" alt="Imagem do produto" style="height: 55px;">
								</router-link>
							</div>
							<div class="col-sm-12 col-md-12 col-lg-9 detalhes-item-pedido">
									<div class="row">
										<div class="col-md-5">
											<h3>{{ toCapitalized(item.descproduto) }}</h3>
											<p><strong>Quantidade:</strong> {{ item.qtditem }}</p>
											<p><strong>Descontos:</strong> R$ {{ formataReal(mPedidoSelecionado.totaldesconto) }}</p>
										</div>
										<div class="col-md-4">
											<p>Ref.: {{item.coddig}}</p>
											<p><strong>Valor Unitário:</strong> R$ {{ formataReal(item.vlunitario) }}</p>
											<p><strong>Total:</strong> R& {{ formataReal(item.vltotal) }}</p>
										</div>
									</div>
								<!-- </router-link> -->
							</div>
							<div class="divisoria-itens-pedido" style="margin-top: 10px;"></div>
						</div>
					</div>
				</div>
			</modal>
		</template>
    </div>
</template>

<script>
// @ is an alias to /src
import HeaderDesktop 		from '@/components/Header/HeaderDesktop.vue';
import HeaderTablet 		from '@/components/Header/HeaderTablet.vue';
import HeaderMobile 		from '@/components/Header/HeaderMobile.vue';
import ModalPagamentoPix 	from '@/components/ModalPagamentoPix/ModalPagamentoPix.vue';
import vPagination 			from 'vue-plain-pagination'

import utils from '@/utilitarios/utils';

export default {
	name: 'MeusPedidos',
	components: {
		HeaderDesktop,
		HeaderTablet,
		HeaderMobile,
		ModalPagamentoPix,
		vPagination,
	},
	data: function() {
		return {
			isLoading				: false,
			descricaoCategoria   	: '',
			qtdPedidos				: 0,
			lstPedidos				: [],
			opListagemPedidos		: 1,
			vConteudoPesquisaPedido	: '',

			// Variáveis paginação
			pqtdregistros		: 20,
			pqtd_paginas 		: 1,
			pagina_atual 		: 1,
			pqtd_total_registros: 0,


			// Variáveis modal pagamento pix
			dadosPix				: {},
			idPedidoPix				: '',

			// Variáveis modal detalhes pedido
			mPedidoSelecionado			: {enderecoentrega: {}},
			mLstItensPedidoSelecionado	: [],
			// Variáveis de filtro
			marcas: [],
			precoProdutos: {
				min: 0,
				max: 0,
			},
			vPrecoFiltro: {
				min: 0,
				max: 0,
			},
			bootstrapPaginationClasses: {
				ul: 'pagination pagination-xxl justify-content-center',
				li: 'page-item',
				liActive: 'active li-ativo',
				liDisable: 'disabled li-desativado',
				button: 'page-link'
			},
			descontos: [
				{id: 1, nome: 'Até 10%'},
				{id: 2, nome: '10% a 30%'},
				{id: 4, nome: '30% a 60%'},
				{id: 6, nome: '60% a 80%'},
				{id: 7, nome: 'Mais de 80%'},
			],
			vMarcaFiltro				: '',
			vPrecoFiltro				: '',
			vDescontoFiltro				: '',
        };
    },
    methods: {
		toCapitalized(value){
			return utils.capitalizeText(value);
		},
		executarPesquisaPedido(){
			if(this.vConteudoPesquisaPedido.trim() != ''){
				this.listagemPedidos(1);
			}
		},
		listagemPedidos(ipagina){
			let vself = this;
			vself.isLoading = true;
			let filtro = vself.preparaFiltrosListagem();
			$('.btn-pedido').removeClass('active');
			switch(vself.opListagemPedidos){
				case 1:
					$('#btn-todos-pedidos').addClass('active');
					break;
				case 2:
					$('#btn-pedidos-andamento').addClass('active');
					break;
				case 3:
					$('#btn-pedidos-finalizados').addClass('active');
					break;	
				case 4:
					$('#btn-pedidos-cancelados').addClass('active');
					break;
			}
			let parametros = [
                {parametro: 'op',           	valor: 'listagempedidos'},
                {parametro: 'idclientesac',     valor: vself.clienteSac.id},
				{parametro: 'filtro', 			valor: filtro},
				{parametro: 'idforma',			valor: null},
				{parametro: 'joinaux',			valor: ""},
				{parametro: 'limit',        	valor : vself.pqtdregistros},
				{parametro: 'offset',       	valor : (parseInt(ipagina) -1) * parseInt(vself.pqtdregistros)},
				{parametro: 'pathimagens',		valor: vself.$store.state.configPaths.pathImgProdutos},
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtmeuspedidos.php');
            retorno.then(function (response) {
				response.data.pedidos.forEach(pedido => {
					pedido.lstExpandida = 0;
				});
				response.data.pedidos.forEach(pedido => {
					pedido.itens.forEach(item => {
						item.imagens = vself.ordenaImagensProduto(item.imagens)[0];
					});
				});
				vself.lstPedidos = response.data.pedidos;
				vself.isLoading = false;
            }).catch(function (response) {
                console.log(response.message);
				vself.isLoading = false;
            });
		},
		listagemQtdPedidos(){
                var vself           = this;
                let filtro 			= vself.preparaFiltrosListagem();
                vself.pagina_atual  = 1;
                let parametros      = [
                    {parametro: 'idclientesac', valor  : vself.clienteSac.id},
                    {parametro: 'op',           valor  : 'qtdregistros'},
                    {parametro: 'filtro',       valor  : filtro}
                ];
                let retorno     = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtmeuspedidos.php');
                retorno.then(function (response) {
                    vself.pqtd_total_registros = parseInt(response.data.qtdregistros);
                    let qtdpg                  = Math.ceil(vself.pqtd_total_registros / vself.pqtdregistros);
                    vself.pqtd_paginas         = qtdpg > 0 ? qtdpg : 1;
                    vself.listagemPedidos(1);
                }).catch(function (response) {
                    alert('Erro ao consultar registros');
                });
        },
		preparaFiltrosListagem(){
			// console.log(this.usuarioLogado);
			let rfiltro = ' AND p.origem = 6 AND p.idcliente = ' + this.usuarioLogado.id;

			if (this.vConteudoPesquisaPedido.trim() !== '') {
				rfiltro += " AND p.id LIKE '%" + this.vConteudoPesquisaPedido.trim() + "%'";
			}
			if(parseInt(this.opListagemPedidos) === 2){ // Em andamento / Em aberto / Pendentes
				rfiltro += ' AND p.entregaexternafinalizada = 0';
			}else if(parseInt(this.opListagemPedidos) === 3){ // Finalizados / Entregues 
				rfiltro += ' AND p.stpedido = 1	AND p.entregaexternafinalizada = 1';
			}else if(parseInt(this.opListagemPedidos) === 4){ // Cancelados
				rfiltro += ' AND p.stpedido = 2';
			}
			rfiltro += ' ORDER BY p.id DESC';
			return rfiltro;
		},
		ordenaImagensProduto(lstimagens){
			let lstordenada = lstimagens.sort((a, b) => {
				if (a.arquivo < b.arquivo) {
					return -1;
				}
				if (a.arquivo > b.arquivo) {
					return 1;
				}
				return 0;
			});
			return lstordenada;
		},
		toggleMaisPedidos(i){
			this.lstPedidos[i].lstExpandida = this.lstPedidos[i].lstExpandida == 1 ? 0 : 1;
		},
		detalhesPedido(pedido){
			this.mPedidoSelecionado = pedido;
			// Verifica se o pedido será entrega externa, se sim, verifica qual endereço exibir
			if (this.mPedidoSelecionado.tpentregaecommerce > 0){
				// Verifica se o endereço de entrega é o mesmo do cliente
				if (parseInt(this.mPedidoSelecionado.enderecoentrega.id) === 0) {
					
				}
			}
			this.mLstItensPedidoSelecionado = pedido.itens;

			this.$modal.show('modal-detalhes-pedido');
		},
		geraCobrancaPix(pedido){
			let vself = this;
			let parametros = [
				{parametro: 'op'				, valor: 'geracobrancapix'},
				{parametro: 'idclientesac'		, valor: vself.clienteSac.id},
				{parametro: 'idfilial'			, valor: vself.filial.id},
				{parametro: 'idcliente'			, valor: vself.usuarioLogado.id},
				{parametro: 'valor'				, valor: pedido.valor},
				{parametro: 'referencia'		, valor: pedido.id},
				{parametro: 'tpforma'			, valor: 0}, // 0 - Pix
			];
			let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/auxiliares.php');
			retorno.then(function (response){
				if(typeof response.data.inf === 'number'){
					vself.dadosPix = response.data.cobranca;
					vself.idPedidoPix = response.data.idpedido;
					vself.$modal.show('modal-pagamento-pix');
				} else {
					alert('Erro ao gerar cobrança Pix');
				}
			}).catch(function (response) {
				console.log(response.message);
			});
		},
		preparaDescricaoRouter(descricao){
			return utils.prepDescricaoRouter(descricao);
		},
		dataPorExtenso(data){
			return utils.dataPorExtenso(data);
		},
		formataReal(valor, prefixo = ''){
			return utils.formataReal(valor, prefixo);
		},
		toCapitalized(value){
			return utils.capitalizeText(value);
		},
    },
	computed: {
		clienteSac(){
            return this.$store.state.clienteSac;
        },
        filial(){
            return this.$store.state.filial;
        },
        usuarioLogado() {
            return this.$store.state.usuarioLogado;
        },
        configPaths() {
            return this.$store.state.configPaths;
        },
        infosGeraisProdutos(){
            return this.$store.state.infosGeraisProdutos;
        },
		destaquesProdutos(){
			return this.$store.state.filial.destaquesProdutos;
		},
		produtosAleatorios(){
			return this.$store.state.produtosAleatorios;
		},
		verificaConteudoPesquisa(){
			return this.$route.params.conteudopesquisa;
		},
	},
	mounted: function() {
		if (window.localStorage.getItem('usuarioLogado') != null){      
			let payload = (JSON.parse(window.localStorage.getItem('usuarioLogado')))   
			this.$store.commit('MUDAR_USUARIO_LOGADO', payload);
		}
		this.listagemQtdPedidos();
		
		// this.$modal.show('modal-detalhes-pedido');
		
		// console.log(window.localStorage.getItem('usuarioLogado'));
		// console.log(this.$store.state.usuarioLogado);
	},
	watch: {
		'$route.params.conteudopesquisa': function(newVal, oldVal) {
            this.pesquisaProdutos();
        },
		pagina_atual(val){
			this.listagemPedidos(val);
		},
		opListagemPedidos(val){
			this.listagemQtdPedidos();
		},
		vConteudoPesquisaPedido(val){
			if(val.trim() == ''){
				this.listagemQtdPedidos();
			}
		},
	}
}
</script>
<style scoped lang="scss">
.page-item.active .page-link{
	background-color: #043664;
	border-color: #043664;
	color: #043664;
}
.page-link:hover{
	color: #043664;
}
.divisoria-itens-pedido{
	background-color: #c0c0c0;
	width: 100%;
	height: 1px;
}
.meus-pedidos {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.066)
}
.breadcrumb-meus-pedidos{
	margin-top: 15px;
	width: 1100px;
	height: 30px;
	font-size: 15px;
	margin-inline: auto;
	&>nav .breadcrumb{
		background-color: transparent;
		padding: 0;
		margin: 0;
		&>.breadcrumb-item a{
			color: var(--cor-primaria-scroll);
		}
		&>.breadcrumb-item.active{
			color: var(--cor-primaria);
		}
	}
}


.container-principal{
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	overflow: hidden;
	width: 1100px;
	margin-inline: auto;
	min-height: 500px;
	margin-bottom: 50px;
}



.container-conteudo-principal{
	display: flex;
	width: 100%;
	margin-top: 15px;
	// background-color: red;
}

.side-nav-pedidos {
	width: 190px;
	height: fit-content;
	// background-color: #fff;
	margin-right: 10px;
}
.btn-pedido{
	width: 100%;
	height: 40px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-bottom: 10px;
	font-size: 15px;
	color: #000;
	cursor: pointer;
	transition: all 0.3s;
	&:hover{
		background-color: #f5f5f5;
	}
	&.active {
		height: 60px;
		background-color: var(--cor-primaria);
		color: #fff;
	}
}

.listagem-pedidos{
	width: 100%;
	height: fit-content;
	background-color: #fff;
}

.header-listagem-pedidos{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	border-bottom: 1px solid #c0c0c0;
}
.titulo-listagem-pedidos{
	h1{
		margin-bottom: 0;
		font-size: 25px;
	}
}
.buscar-listagem-pedidos{
    // background-color: blue;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    input[type=text] {
        height: 30px;
        width: 400px;
        border-radius: 8px;
        padding-left: 10px;
        border: none;
        box-shadow: 0px 0px 5px -2.5px rgba(0,0,0,0.75);

        &:focus {
            outline: 0;
            border: 1px solid rgb(172, 172, 172);
        }
    }
}

.buscar-pedido{
    position: relative;
    height: fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    
    & > button {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            color: var(--cor-primaria);
            position: absolute;
            right: 10px;
        }
    }
}

.nenhum-pedido-listagem{
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 50px;
	flex-direction: column;
	&>i{
		font-size: 50px;
		color: #c0c0c0;
		margin-bottom: 10px;
	}
	&>h3{
		font-size: 23px;
		color: var(--cor-primaria);
		opacity: .7;
		margin-bottom: 0;
	}
}

.itens-listagem-pedidos{
	min-height: 60vh;
	padding: 10px;
}

.item-pedido{
	width: 100%;
	overflow: hidden;
	// padding-bottom: 50px;
	background-color: #fff;
	border-radius: 10px;
	border: 2px solid #c0c0c0;
	margin-bottom: 15px;
	&>.header-pedido{
		background-color: #f1f1f1;
		border-bottom: 1px solid #c0c0c0;
		div {
			display: flex;
			flex-direction: column;
			padding-block: 5px;
		}
		p {
			text-align: left;
			margin-bottom: 0;
			font-size: 12px;
		}
		span {
			text-align: left;
			font-size: 14px;
			font-weight: 500;
			color: #000;
			&>a{
				color: #007bff;
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
	&>.body-pedidos {
		max-height: 342px;
		padding: 10px;
		transition: max-height 0.5s ease-in;
		
		h2{
			font-weight: 600;
			font-size: 20px;
			margin-bottom: 0;
		}
		&.expanded{
			max-height: 5000px;
		}
	}
}

.detalhes-card-pedido{
	padding-left: 0;
	text-align: left;
	h3 {
		color: var(--cor-primaria);
		font-size: 18px;
		margin-bottom: 0;
	}
	p {
		color: #000;
		font-size: 14px;
		margin-bottom: 0;
	}
}

.mais-produtos-card{
	height: 40px;
	text-align: center;
	cursor: pointer;
	font-size: 16px;
	font-weight: 500;
	color: var(--cor-primaria);
	display: flex;
	justify-content: center;
	align-items: center;
	p {
		margin-bottom: 0;
	}
}

.btns-card-pedido{
	display: flex;
	flex-direction: column;
	padding-top: 20px;
	align-items: center;
	button{
		width: 100%;
		height: 40px;
		background-color: #fff;
		color: var(--cor-primaria);
		border: 1px solid #ccc;
		border-radius: 5px;
		margin-bottom: 10px;
		cursor: pointer;
		transition: all 0.3s;
		&:hover{
			background-color: #f5f5f5;
		}
	}
	.btn-concluir-pag{
		background-color: var(--cor-primaria);
		color: #fff;
		&:hover{
			background-color: var(--cor-primaria-hover);
		}
	}
}

// estilização do modal
.vm--modal.modal-detalhes-pedido-class{
	background-color: transparent;
}
.container-detalhes-pedido{
	overflow: hidden;
	// padding-bottom: 50px;
	background-color: #fff;
	border-radius: 10px;
	border: 2px solid #c0c0c0;
	&>.header-detalhes-pedido{
		background-color: #f1f1f1;
		
		border-bottom: 1px solid #c0c0c0;
		div {
			display: flex;
			flex-direction: column;
			padding-block: 5px;
			// border-bottom: 1px solid #c0c0c0;
		}
		p {
			text-align: left;
			margin-bottom: 0;
			font-size: 12px;
		}
		span {
			text-align: left;
			font-size: 14px;
			font-weight: 500;
			color: #000;
			&>a{
				color: #007bff;
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
	&>.body-detalhes-pedido {
		height: fit-content;
		max-height: 600px;
		overflow-y: scroll;
		overflow-x: hidden;
		padding: 10px;
		h2{
			font-weight: 600;
			font-size: 20px;
			margin-bottom: 0;
		}
	}
}

.infos-detalhes-pedido{
	&>div{
		display: flex;
		flex-direction: column;
		text-align: left;
		margin-bottom: 10px;
		&>p{
			color: var(--cor-primaria);
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 0;
		}
		&>span {
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 0;
		}
		&>.precos-pedido{
			height: 25px;
			display: flex;
			justify-content: space-between;
			margin-right: 15px;
			&>p{
				font-size: 14px;
			}
			&>span{
				font-size: 14px;
				font-weight: 400;
			}

		}
	}
}

.detalhes-item-pedido{
	padding-left: 0;
	text-align: left;
	h3 {
		color: var(--cor-primaria);
		font-size: 16px;
		margin-bottom: 0;
	}
	p {
		color: #000;
		font-size: 13px;
		margin-bottom: 0;
	}
}
</style>