import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		clienteSac:{
			id					: 14, // fazer logica para pegar o id do cliente sac
			endServidor			: '',
		},
		filial:{
			id        			: 1, // fazer logica para pegar o id da filial
			fantasia			: '',
			tema				: 'padrao',
			idtabelaestoque		: null, // o produto só irá ser exibido no site se tiver estoque nessa tabela
			idtabelaprecos		: null, // aqui tem que carregar o id da tabela. os produtos só aparecerão no site se estiverem preços vinculados a essa tabela
			idplano             : null,
			idvendedor			: null,
			endereco			: {
				cep			: '',
				idbairro	: '',
				descbairro	: '',
				idcidade	: '',
				desccidade	: '',
				iduf		: '',
				descuf		: '',
				logradouro	: '',
				numero		: '',
			},
		},
		usuarioLogado: {
			id				: null,
			nome			: '',
			cep				: '',
			cepecommerce	: '',
		},
		pagarMe: {
			chavePublica: 'pk_test_WxklEkibqc5xJ1Lr',	
		},
		urlsPagarme: {
			urlServices: 'app-rillsoft.com.br/pagarme/',
			urlToken: 'https://api.pagar.me/core/v5/tokens',
		},
		configPaths: {
			// pathControle: 'https://www.rillsoft-dev.cloud/controller/',
			pathControle    	: '',
			pathArquivos    	: '',
			pathImgProdutos 	: 'imagens/manager/produtos/',
			pathImgClientes 	: 'imagens/manager/clientes/',
			pathImgPedidos 		: 'imagens/manager/pedidos/',
		},
		configPathsLogos: {
			logoPrincipal 	: '',
			logoSecundaria 	: '',
			logoIcone 		: '',
		},
		infosGeraisProdutos: {
			marcas				: [],
			todasCategorias		: [],
			categoriasHeader	: [],
			todasSubcategorias	: [],
		},
		carrinhoCompras: {
			qtdItens: 0,
		},
		produtosAleatorios: [
			{
				idproduto: 1519221,
				descricao: 'Smart TV 55" 4K LG NanoCell 55NANO77SRA Bluetooth, ThinQ AI, Alexa',
				valorReal: '2.250,00',
				valorDesc: '1.957,50',
				porcentagemDesc: '13',
				urlimg: '1g.avif',
				outro: 'ou R$2.250,00 em 5x de R$450,00 sem juros',
				qtdItensCarrinho: 0,
			},
			{
				idproduto: 1519222,
				descricao: 'Smart Tv Samsung 32 Hd Tizen T4300 Bivolt',
				valorReal: '1.899,00',
				valorDesc: '1.059,00',
				porcentagemDesc: '44',
				urlimg: '1g.avif',
				outro: 'ou R$2.250,00 em 10x de R$105,00 sem juros',
				qtdItensCarrinho: 0,
			},
			{
				idproduto: 1519223,
				descricao: 'Apple iPhone 15 Pro Max 256 GB -Titânio Natural',
				valorReal: '8.998,89',
				valorDesc: '8.099,00',
				porcentagemDesc: '14',
				urlimg: '1g.avif',
				outro: 'ou R$8.998,89 em 10x de R$899,89 sem juros',
				qtdItensCarrinho: 0,
			},
			{
				idproduto: 1519224,
				descricao: 'Smart TV 55" 4K LG NanoCell 55NANO77SRA Bluetooth, ThinQ AI, Alexa',
				valorReal: '2.250,00',
				valorDesc: '1.957,50',
				porcentagemDesc: '13',
				urlimg: '1g.avif',
				outro: 'ou R$2.250,00 em 5x de R$450,00 sem juros',
				qtdItensCarrinho: 0,
			},
			{
				idproduto: 1519225,
				descricao: 'Smart Tv Samsung 32 Hd Tizen T4300 Bivolt',
				valorReal: '1.899,00',
				valorDesc: '1.059,00',
				porcentagemDesc: '44',
				urlimg: '1g.avif',
				outro: 'ou R$2.250,00 em 10x de R$105,00 sem juros',
				qtdItensCarrinho: 0,
			},
			{
				idproduto: 1519226,
				descricao: 'Smart Tv Samsung 32 Hd Tizen T4300 Bivolt',
				valorReal: '1.899,00',
				valorDesc: '1.059,00',
				porcentagemDesc: '44',
				urlimg: '1g.avif',
				outro: 'ou R$2.250,00 em 10x de R$105,00 sem juros',
				qtdItensCarrinho: 0,
			},
			{
				idproduto: 1519221,
				descricao: 'Smart TV 55" 4K LG NanoCell 55NANO77SRA Bluetooth, ThinQ AI, Alexa',
				valorReal: '2.250,00',
				valorDesc: '1.957,50',
				porcentagemDesc: '13',
				urlimg: '1g.avif',
				outro: 'ou R$2.250,00 em 5x de R$450,00 sem juros',
				qtdItensCarrinho: 0,
			},
			{
				idproduto: 1519222,
				descricao: 'Smart Tv Samsung 32 Hd Tizen T4300 Bivolt',
				valorReal: '1.899,00',
				valorDesc: '1.059,00',
				porcentagemDesc: '44',
				urlimg: '1g.avif',
				outro: 'ou R$2.250,00 em 10x de R$105,00 sem juros',
				qtdItensCarrinho: 0,
			},
			{
				idproduto: 1519223,
				descricao: 'Apple iPhone 15 Pro Max 256 GB -Titânio Natural',
				valorReal: '8.998,89',
				valorDesc: '8.099,00',
				porcentagemDesc: '14',
				urlimg: '1g.avif',
				outro: 'ou R$8.998,89 em 10x de R$899,89 sem juros',
				qtdItensCarrinho: 0,
			},
			{
				idproduto: 1519224,
				descricao: 'Smart TV 55" 4K LG NanoCell 55NANO77SRA Bluetooth, ThinQ AI, Alexa',
				valorReal: '2.250,00',
				valorDesc: '1.957,50',
				porcentagemDesc: '13',
				urlimg: '1g.avif',
				outro: 'ou R$2.250,00 em 5x de R$450,00 sem juros',
				qtdItensCarrinho: 0,
			},
			{
				idproduto: 1519225,
				descricao: 'Smart Tv Samsung 32 Hd Tizen T4300 Bivolt',
				valorReal: '1.899,00',
				valorDesc: '1.059,00',
				porcentagemDesc: '44',
				urlimg: '1g.avif',
				outro: 'ou R$2.250,00 em 10x de R$105,00 sem juros',
				qtdItensCarrinho: 0,
			},
			{
				idproduto: 1519226,
				descricao: 'Smart Tv Samsung 32 Hd Tizen T4300 Bivolt',
				valorReal: '1.899,00',
				valorDesc: '1.059,00',
				porcentagemDesc: '44',
				urlimg: '1g.avif',
				outro: 'ou R$2.250,00 em 10x de R$105,00 sem juros',
				qtdItensCarrinho: 0,
			},
		]
	},
	getters: {
	},
	mutations: {
		MUDAR_FILIAL(state, payload) {
			state.filial = payload;
		},
		MUDAR_USUARIO_LOGADO(state, payload) {
			state.usuarioLogado = payload;
		},
		MUDAR_CEP_ECOMMERCE(state, payload) {
			state.usuarioLogado.cepecommerce = payload;
			window.localStorage.setItem('usuarioLogado', JSON.stringify(state.usuarioLogado));
		},
		MUDAR_CONFIG_PATHS(state, payload) {
			state.configPaths = payload;
		},
		MUDAR_CONFIG_PATHS_LOGOS(state, payload) {
			state.configPathsLogos.logoPrincipal 	= payload.logoPrincipal;
			state.configPathsLogos.logoSecundaria 	= payload.logoSecundaria;
		},
		MUDAR_CONFIG_PATHS_LOGO_ICONE(state, payload) {
			if (state.configPathsLogos.logoIcone !== payload) {
				state.configPathsLogos.logoIcone = payload;
				window.localStorage.setItem('path_logo_icone', payload);
			}
		},
		MUDAR_END_SERVIDOR(state, payload) {
			state.clienteSac.endServidor = payload;
		},
		MUDAR_ID_CLIENTE_SAC(state, payload) {
			state.clienteSac.id = payload;
		},
		LOGOUT_USUARIO(state) {
			window.localStorage.clear();
			state.usuarioLogado = {
				id			: null,
				nome		: '',
				cep			: '',
				cepecommerce: '',
			}
		},
		MUDAR_QTD_CARRINHO_COMPRAS(state, payload) {
			state.carrinhoCompras.qtdItens = payload;
		},
		MUDAR_MARCAS_PRODUTOS(state, payload) {
			state.infosGeraisProdutos.marcas = payload;
		},
		MUDAR_TODAS_CATEGORIAS_PRODUTOS(state, payload) {
			state.infosGeraisProdutos.todasCategorias = payload;
		},
		MUDAR_CATEGORIAS_HEADER_PRODUTOS(state, payload) {
			state.infosGeraisProdutos.categoriasHeader = payload
		},
		MUDAR_TODAS_SUBCATEGORIAS_PRODUTOS(state, payload) {
			state.infosGeraisProdutos.todasSubcategorias = payload;
		},
	},
	actions: {
	},
	modules: {
	}
})
