import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeDestaques from '../views/HomeDestaques.vue'
import LoginUsuario from '@/views/LoginUsuario.vue'
import CadastroUsuario from '@/views/CadastroUsuario.vue'
import DetalhesPerfil from '@/views/DetalhesPerfil.vue'
import MeusPedidos from '@/views/MeusPedidos.vue'
import DetalhesProduto from '@/views/DetalhesProduto.vue'
import ProdutosCategoria from '@/views/ProdutosCategoria.vue'
import BuscarProdutos from '@/views/BuscarProdutos.vue'
import Checkout from '@/views/Checkout.vue'
import RecuperarSenha from '@/views/RecuperarSenha.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeDestaques',
    component: HomeDestaques
  },
  {
    path: '/login',
    name: 'LoginUsuario',
    component: LoginUsuario,
  },
  {
    path: '/recuperarsenha',
    name: 'RecuperarSenha',
    component: RecuperarSenha,
  },
  {
    path: '/cadastro',
    name: 'CadastroUsuario',
    component: CadastroUsuario
  },
  {
    path: '/perfil',
    name: 'DetalhesPerfil',
    component: DetalhesPerfil,
  },
  {
    path: '/pedidos',
    name: 'MeusPedidos',
    component: MeusPedidos,
  },
  {
    path: '/produtos/:idproduto/:nomeproduto',
    name: 'DetalhesProduto',
    component: DetalhesProduto,
  },
  {
    path: '/categorias/:idcategoria/:desccategoria',
    name: 'ProdutosCategoria',
    component: ProdutosCategoria,
  },
  {
    path: '/buscar/:srcpesquisa',
    name: 'BuscarProdutos',
    component: BuscarProdutos,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
  },
  // {
  //   path: '/categorias/Eletronicos/Smart-TV',
  //   name: 'ProdutosCategoria',
  //   component: ProdutosCategoria,
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
