import utils from './utils.js';

export default {
    async carregaDadosClienteSac(context){   
        let idclientesac = '';
        let vself = context;  

        let parametros = [
            {parametro: 'op'            , valor: 'pegainfosecommerce'}, 
            {parametro: 'dominio'       , valor: window.location.hostname}
            // {parametro: 'dominio'       , valor: 'amazonfix.com.br'}
            // {parametro: 'dominio'       , valor: 'distribuidorafeirao.com.br'}
        ];
        try {
            let response = await utils.reqAxios(parametros, 'post', 'rillsoft.app/sacweb/controller/auxiliares.php');

            idclientesac = response.data.codcli
        } catch (error) {
            console.log(error.message);
        }
        let payload = {
            pathControle    	: '',
            pathArquivos    	: 'controller/ecommerce/arquivos/',
            pathAssets      	: 'controller/ecommerce/assets/',
            pathImgProdutos 	: 'imagens/manager/produtos/',
            pathImgClientes 	: 'imagens/manager/clientes/',
			pathImgPedidos 		: 'imagens/manager/pedidos/',
        };

        if (idclientesac){
            vself.$store.commit('MUDAR_ID_CLIENTE_SAC', idclientesac); // muda globalmente o idcliente sac antes de fazer outras requests - guga
            let parametros = [
                {parametro: 'op', valor: 'dadoslogin'}, 
                {parametro: 'idclientesac', valor: idclientesac}
            ];
            try {
                // Conecta direto no servidor do sac para coletar as informações do cliente
                let response = await utils.reqAxios(parametros, 'post', 'rillsoft.app/sacweb/controller/auxiliares.php');
                // Verifica se encontrou o cliente no sac e se o cliente tem sistemas ativos
                if (response.data.fantasia !== '' || response.data.sistemas.length > 0){
                    for (let sistema of response.data.sistemas) {
                        // Se ele tiver o manager web, retorna as informações desse sistema
                        if (parseInt(sistema.codsis) === 31){
                            // console.log(sistema);
                            let endereco = sistema.endereco;
                            payload.pathControle = 'https://' + endereco + '/controller/';
                            vself.$store.commit('MUDAR_CONFIG_PATHS', payload);
                            vself.$store.commit('MUDAR_END_SERVIDOR', endereco);
                            return payload;
                        }
                    }
                }
                return payload;
            } catch (error) {
                console.log(error.message);
                return payload;
            }
        } else {
            return payload;
        }                 
    },
    async carregaLogosFilial(vself){
        let idclientesac = vself.$store.state.clienteSac.id;

        let payload = {
            logoPrincipal       : '',
            logoSecundaria      : '',
            logoIcone           : '',
        };

        if(idclientesac){
            let parametros = [
                {parametro: 'op'            , valor: 'listagemlogosfilial'}, 
                {parametro: 'idclientesac'  , valor: idclientesac},
                {parametro: 'idfilial'      , valor: vself.$store.state.filial.id}
            ];
            try {
                let res = await utils.reqAxios(parametros, 'post', vself.$store.state.clienteSac.endServidor+'/controller/ecommerce/auxiliares.php');
                res = res.data;
                payload.logoPrincipal       = res.logoprincipal.length > 0 ? res.logoprincipal[0].patharquivo : '';
                payload.logoSecundaria      = res.logosecundaria.length > 0 ? res.logosecundaria[0].patharquivo : '';
                payload.logoIcone           = res.icone.length > 0 ? res.icone[0].patharquivo : '';
                vself.$store.commit('MUDAR_CONFIG_PATHS_LOGO_ICONE', payload.logoIcone);
                vself.$store.commit('MUDAR_CONFIG_PATHS_LOGOS', payload);
                return payload;
            } catch (error) {
                // console.log(error.message);
                return payload;
            }
        } else {
            return payload;
        }
    },
    async carregaDadosFilial(vself){
        let idclientesac = vself.$store.state.clienteSac.id;
        let payload = {
            id        			: vself.$store.state.filial.id,
            fantasia			: '',
            tema				: 'padrao',
            idtabelaestoque		: null, // o produto só irá ser exibido no site se tiver estoque nessa tabela
            idtabelaprecos		: null, // aqui tem que carregar o id da tabela. os produtos só aparecerão no site se estiverem preços vinculados a essa tabela
            idplano             : null,
            idvendedor			: null,
            endereco			: {
				cep: '',
				idbairro: '',
				descbairro: '',
				idcidade: '',
				desccidade: '',
				iduf: '',
				descuf: '',
				logradouro: '',
				numero: '',
			},
        };

        if(idclientesac){
            let parametros = [
                {parametro: 'op'            , valor: 'carregadadosfilial'}, 
                {parametro: 'idclientesac'  , valor: idclientesac},
                {parametro: 'idfilial'      , valor: vself.$store.state.filial.id}
            ];
            try {
                let response = await utils.reqAxios(parametros, 'post', vself.$store.state.clienteSac.endServidor+'/controller/ecommerce/auxiliares.php');
                console.log(response.data);
                if (response.data.inf == 1){
                    payload.id        			= response.data.idfilial;
                    payload.fantasia			= response.data.fantasia;
                    payload.tema				= response.data.tema;
                    payload.idtabelaestoque		= response.data.idtabelaestoque;
                    payload.idtabelaprecos		= response.data.idtabelaprecos;
                    payload.idplano             = response.data.idplano;
                    payload.idvendedor          = response.data.idvendedor;

                    // binds do endereço
                    payload.endereco.cep        = utils.capitalizeText(response.data.endereco.cep);
                    payload.endereco.idbairro   = utils.capitalizeText(response.data.endereco.idbairro);
                    payload.endereco.descbairro = utils.capitalizeText(response.data.endereco.descbairro);
                    payload.endereco.idcidade   = utils.capitalizeText(response.data.endereco.idcidade);
                    payload.endereco.desccidade = utils.capitalizeText(response.data.endereco.desccidade);
                    payload.endereco.iduf       = utils.capitalizeText(response.data.endereco.iduf);
                    payload.endereco.descuf     = utils.capitalizeText(response.data.endereco.descuf);
                    payload.endereco.logradouro = utils.capitalizeText(response.data.endereco.logradouro);
                    payload.endereco.numero     = utils.capitalizeText(response.data.endereco.numero);
                    
                    vself.$store.commit('MUDAR_FILIAL', payload);
                } 
                return payload;
            } catch (error) {
                console.log(error.message);
                return payload;
            }
        } else {
            return payload;
        }
    },
    async carregaInfosGeraisProdutos(vself){
        let idclientesac = vself.$store.state.clienteSac.id;

        let payload = {
            marcas			    : [],
            todasCategorias		: [],
            categoriasHeader    : [],
            todasSubcategorias	: [],
        };
        let parametros = [
            {parametro: 'op'            , valor: 'carregainfosgeraisprodutos'},
            {parametro: 'idclientesac'  , valor: idclientesac},
            {parametro: 'idfilial'      , valor: vself.$store.state.filial.id}
        ];
        try {
            let response = await utils.reqAxios(parametros, 'post', vself.$store.state.clienteSac.endServidor+'/controller/ecommerce/auxiliares.php');
                payload.marcas			    = response.data.marcas;
                payload.todasCategorias		= response.data.todasCategorias;
                payload.categoriasHeader	= response.data.categoriasHeader;
                payload.todasSubcategorias	= response.data.todasSubcategorias;

                vself.$store.commit('MUDAR_MARCAS_PRODUTOS', payload.marcas);
                vself.$store.commit('MUDAR_TODAS_CATEGORIAS_PRODUTOS', payload.todasCategorias);
                vself.$store.commit('MUDAR_CATEGORIAS_HEADER_PRODUTOS', payload.categoriasHeader);
                vself.$store.commit('MUDAR_TODAS_SUBCATEGORIAS_PRODUTOS', payload.todasSubcategorias);
            return payload;
        } catch (error) {
            console.log(error.message);
            return payload;
        }
    },
}
