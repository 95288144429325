<template>
    <div class="container-fluid bg-login">
        <div class="row container-login">
            <div class="left-side">
                <div v-show="isLoadingMounted" class="spinner-border" style="width: 2.2rem; height: 2.2rem;" role="status">
						<span class="sr-only">Loading...</span>
				</div>
                <transition name="fade">
                    <div v-show="!isLoadingMounted">
                        <div class="logo-login">
                            <img @click="$router.push({ path: '/' })" :src="'https://'+clienteSac.endServidor+'/controller/ecommerce/'+configPathsLogos.logoPrincipal" alt="logo loja">
                        </div>
                        <div class="infos-cadastro">
                            <h1>Crie uma conta!</h1>
                            <span>Preencha com as suas informações continuar.</span>
                        </div>
                        <div class="aviso-cadastro">
                            <transition name="fade">
                                <span v-show="avisoCadastro" >{{ avisoCadastro }}</span>
                            </transition>
                        </div>
                        <form class="">
                            <div class="row" style="margin-bottom: 7px;">
                                <div id="app" class="col-8" style="text-align: left;">
                                    <div class="grupo-form">
                                        <label for="edNome">Nome Completo:</label>
                                        <div class="wrap-input-8">
                                            <input id="edNome" v-model="vNomeCadastro" :disabled="isLoadingCadastro" class="input" type="text" placeholder="Digite seu nome completo" maxlength="80">
                                            <span class="focus-border"><i></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="grupo-form">
                                        <label for="edCep">CEP <small style="color: #8b8b8b; float: right;">(Opcional)</small></label>
                                        <div class="wrap-input-8">
                                            <input id="edCep" v-mask="'#####-###'" v-model="vCepCadastro" :disabled="isLoadingCadastro" class="input" type="text" placeholder="Digite seu cep">
                                            <span class="focus-border"><i></i></span>
                                        </div>
                                        <small v-if="erroCep"> </small>
                                        <small v-else>Cep inválido!</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-bottom: 7px;">
                                <div class="col-7">
                                    <div class="grupo-form">
                                        <label for="edEmail">E-mail</label>
                                        <div class="wrap-input-8">
                                            <input id="edEmail" v-model="vEmailCadastro" :disabled="isLoadingCadastro" class="input" type="text" placeholder="Digite seu email">
                                            <span class="focus-border"><i></i></span>
                                        </div>
                                        <small v-if="erroEmail">{{erroEmail}}</small>
                                        <small v-else> </small>
                                    </div>
                                </div>
                                <div class="col-5">
                                    <div class="grupo-form">
                                        <label for="edCpfCnpj">CPF ou CNPJ</label>
                                        <div class="wrap-input-8">
                                            <input id="edCpfCnpj" v-mask="['###.###.###-##', '##.###.###/####-##']" v-model="vCpfCnpjCadastro" :disabled="isLoadingCadastro" class="input" type="text" placeholder="Digite o CPF ou CNPJ">
                                            <span class="focus-border"><i></i></span>
                                        </div>
                                        <small v-if="erroCpfCnpj">CPF ou CNPJ inválido!</small>
                                        <small v-else> </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-bottom: 7px;">
                                <div class="col-6">
                                    <div class="grupo-form">
                                        <label for="edSenha1">Senha
                                            <span v-if="vSenha1.length === 20" style="width: fit-content; color: rgb(161, 0, 0); font-weight: 400; float: right;">{{ vSenha1.length }}/20 caracteres</span>
                                            <span v-else style="width: fit-content; color: #8b8b8b; font-weight: 400; float: right;">{{ vSenha1.length }}/20 caracteres</span>
                                        </label>
                                        <div class="wrap-input-8">
                                            <input maxlength="20" id="edSenha1" v-model="vSenha1" :disabled="isLoadingCadastro" class="input" type="password" placeholder="Digite sua senha">
                                            <span class="focus-border"><i></i></span>
                                            <i v-if="mostraSenha1" class="fa fa-eye" aria-hidden="true" @click="toggleSenha1"></i>
                                            <i v-else class="fa fa-eye-slash" aria-hidden="true" @click="toggleSenha1"></i>
                                        </div>
                                        <small v-if="erroSenha1">A senha deve conter 8 ou mais caracteres!</small>
                                        <small v-else> </small>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="grupo-form">
                                        <label for="edSenha2">Confirmar senha</label>
                                        <div class="wrap-input-8">
                                            <input maxlength="30" id="edSenha2" v-model="vSenha2" :disabled="isLoadingCadastro" class="input" type="password" placeholder="Repita sua senha">
                                            <span class="focus-border"><i></i></span>
                                            <i v-if="mostraSenha2" class="fa fa-eye" aria-hidden="true" @click="toggleSenha2"></i>
                                            <i v-else class="fa fa-eye-slash" aria-hidden="true" @click="toggleSenha2"></i>
                                        </div>
                                        <small v-if="erroSenha2">As senhas não conferem!</small>
                                        <small v-else> </small>
                                    </div>
                                </div>
                            </div>
                            <div class="termos-uso" style="width: 100%;">
                                <small style="font-size: 11.5px;">
                                    Ao criar uma conta, você concorda com nossos <span @click="ativaAceite = false" data-toggle="modal" data-target="#staticBackdrop">termos de serviço</span>
                                    e <span @click="ativaAceite = false" data-toggle="modal" data-target="#staticBackdrop">política de privacidade</span>.
                                </small>
                            </div>
                            <div class="grupo-btns-form">
                                <button @click.prevent="cadastrarUsuario" :disabled="isLoadingCadastro">
                                    <div v-if="isLoadingCadastro" class="spinner-border" style="width: 1.2rem; height: 1.2rem;" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <template v-else>
                                        Cadastre-se <i class="bi bi-arrow-right"></i>
                                    </template>
                                </button>
                            </div>
                            <div class="cadastro-grupo" style="margin-top: 10px;">
                                <span>Já possui uma conta? </span><router-link to="/login">Entre agora</router-link>
                            </div>
                        </form>
                    </div>
                </transition>
            </div>
            <div class="right-side">
                <img src="../assets/images/login/gente-fleiz.jpg" alt="">
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" style="background-color: rgba(0, 0, 0, 0.5);" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Termos de uso e serviço</h5>
                    </div>
                    <div class="modal-body">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum laborum temporibus, dolorum officia repudiandae sequi blanditiis earum quo voluptatem suscipit hic laboriosam commodi enim, expedita doloremque, voluptatum voluptates saepe maiores.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum laborum temporibus, dolorum officia repudiandae sequi blanditiis earum quo voluptatem suscipit hic laboriosam commodi enim, expedita doloremque, voluptatum voluptates saepe maiores.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum laborum temporibus, dolorum officia repudiandae sequi blanditiis earum quo voluptatem suscipit hic laboriosam commodi enim, expedita doloremque, voluptatum voluptates saepe maiores.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum laborum temporibus, dolorum officia repudiandae sequi blanditiis earum quo voluptatem suscipit hic laboriosam commodi enim, expedita doloremque, voluptatum voluptates saepe maiores.
                    </div>
                    <div class="modal-footer">
                        <div class="aceite" style="width: 100%; display: flex;">
                            <input style="float: left;" type="checkbox" name="" id="" v-model="ativaAceite">
                            <span style="margin-left: 5px;">Concordo com os termos de uso e serviço</span>
                        </div>
                        <button type="button" class="btn btn-primary" data-dismiss="modal" :disabled="!ativaAceite">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import utils from '@/utilitarios/utils.js';

export default {
	name: 'CadastroUsuario',
	components: {

	},
	data: function() {
        return {
            imgLogoFilial           : 'https://i.imgur.com/8lJ6gDQ.png',
            // CONFIGURAÇÕES DO CLIENTE
            temaFilial              : '', // AQUI TRÁS O TEMA DE CORES ESCOLHIDO NA CONFIGURAÇÃO DO WEBSITE NO SISTEMA

            // VARIAVEIS FORMULÁRIO CADASTRO
            mostraSenha1            : false,
            mostraSenha2            : false,
            vCpfCnpjCadastro        : '',
            erroCpfCnpj             : false,
            vNomeCadastro           : '',
            vEmailCadastro          : '',
            erroEmail               : '',
            vCepCadastro            : '',
            vSenha1                 : '',
            vSenha2                 : '',
            ativaAceite             : false,
            avisoCadastro           : '',

            isLoadingMounted        : true,
            isLoadingCadastro       : false,
        };
    },
    mounted: function(){
        setTimeout(() => {
            this.isLoadingMounted = false;
        }, 500);
    },
    methods: {
        toggleSenha1 (){
            this.mostraSenha1 = !this.mostraSenha1;
            if (this.mostraSenha1) {
                $('#edSenha1').attr('type',"text")
            } else {
                $('#edSenha1').attr('type',"password")
            }
        },
        toggleSenha2 (){
            this.mostraSenha2 = !this.mostraSenha2;
            if (this.mostraSenha2) {
                $('#edSenha2').attr('type',"text")
            } else {
                $('#edSenha2').attr('type',"password")
            }
        },
        verificaCnpjCpf(){
            // Aqui valida se o cpf ou cnpj tem a quantidade de caracteres válida
            if (this.vCpfCnpjCadastro.length > 5 && 
                this.vCpfCnpjCadastro.length !== 14 && 
                this.vCpfCnpjCadastro.length !== 18){

                this.erroCpfCnpj = true
                return false
            }
            return true
        },
        async verificaCnpjCpfBD(){
            let vself = this;
            let retornoReq = { status : false }
            let parametros = [
                {parametro: 'op',           	valor: 'verificacnpjcpf'},
                {parametro: 'cnpjcpf',    		valor: utils.limpaCnpjCpf(vself.vCpfCnpjCadastro)},
                {parametro: 'idclientesac',     valor: vself.$store.state.clienteSac.id}
            ];
            try {
                let response = await utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtcadastrousuario.php');
                if (response.data.idcliente) {
                    retornoReq.status       = true;
                    retornoReq.idcliente    = response.data.idcliente;
                    retornoReq.cnpjcpf      = response.data.cnpjcpf;
                    retornoReq.email        = response.data.email;
                    retornoReq.temcadastro  = response.data.temcadastro;
                    retornoReq.erro         = 0;
                }
                return retornoReq;
            } catch (error) {
                retornoReq.status = false;
                retornoReq.erro = 1;
                console.log(error.message);
                return retornoReq;
            }
        },
        async verificaEmailBD(){
            let vself = this;
            let registro = {
                cnpjcpf     : utils.limpaCnpjCpf(vself.vCpfCnpjCadastro),
                email       : vself.vEmailCadastro,
            }
            let parametros = [
                {parametro: 'op',           	valor: 'verificaemail'},
                {parametro: 'idclientesac',     valor: vself.$store.state.clienteSac.id},
                {parametro: 'registro',    		valor: JSON.stringify(registro)},
            ];
            try {
                let response = await utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtcadastrousuario.php');
                return response.data;
            } catch (error) {
                return {msg : error.message}
            }
        },
        async cadastrarUsuario(){
            if( this.vNomeCadastro.trim()   === '' ||
                this.vCepCadastro.trim()        === '' ||
                this.vEmailCadastro             === '' ||
                this.vCpfCnpjCadastro.trim()    === '' ||
                this.vSenha1                    === '' ||
                this.vSenha2                    === '') {
                
                this.avisoCadastro = 'Por favor, preencha todos os campos!'
                
                setTimeout(() => {
                    this.avisoCadastro = ''
                }, 5000);
                return
            }
            
            this.isLoadingCadastro = true;

            //////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // (da pra simplificar isso aqui futuramente **unir a verificação de caracteres do cpf com a verificação no banco**)
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////
            
            // PRIMEIRO VERIFICA SE O CPF OU CNPJ ESTÁ COM QUANTIDADE E FORMATO VÁLIDO
            if(!this.verificaCnpjCpf()){
                this.isLoadingCadastro = false;
                setTimeout(() => {
                    this.erroCpfCnpj = false
                }, 3000);
                return
            }

            // DEPOIS VERIFICA SE O CPF OU CNPJ JÁ EXISTE NO BANCO DE DADOS E DEFINE O OPCRUD
            let retornoCnpjCpf = await this.verificaCnpjCpfBD()
            let opcrud = retornoCnpjCpf.status ? 3 : 1
            if (!retornoCnpjCpf.status && retornoCnpjCpf.erro === 1) {
                this.isLoadingCadastro = false;
                this.avisoCadastro = 'Ocorreu um erro. Tente novamente ou entre em contato conosco.'
                setTimeout(() => {
                    this.avisoCadastro = ''
                }, 5000);
                return
            } else if (retornoCnpjCpf.temcadastro){
                this.isLoadingCadastro = false;
                this.avisoCadastro = 'CPF/CNPJ já cadastrado, faça login ou recupere sua senha.'
                setTimeout(() => {
                    this.avisoCadastro = ''
                }, 10000);
                return
            }
            // console.warn(opcrud == 3 ? '***cpf/cnpj já existe' : '***cpf/cnpj não existe')
            // console.log(retornoCnpjCpf)

            let retornoEmail = await this.verificaEmailBD()
            if(retornoEmail.msg !== 1){
                this.isLoadingCadastro = false;
                this.erroEmail = retornoEmail.msg
                setTimeout(() => {
                    this.erroEmail = ''
                }, 5000);
                return
            } else {
                this.erroEmail = ''
            }

            let vself = this;
            let registroCrud = {
                id              : retornoCnpjCpf.idcliente,
                nome            : vself.vNomeCadastro,
                idfilial        : vself.$store.state.filial.id,
                dtcadastro      : utils.dataAtual(true, true),
                cnpjcpf         : utils.limpaCnpjCpf(vself.vCpfCnpjCadastro),
                email           : vself.vEmailCadastro,
                senha           : vself.vSenha1,
                cep             : utils.limpaCnpjCpf(vself.vCepCadastro),
                cepecommerce    : utils.limpaCnpjCpf(vself.vCepCadastro),
            }
            let parametros = [
                {parametro: 'op',           	valor: 'rotinacrud'},
                {parametro: 'opcrud',    		valor: opcrud}, // DEFINE SE É INSERT OU UPDATE DE UM CLIENTE/USUÁRIO
                {parametro: 'registro',     	valor: JSON.stringify(registroCrud)},
                {parametro: 'idclientesac',     valor: vself.$store.state.clienteSac.id}
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtcadastrousuario.php');
            retorno.then(function (response) {
                if(isNaN(response.data.idcliente)){
                    vself.avisoCadastro = response.data.idcliente
                    setTimeout(() => {
                        vself.avisoCadastro = ''
                    }, 5000);
                } else {
                    vself.isLoadingMounted = true
                    vself.logarUsuario();

                }
                vself.isLoadingCadastro = false;
            }).catch(function (response) {
                console.log(response.message);
                vself.isLoadingCadastro = false;
            });
        },
        async logarUsuario(){
			let vself = this;
			this.isLoading = true;
            let parametros = [
                {parametro: 'op',           	valor: 'loginusuario'},
                {parametro: 'loginusu',    		valor: this.vEmailCadastro},
                {parametro: 'senhausu',     	valor: this.vSenha1},
                {parametro: 'idclientesac',     valor: this.$store.state.clienteSac.id}
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/auxiliares.php');
            retorno.then(function (response) {
				if(parseInt(response.data.qtdusu) > 0){
					let dadoscliente = {
						id 				: response.data.idcliente,
						nome 			: response.data.nome,
						cep 			: response.data.cep,
						cepecommerce 	: response.data.cepecommerce,
					}
					vself.$store.commit('MUDAR_USUARIO_LOGADO', dadoscliente);

					window.localStorage.setItem('usuarioLogado', JSON.stringify(dadoscliente));

					// LIMPA VARIÁVEIS E REDIRECIONA
					vself.vNomeCadastro = '';
                    vself.vCpfCnpjCadastro = '';
                    vself.vCepCadastro = '';
                    vself.vEmailCadastro = '';
                    vself.vSenha1 = '';
                    vself.vSenha2 = '';

					vself.$router.push({ path: '/' });
					vself.isLoadingCadastro = false;
					vself.isLoadingMounted = false;
				} else {
                    vself.avisoCadastro = response.data.msg;
                    vself.isLoadingCadastro = false;
					vself.isLoadingMounted = false;
					setTimeout(() => {
						vself.avisoCadastro = '';
					}, 5000);
				}
            }).catch(function (response) {
                console.log(response.message);
				vself.isLoadingCadastro = false;
				vself.isLoadingMounted = false;
            });
		},
    },
    computed: {
        clienteSac(){
            return this.$store.state.clienteSac;
        },
        filial(){
            return this.$store.state.filial;
        },
        usuarioLogado() {
            return this.$store.state.usuarioLogado;
        },
        configPaths() {
            return this.$store.state.configPaths;
        },
        configPathsLogos() {
            return this.$store.state.configPathsLogos;
        },
        infosGeraisProdutos(){
            return this.$store.state.infosGeraisProdutos;
        },
        erroCep(){
            // AQUI VAI A LÓGICA DE VALIDAÇÃO DE CEP
            return true
        },
        erroSenha1(){
            if (this.vSenha1.length > 0){
                return this.vSenha1.length < 8
            } else {
                return false
            }
        },
        erroSenha2(){
            if (this.vSenha2.length > 0){
                return this.vSenha1 != this.vSenha2
            } else {
                return false
            }
        },
    },
    watch: {
        vEmailCadastro: function(){
            this.vEmailCadastro = this.vEmailCadastro.replace(' ', '').toLowerCase()
        },
        vNomeCadastro: function(){
            this.vNomeCadastro = this.vNomeCadastro.toUpperCase()
        }
    }
}
</script>
<style>
</style>
<style scoped lang="scss">

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
    opacity: 0;
}

.bg-login{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgb(231, 231, 231);
}
.container-login{
    height: 90vh;
    width: 60vw;
    box-shadow: -5px 6px 10px -3px rgba(0,0,0,0.75);
}
.left-side{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #fff;
    width: 65%;
    height: 100%;
    &> div {
        width: 100%;
        form {
            padding-inline: 5%;
            width: 100%;
        }
    }
}
.logo-login{
    padding-top: 10px;
    width: 100%;
    height: fit-content;
    & > img {
		&:hover{
			cursor: pointer;
		}
		width: 200px;
	}
}
.infos-cadastro{
    margin-block: 14px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > h1 {
        margin: 0;
        font-size: 22px;
    }
    & > span {
        font-size: 15px;
        color: rgba(0, 0, 0, 0.683);
    }
}
.form-login{
    margin-top: 20px;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
}
.aviso-cadastro{
    width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
	// background-color: red;
	& > span {
		padding-inline: 13px;
		border-radius: 5px;
		border: 1px solid rgba(0, 0, 0, 0.452);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		font-weight: 500;
		background-color: rgba(255, 0, 0, 0.151);
		height: 30px;
	}
}

.grupo-form{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    // background-color: red;
    & > label {
        text-align: left;
        font-size: 13px;
        margin: 0;
        font-weight: 600;
        color: var(--cor-primaria);
    }
    & > small {
        overflow-y: hidden;
        padding-top: 3px;
        height: 16px;
        // background-color: blue;
        font-size: 11px;
        font-weight: 500;
        color: rgb(161, 0, 0);
    }
}

.grupo-btns-form{
    & > button {
        font-size: 13.5px;
        width: 200px;
        height: 33px;
        border-radius: 7px;
        border: 1px solid #686868;
        background-color: var(--cor-primaria);
        color: #fff;
        font-weight: 500;
    }
}
.cadastro-grupo{
    &>span{
        font-size: 14px;
        font-weight: 400;
    }
    &>a{
        color: var(--cor-primaria);
        font-size: 14px;
        font-weight: 500;
    }
}

.termos-uso{
    width: 100%;
    padding-inline: 10%;
    margin-top: 5px;
    margin-bottom: 5px;
    & > small {
        & > span {
            font-weight: 500;
            color: var(--cor-primaria);
            &:hover {
                color: var(--cor-primaria-hover);
                cursor: pointer;
            }
        }
    }
}

.right-side{
    background-color: var(--cor-primaria);
    width: 35%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
        height: 100%;
    }
}

// input css 
.wrap-input-8 .input {
    width: 100%;
    height: 40px;
    font-size: 14px;
    box-sizing: border-box;
    letter-spacing: 1px;
    background-color: #f5f6fd;
    border: 0; 
    border-bottom: 2px solid var(--cor-primaria); 
    padding: 5px 10px 5px; 
    transition: 0.4s;
}
    
.wrap-input-8 .input:focus {
    outline: none;
}
    
.wrap-input-8 {
    width: 100%;
    // margin-top: 5px;
    position: relative;
    & > i{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        &:hover{
            cursor: pointer;
        }
    }
}
    
.wrap-input-8 .input ~ .focus-border:before,
.wrap-input-8 .input ~ .focus-border:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--cor-primaria);
    transition: 0.3s;
}
.wrap-input-8 .input ~ .focus-border:after {
    top: auto;
    bottom: 0;
    left: auto;
    right: 0;
}
.wrap-input-8 .input ~ .focus-border i:before,
.wrap-input-8 .input ~ .focus-border i:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 0;
    background-color: var(--cor-primaria);
    transition: 0.4s;
}
.wrap-input-8 .input ~ .focus-border i:after {
    left: auto;
    right: 0;
    top: auto;
    bottom: 0;
}
.wrap-input-8 .input:focus ~ .focus-border:before,
.wrap-input-8 .input:focus ~ .focus-border:after {
    width: 100%;
    transition: 0.3s;
}
.wrap-input-8 .input:focus ~ .focus-border i:before,
.wrap-input-8 .input:focus ~ .focus-border i:after {
    height: 100%;
    transition: 0.4s;
}
@media (max-width: 575px) { // RESPONSIVIDADE MOBILE

}
</style>