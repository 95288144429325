<template>
    <div class="container-fluid bg-login">
        <div class="row container-login">
            <div class="left-side">
                <div v-show="isLoadingMounted" class="spinner-border" style="width: 2.2rem; height: 2.2rem;" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <transition name="fade">
                    <div v-show="!isLoadingMounted">
                        <div class="logo-login">
                            <img @click="$router.push({ path: '/' })" :src="'https://'+clienteSac.endServidor+'/controller/ecommerce/'+configPathsLogos.logoPrincipal" alt="logo loja">
                        </div>
						<template v-if="parseInt(novoFormulario) === 0">
							<div class="infos-cadastro">
								<h1>Recuperação de Senha</h1>
								<span>Insira seu e-mail para recuperar sua senha.</span>
							</div>
							<form class="form-login" @submit.prevent="enviarCodigo">
								<div class="grupo-form">
									<label for="edEmail">E-mail</label>
									<div class="wrap-input-8">
										<input id="edEmail" v-model="vemail" class="input" type="text" placeholder="Digite seu e-mail">
										<span class="focus-border"><i></i></span>
									</div>
									<div class="email-error" style="height: 20px; width: 100%; display: flex; flex-direction: row-reverse;">
										<small v-show="vemailerror" style="color: red; margin-top: 5px; font-size: 12px; font-weight: 600;">E-mail inválido!</small>
									</div>
								</div>
								<div class="grupo-btns-form">
									<button id="btn-recuperar">
										<template>
											Receber código
											<i class="bi bi-arrow-right"></i>
										</template>
									</button>
								</div>
								<div class="cadastro-grupo" style="margin-top: 10px;">
									<span>Lembrou a senha? </span><router-link to="/login">Faça login</router-link>
								</div>
							</form>
						</template>
						<template v-else>
							<div class="infos-cadastro">
								<h1>Recuperação de Senha</h1>
								<span>Insira sua nova senha.</span>
							</div>
							<form class="form-login" @submit.prevent="recuperarSenha">
								<div class="grupo-form">
									<label for="edNovaSenha">Nova senha</label>
									<div class="wrap-input-8">
										<input id="edNovaSenha" v-model="vNovaSenha" class="input" type="text" placeholder="Digite sua nova senha">
										<span class="focus-border"><i></i></span>
									</div>
									<label for="edConfirmaSenha">Confirmar senha</label>
									<div class="wrap-input-8">
										<input id="edConfirmaSenha" v-model="vConfirmaNovaSenha" class="input" type="text" placeholder="Confirme sua senha">
										<span class="focus-border"><i></i></span>
									</div>
									<div class="senha-error" style="height: 20px; width: 100%; display: flex; flex-direction: row-reverse;">
										<small v-show="vNovaSenha !== vConfirmaNovaSenha" style="color: red; margin-top: 5px; font-size: 12px; font-weight: 600;">Senhas não coincidem!</small>
									</div>
								</div>
								<div class="grupo-btns-form">
									<button id="btn-recuperar" :disabled="isLoading" >
										<div v-if="isLoading" class="spinner-border" style="width: 1.2rem; height: 1.2rem;" role="status">
											<span class="sr-only">Loading...</span>
										</div>
										<template v-else>
											Recuperar senha
											<i class="bi bi-arrow-right"></i>
										</template>
									</button>
								</div>
								<div class="cadastro-grupo" style="margin-top: 10px;">
									<span>Lembrou a senha? </span><router-link to="/login">Faça login</router-link>
								</div>
							</form>
						</template>
                    </div>
                </transition>
            </div>
            <div class="right-side">
                <img src="../assets/images/login/gente-fleiz.avif" alt="" style="height: 115%; width: 669px;">
            </div>
        </div>
        <template>
            <modal name="codigo-recuperacao" classes="modal-codigo" height="auto" :clickToClose="false">
				<div class="header-modal-recuperacao">
					<div class="col-sm-1 col-md-1 col-lg-1 d-flex justify-content-center align-center ml-auto mt-2">
						<button type="button" class="close" @click.prevent="$modal.hide('codigo-recuperacao')">&times;</button>
					</div>
				</div>
				<div class="logo-modal"> 
					<img :src="'https://'+clienteSac.endServidor+'/controller/ecommerce/'+configPathsLogos.logoPrincipal" alt="logo loja">
				</div>
				<template v-if="isLoadingCode === true && alternaModal === 1">
					<h5>Enviando o código para o seu email.</h5>
					<span class="loader"></span>
				</template>
				<template v-if="isLoadingCode === true && alternaModal === 4">
					<div class="alert alert-danger" role="alert">
						<i class="bi bi-exclamation-triangle-fill mr-2"></i>
					<span>{{ errorMessage }}</span>
					</div>
				</template>
				<template v-else-if="isLoadingCode === false && alternaModal === 2">
					<h5>Por favor, digite o código que enviamos para:</h5>
					<p>{{ email }}</p>
					
					<div class="code-inputs" :class="{ shake: isShaking }">
						<input
						v-for="(digit, index) in digitos"
							:key="index"
							type="text"
							class="single-digit-input"
							maxlength="1"
							v-model="vCodigoRecuperacao[index]"
							@input="onInput(index)"
							@keydown.backspace="onBackspace(index)"
							@paste="onPaste($event)"
							:ref="'codeInput' + index"
						/>
					</div>
					<div class="error">
						<p>{{ errorMessage }}</p>
					</div>
					<div class="footer-modal" style="width: 100%;">
						<h4 class="duracao-codigo">{{ tempoDuracao  }}</h4>
					</div>
				</template>
				<template v-else-if="isLoadingCode === false && alternaModal === 3">
					<h5 class="mb-4 mt-4" style="margin-block: auto; font-weight: bold;">
						Por favor, solicite um novo código de recuperação.
					</h5>
					<div class="grupo-btns-form mb-3">
						<button id="btn-recuperar" @click.prevent="reenviarCodigo">
							Reenviar código <i class="bi bi-arrow-repeat"></i>
						</button>
					</div>
					<div :class="{'footer-modal-alert' : alternaModal === 3, 'footer-modal': alternaModal === 2}" style="width: 100%;">
						<i class="bi bi-exclamation-triangle-fill mr-2"></i>
						<span>{{ errorMessage }}</span>
					</div>
				</template>
            </modal>
        </template>	
    </div>
</template>
<script>
import utils from '@/utilitarios/utils.js';
// @ is an alias to /src

export default {
	name: 'RecuperarSenha',
	components: {
		
	},
	data: function() {
		return {
			// CONFIGURAÇÕES DO CLIENTE
			temaFilial              : '', // AQUI TRÁS O TEMA DE CORES ESCOLHIDO NA CONFIGURAÇÃO DO WEBSITE NO SISTEMA
			corPrimaria			 	: '',	

			// DADOS FORMULARIO
			vemail 					: '',
			vemailerror 			: false,

			isLoading 				: false,
			isLoadingMounted		: true,

			isLoadingCode			: true,
			alternaModal			: 1,
			isShaking				: false,

            email                   : '', 
            token                   : '', 
            digitos                 : 6, 
            vCodigoRecuperacao      : Array(6).fill(''), 
            errorMessage            : '',
            tempoRestante			: 60, 
            contagem				: null,

			//DADOS FORMULARIO RECUPERACAO SENHA
			novoFormulario 			: 0,
			vNovaSenha 				: '',
			vConfirmaNovaSenha 		: '',
		};
	},
	methods: {
		triggerShake() {
			this.isShaking = true;
			setTimeout(() => {
			this.isShaking = false;
			}, 300); 
		},
		reenviarCodigo(){
            this.errorMessage = ""; 
			this.enviarCodigo(); 
            this.comecarContagem(); 
		},
		comecarContagem() {
            this.tempoRestante = 60;
            if (this.contagem) clearInterval(this.contagem);
            this.contagem = setInterval(() => {
                if (this.tempoRestante > 0) {
                    this.tempoRestante -= 1;
                } else {
                    clearInterval(this.contagem);
                    this.errorMessage = "O código expirou.";
					this.alternaModal = 3;
                }
            }, 1000);
        },
		onPaste(event) {
			const pastedData = event.clipboardData.getData("text").slice(0, this.digitos);
			for (let i = 0; i < pastedData.length; i++) {
				this.$set(this.vCodigoRecuperacao, i, pastedData[i]);
				if (i < this.digitos - 1) {
					this.$refs[`codeInput${i + 1}`][0].focus();
				}
			}
			if (pastedData.length === this.digitos) {
				this.vCodigoRecuperacao = pastedData.split(''); 
				this.verificarCodigo(); 
			}
			event.preventDefault();
		},
		onInput(index) {
			const input = this.$refs[`codeInput${index}`][0];
			if (input.value !== "" && index < this.digitos - 1) {
				this.$refs[`codeInput${index + 1}`][0].focus();
			}
			if (this.vCodigoRecuperacao.join('').length === this.digitos) {
				this.verificarCodigo();
			}
		},
		onBackspace(index) {
			if (this.vCodigoRecuperacao[index] === "" && index > 0) {
				this.$refs[`codeInput${index - 1}`][0].focus();
			}
		},
		verificarCodigo() {
			let vself = this;
			let codeTratado = vself.vCodigoRecuperacao.join('').replace(/[^0-9]/g, '');

			vself.isLoading = true;
			let parametros = [
				{ parametro: 'op', 				valor: 'confirmacodigo' },
				{ parametro: 'idclientesac', 	valor: vself.$store.state.clienteSac.id },
				{ parametro: 'codigo', 			valor: codeTratado} 
			];

			let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtesquecisenha.php');
			retorno.then(function (response) {
				if (response.data.sucesso) {
					vself.$modal.hide('codigo-recuperacao'); 
					vself.novoFormulario = 1;
					vself.token = response.data.token;
				} else {
					vself.errorMessage = response.data.message;
					vself.triggerShake();
				}
				vself.isLoading = false;
			}).catch(function (error) {
				console.log(error.message);
				vself.errorMessage = error.message;
				vself.triggerShake();
			});
		},
        enviarCodigo() {
            let vself = this;
			vself.errorMessage = '';
			vself.alternaModal 	= 1;
			vself.isLoadingCode = true;
            if (vself.vemail === '' || vself.vemailerror) {
				vself.vemailerror = true;
                $('#edEmail').trigger("focus");
                return;
            }
			vself.vCodigoRecuperacao = [];
			vself.$modal.show('codigo-recuperacao');

            vself.isLoading = true;
            let parametros = [
                { parametro: 'op',              valor: 'verificaemail' },
                { parametro: 'idclientesac',    valor: vself.$store.state.clienteSac.id },
                { parametro: 'email',           valor: vself.vemail }
            ];
            // console.log(parametros);
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtesquecisenha.php');
            retorno.then(function (response) {
				if (response.data && response.data.status === 'sucesso') {
					vself.email 		= response.data.email;
					vself.isLoadingCode = false;
					vself.alternaModal 	= 2;
                } else {
					vself.alternaModal 	= 4;
                    vself.errorMessage = response.data.message;
                }
            }).catch(function (error) {
                console.log(error.message);
                vself.errorMessage = response.data.message;
                vself.alternaModal 	= 4;
            });
        },
        recuperarSenha() {
			let vself = this;

			if (vself.vNovaSenha !== vself.vConfirmaNovaSenha) {
				alert('As senhas não coincidem!');
				return;
			}

			vself.isLoading = true;
			let parametros = [
				{ parametro: 'op', 				valor: 'atualizarsenha' },
				{ parametro: 'idclientesac', 	valor: vself.$store.state.clienteSac.id },
				{ parametro: 'novasenha', 		valor: vself.vNovaSenha },
				{ parametro: 'token', 			valor: vself.token }
			];

			let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtesquecisenha.php');
			retorno.then(function (response) {
				if (response.data.sucesso) {
					vself.$toastr.s('Senha alterada com sucesso!');
					setTimeout(() => {
						vself.$router.push({ path: '/login' });
					}, 2000);
				} else {
					vself.$toastr.w('Erro ao alterar a senha. Tente novamente mais tarde.');
				}
				vself.isLoading = false;
			}).catch(function (error) {
				console.log(error.message);
				vself.isLoading = false;
			});
		},
	},
	beforeMount: function() {
		if (this.$store.state.usuarioLogado.id != null){
			this.$router.push({ path: '/' })
		}

	},
	mounted: function() {
		let elemento = document.querySelector('.tema-'+this.$store.state.filial.tema);
		this.corPrimaria = getComputedStyle(elemento).getPropertyValue('--cor-primaria')
		setTimeout(() => {
            this.isLoadingMounted = false;
        }, 500);
	},
	computed: {
		usuarioLogado() {
			return this.$store.state.usuarioLogado;
		},
		filial(){
			return this.$store.state.filial;
		},
		clienteSac(){
            return this.$store.state.clienteSac;
        },
		configPaths(){
			return this.$store.state.configPaths;
		},
        configPathsLogos() {
            return this.$store.state.configPathsLogos;
        },
		tempoDuracao() {
            const minutes = Math.floor(this.tempoRestante / 60);
            const seconds = this.tempoRestante % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        }
	},
	watch: {
		vemail: function(){
			const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
			if(!emailPattern.test(this.vemail) && this.vemail.length > 3){
				this.vemailerror = true;
			} else {
				this.vemailerror = false;
			}
		},
		vsenha: function(){
			if(this.vsenhaerror){
				this.vsenhaerror = false;
			}
		},	
		isLoadingCode(val) {
            if (!val) {
                this.comecarContagem();
            }
        }
	},
	beforeDestroy() {
        clearInterval(this.contagem);
    }
}
</script>
<style>
.alert-warning {
	color: #856404;
	background-color: #fff3cd;
	border-color: #ffeeba;
	font-size: 1.1em;
}
.vm--modal.modal-codigo{
	border-radius: 10px !important;
	& > h5 {
		margin-top: 30px;
	}
}
</style>
<style scoped lang="scss">
.shake {
	animation: shake 0.3s;
}

@keyframes shake {
	0%, 100% { transform: translateX(0); }
	20%, 60% { transform: translateX(-8px); }
	40%, 80% { transform: translateX(8px); }
}
.loader {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: block;
	margin:15px auto;
	position: relative;
	color: var(--cor-primaria);
	left: -100px;
	box-sizing: border-box;
	animation: shadowRolling 2s linear infinite;
}

@keyframes shadowRolling {
	0% {
		box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
	}
	12% {
		box-shadow: 100px 0 var(--cor-primaria), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
	}
	25% {
		box-shadow: 110px 0 var(--cor-primaria), 100px 0 var(--cor-primaria), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
	}
	36% {
		box-shadow: 120px 0 var(--cor-primaria), 110px 0 var(--cor-primaria), 100px 0 var(--cor-primaria), 0px 0 rgba(255, 255, 255, 0);
	}
	50% {
		box-shadow: 130px 0 var(--cor-primaria), 120px 0 var(--cor-primaria), 110px 0 var(--cor-primaria), 100px 0 var(--cor-primaria);
	}
	62% {
		box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 var(--cor-primaria), 120px 0 var(--cor-primaria), 110px 0 var(--cor-primaria);
	}
	75% {
		box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 var(--cor-primaria), 120px 0 var(--cor-primaria);
	}
	87% {
		box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 var(--cor-primaria);
	}
	100% {
		box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
	}
}
.single-digit-input {
    width: 30px; 
    height: 30px; 
    border: none; 
	/*background-color: #eeeeee;*/
    border-bottom: 1px solid #969696;
    outline: none;
    text-align: center; 
    font-size: 16px; 
    margin: 0 5px;
}

.single-digit-input:focus {
    border-bottom: 2px solid var(--cor-primaria);
}
.footer-modal {
    width: 100%;
    background-color: #f1f1f1;
	border: #c0c0c0 1px solid; 
    padding: 12px; 
}
.footer-modal-alert{
    width: 100%;
    background-color: #f8d7da;
	border: #c0c0c0 1px solid; 
    padding: 12px; 
	color: #7f2e36;
}
.verification-container {
    text-align: center;
    margin: 20px;
}
.code-inputs {
    display: flex;
    justify-content: center;
    margin: 10px 0;
	margin-bottom: 30px;;
}
.code-inputs input {
    width: 40px;
    height: 40px;
    font-size: 24px;
    text-align: center;
    margin: 0 5px;
}
.error {
    color: #7f2e36;
}
.codigo-recuperacao{
	overflow: auto;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
    opacity: 0;
}

.bg-login{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: rgb(231, 231, 231);
}
.container-login{
	height: 90vh;
	width: 900px;
	box-shadow: -5px 6px 10px -3px rgba(0,0,0,0.75);
}
.left-side{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	background-color: #fff;
	width: 50%;
	height: 100%;
	& > div {
		width: 100%;
		& > form {
			width: 100%;
			padding-inline: 10%;
		}
	}
}
.logo-login{
	margin-top: 30px;
	width: 100%;
	height: fit-content;
	& > img {
	
		width: 200px;
	}
}
.logo-modal{
	width: 100%;
	height: fit-content;
	& > img {
	
		width: 120px;
	}
}

.infos-cadastro{
	margin-top: 14px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	& > h1 {
		margin: 0;
		font-size: 22px;
	}
	& > span {
		font-size: 15px;
		color: rgba(0, 0, 0, 0.683);
	}
}

.aviso-login-senha{
	width: 100%;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
	// background-color: red;
	& > span {
		padding-inline: 13px;
		border-radius: 5px;
		border: 1px solid rgba(0, 0, 0, 0.452);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		font-weight: 500;
		background-color: rgba(255, 0, 0, 0.151);
		height: 30px;
	}
}

.form-login{
	width: 70%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: left;
	& > .grupo-form{
		display: flex;
		flex-direction: column;
		width: 100%;
		// background-color: red;
		& > label {
			margin: 0;
			font-weight: 600;
			color: var(--cor-primaria);
		}
	}
	& > .adicionais-senha{
		width: 100%;
		height: 25px;
		padding-top: 4px;
		margin-bottom: 15px;
		// background-color: red;
		display: flex;
		justify-content: space-between;
		align-items: center;
		& > .form-check {
			width: fit-content;
			display: flex;
			justify-content: center;
			align-items: center;
			& > label {
				margin-left: 5px;
				margin-top: 8px;
				font-size: 11px;
				font-weight: 500;
				color: black;
			}
		}
		& > .form-esqueci{
			padding-top: 4px;
			a {
				font-size: 11px;
				color: rgba(0, 0, 0, 0.731);
				&:hover {
					color: var(--cor-primaria);
				}
			} 
		}
	}
}
input[type="checkbox"] {
	width: 14px;
	height: 14px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 2px solid #8b8b8b;
	border-radius: 4px;
	position: relative;
	cursor: pointer;
}

input[type="checkbox"]:checked {
	margin-top: 3px;
	background-color: var(--cor-primaria);
	border: 1px solid #8b8b8b50;
}

input[type="checkbox"]:checked::after {
	content: '✔';
	position: absolute;
	top: 1px;
	left: 4px;
	font-size: 7px;
	color: #ffffff82;
}

.grupo-btns-form{
	& > button {
		font-size: 13.5px;
		width: 200px;
		height: 33px;
		border-radius: 7px;
		border: 1px solid #686868;
		background-color: var(--cor-primaria);
		color: #fff;
		font-weight: 500;
	}
}

#btn-entrar:disabled {
	background-color: var(--cor-primaria-disabled);
	border: 0;
	color: #fff;
	&:hover{
		cursor: not-allowed;
	}

}
.cadastro-grupo{
	&>span{
		font-size: 14px;
		font-weight: 400;
	}
	&>a{
		color: var(--cor-primaria);
		font-size: 14px;
		font-weight: 500;
	}
}

.right-side{
	display: flex;
	justify-content: center;
	background-color: var(--cor-primaria);
	width: 50%;
	height: 100%;
	overflow: hidden;
}

// input css 
.wrap-input-8 .input {
	width: 100%;
	height: 40px;
	font-size: 14px;
	box-sizing: border-box;
	letter-spacing: 1px;
	background-color: #f5f6fd;
	border: 0; 
	border-bottom: 2px solid var(--cor-primaria); 
	padding: 5px 10px 5px; 
	transition: 0.4s;
}
	
.wrap-input-8 .input:focus {
	outline: none;
}
	
.wrap-input-8 {
	width: 100%;
	margin-top: 5px;
	position: relative;
	& > i{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 8px;
		&:hover{
			cursor: pointer;
		}
	}
}
	
.wrap-input-8 .input ~ .focus-border:before,
.wrap-input-8 .input ~ .focus-border:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 2px;
	background-color: var(--cor-primaria);
	transition: 0.3s;
}
.wrap-input-8 .input ~ .focus-border:after {
	top: auto;
	bottom: 0;
	left: auto;
	right: 0;
}
.wrap-input-8 .input ~ .focus-border i:before,
.wrap-input-8 .input ~ .focus-border i:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 2px;
	height: 0;
	background-color: var(--cor-primaria);
	transition: 0.4s;
}
.wrap-input-8 .input ~ .focus-border i:after {
	left: auto;
	right: 0;
	top: auto;
	bottom: 0;
}
.wrap-input-8 .input:focus ~ .focus-border:before,
.wrap-input-8 .input:focus ~ .focus-border:after {
	width: 100%;
	transition: 0.3s;
}
.wrap-input-8 .input:focus ~ .focus-border i:before,
.wrap-input-8 .input:focus ~ .focus-border i:after {
	height: 100%;
	transition: 0.4s;
}

@media (max-width: 991px) { // RESPONSIVIDADE TABLET
	.container-login{
		height: 90vh;
		width: 900px;
		box-shadow: -5px 6px 10px -3px rgba(0,0,0,0.75);
	}

	.right-side {
		overflow: hidden;
	}
}
@media (max-width: 575px) { // RESPONSIVIDADE MOBILE

}
</style>